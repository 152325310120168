import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import PublishIcon from '@material-ui/icons/Publish';

import { SET_MEDIA_OPTIMIZATION_REQUEST_ACTION } from '../../graphql/mutation/mediaOptimizationRequest';
import { UPDATE_MEDIA } from '../../graphql/mutation/media';
import { MEDIA_OPTIMIZATION_REQUEST } from '../../graphql/query/mediaOptimizationRequest';

import Dropzone from '../form/dropzone';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  downloadIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  downloadLink: {
    display: 'flex',
    color: theme.palette.text.link,
    alignItems: 'center',
    textDecoration: 'none',
  },
  dropzone: {
    marginTop: theme.spacing(2),
    height: 300,
    width: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzoneContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  picture: {
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadIcon: {
    height: '50%',
    width: '50%',
  },
}));

const ImageReportMedia = (props) => {
  const { request } = props;
  const { src, id, media_key, media_id } = request;
  const classes = useStyles();
  const [addMediaOptimizationRequestNote] = useMutation(SET_MEDIA_OPTIMIZATION_REQUEST_ACTION, {
    refetchQueries: [{ query: MEDIA_OPTIMIZATION_REQUEST, variables: { id: request?.id } }],
  });
  const [updateMedia, { loading }] = useMutation(UPDATE_MEDIA, {
    refetchQueries: [{ query: MEDIA_OPTIMIZATION_REQUEST, variables: { id: request?.id } }],
  });

  const onDownload = () =>
    addMediaOptimizationRequestNote({ variables: { id, action: 'MEDIA_DOWNLOADED' } });

  const onFileUploaded = async ({ file }) => {
    const { key, original_name, size } = file;
    addMediaOptimizationRequestNote({ variables: { id, action: 'MEDIA_UPLOADED' } });
    updateMedia({ variables: { media: { id: media_id, key, original_name, size } } });
  };

  return (
    <div className={classes.root}>
      <a href={src} className={classes.downloadLink} onClick={onDownload}>
        <GetAppIcon className={classes.downloadIcon} />
        <Typography>Download the image</Typography>
      </a>
      <Dropzone
        className={classes.dropzone}
        id="media"
        dataTest="account-userpic"
        max={1}
        content={<PublishIcon className={classes.uploadIcon} />}
        variant="single"
        imageFit="contain"
        initKey={media_key}
        hideEditButton
        onFileUploaded={onFileUploaded}
        classes={{ content: classes.dropzoneContent, picture: classes.picture }}
        files={[{ src }]}
        loading={loading}
      />
    </div>
  );
};

ImageReportMedia.propTypes = {
  request: PropTypes.object.isRequired,
};

ImageReportMedia.defaultProps = {};

export default ImageReportMedia;
