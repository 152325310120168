import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);

export function getFormikInputProps({ name, formik = {} }) {
  if (!name || _isEmpty(formik)) return {};

  const { values = {}, handleChange, errors, touched, handleBlur } = formik || {};

  return {
    onChange: handleChange,
    onBlur: handleBlur,
    value: _get(values, name),
    error: !!(_get(touched, name) && _get(errors, name)),
    helperText: _get(touched, name) && _get(errors, name),
  };
}

export function getFormikSelectProps({ name, formik = {} }) {
  if (!name || _isEmpty(formik)) return {};

  const { values = {}, setFieldValue, setFieldTouched, errors, touched, handleBlur } = formik || {};

  return {
    onChangeTarget: (e) => {
      const value = e?.target.value;
      setFieldTouched(name, true);
      setFieldValue(name, value);
    },
    onBlur: handleBlur,
    value: _get(values, name),
    error: !!(_get(touched, name) && _get(errors, name)),
    helperText: _get(touched, name) && _get(errors, name),
  };
}

export function getFormikCheckboxProps({ name, formik = {} }) {
  if (!name || _isEmpty(formik)) return {};

  const { values = {}, handleChange, errors, touched, handleBlur } = formik || {};

  return {
    onChange: handleChange,
    onBlur: handleBlur,
    checked: values[name],
    error: !!(_get(touched, name) && _get(errors, name)),
    helperText: _get(touched, name) && _get(errors, name),
  };
}

export function getFormikAutocompleteProps({ name, formik = {} }) {
  if (!name || _isEmpty(formik)) return {};

  const { values = {}, setFieldValue, setFieldTouched, errors, touched } = formik || {};

  const formatValue = ({ label, inputLabel, value }) => ({ label: inputLabel || label, value });

  const getValuesArray = (value) =>
    value.map(formatValue).filter(({ label, value: optionValue }) => label && optionValue);

  const getNewValue = (value) => {
    if (_isEmpty(value)) return value;
    if (Array.isArray(value)) return getValuesArray(value);
    return formatValue(value);
  };

  return {
    onChange: (e, value) => {
      setFieldTouched(name, true);
      setFieldValue(name, getNewValue(value));
    },
    value: _get(values, name),
    error: !!(_get(touched, name) && _get(errors, name)),
    helperText: _get(touched, name) && _get(errors, name),
  };
}

export function getFormikColorProps({ name, formik = {} }) {
  if (!name || _isEmpty(formik)) return {};

  const { values = {}, setFieldValue, errors, touched, setFieldTouched } = formik || {};

  return {
    onChange: (value) => setFieldValue(name, value),
    onBlur: () => setFieldTouched(name, true),
    value: _get(values, name),
    error: !!(_get(touched, name) && _get(errors, name)),
    helperText: _get(touched, name) && _get(errors, name),
  };
}

export function getFormikChipsProps({ name, formik }) {
  if (!name || _isEmpty(formik)) return {};

  const { values = {}, setFieldValue, errors, touched, setFieldTouched } = formik || {};

  return {
    onChange: (value) => {
      setFieldValue(name, value);
      setFieldTouched(name, true);
    },
    onBlur: () => setFieldTouched(name, true),
    value: _get(values, name),
    error: !!(_get(touched, name) && _get(errors, name)),
    helperText: _get(touched, name) && _get(errors, name),
  };
}

export function getFormikDropzoneProps({ name, formik = {} }) {
  if (!name || _isEmpty(formik)) return {};

  const { errors, touched, setFieldValue, setFieldTouched } = formik || {};

  const deleteFiles = (files, deleteFileCallback) => {
    if (_isEmpty(files)) return;
    files.map(deleteFileCallback);
  };

  return {
    onFileUploaded: async ({ file, files, max, deleteFileCallback }) => {
      const newFiles = [...files, file];
      const redundantFiles = newFiles.slice(0, -max);
      deleteFiles(redundantFiles, deleteFileCallback);
      setFieldTouched(name, true);
      return setFieldValue(name, newFiles.slice(-max));
    },
    files: formik.values[name] || [],
    formError: _get(touched, name) && _get(errors, name),
    onRemoveFile: (file, files) => {
      const newFiles = files.filter(({ key }) => key !== file.key);
      return setFieldValue(name, newFiles);
    },
    onFileDialogCancel: () => {
      setFieldTouched(name, true);
    },
    onBlur: () => setFieldTouched(name, true),
    helperText: _get(touched, name) && _get(errors, name),
  };
}

export function getFormikDateProps({ name, formik = {} }) {
  if (!name || _isEmpty(formik)) return {};

  const { errors, touched, setFieldValue, setFieldTouched, values } = formik || {};

  return {
    onChange: (date) => {
      const formatDate = dayjs(date).toDate();
      return setFieldValue(name, formatDate);
    },
    onBlur: () => setFieldTouched(name, true),
    value: _get(values, name),
    error: !!(_get(touched, name) && _get(errors, name)),
    helperText: _get(touched, name) && _get(errors, name),
  };
}

export function getFormikSliderProps({ name, formik = {} }) {
  if (!name || _isEmpty(formik)) return {};

  const { values = {}, setFieldValue, setFieldTouched } = formik || {};

  return {
    onChange: (e, value) => setFieldValue(name, value),
    onBlur: () => setFieldTouched(name, true),
    value: _get(values, name),
  };
}

export function checkSubmitIsDisabled(formik) {
  if (!formik) return {};

  const { errors, dirty, touched } = formik || {};

  const hasErrors = errors && Object.keys(errors).length;
  const isTouched = touched && Object.keys(touched).length;
  const hasChanged = dirty || isTouched;
  return !!(hasErrors || !hasChanged);
}

export function onSubmitErrors(error, enqueueSnackbar) {
  if (!error) return;

  const errors = _get(error, 'errors');

  const handleError = (message) => {
    if (!enqueueSnackbar) return;
    return (
      enqueueSnackbar &&
      enqueueSnackbar(message, {
        variant: 'error',
        persist: true,
      })
    );
  };

  if (Array.isArray(errors) && errors.length) {
    return errors.map(({ message }) => handleError(message));
  }

  const errorMessage = _get(error, 'message');

  if (errorMessage) handleError(errorMessage);
}
