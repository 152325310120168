/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const MEDIA = gql`
  fragment media on Media {
    id
    src
    placeholder
    size
  }
`;

export const USERPIC = gql`
  fragment userpic on Media {
    id
    src
    placeholder
    size
  }
`;
