/* eslint-disable no-restricted-syntax */
import { useQuery } from '@apollo/client';
import _get from 'lodash/get';

import { ME } from '../graphql/query/auth';

const useAuth = () => {
  const { data } = useQuery(ME);
  const me = _get(data, 'me');

  return { user: me, isAuth: Boolean(me) };
};

export default useAuth;
