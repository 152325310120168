import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { getBaseInputStyles } from '../base-input';

import PropertyFieldNumberInput from './property-field-number-input';
import PropertyFieldTextInput from './property-field-text-input';

const useStyles = makeStyles((theme) => ({
  ...getBaseInputStyles(theme),
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    minHeight: 45,
    cursor: 'pointer',
    paddingTop: theme.spacing(2),
  },
  number: {
    width: theme.spacing(8),
    borderRadius: `${theme.spacing(0.5)}px 0 0 ${theme.spacing(0.5)}px`,
    '& input': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      textAlign: 'center',
      '-moz-appearance': 'textfield',
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  string: {
    '$number + &': {
      marginLeft: theme.spacing(0.25),
      borderRadius: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
    },
    '& input': {
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  input: {
    '&:before': {
      border: 'none',
    },
    '&:after': {
      border: 'none',
    },
  },
}));

const PropertyFieldNumberPlusString = (props) => {
  const { property: propertyGroup, formik, classes: outerClasses } = props;
  const classes = useStyles();
  const { properties, name } = propertyGroup || {};
  const numberProperty = _find(properties, { property_type: 'Number' });
  const stringProperty = _find(properties, { property_type: 'String' });

  const fieldClasses = {
    ...outerClasses,
    string: clsx(outerClasses.string, classes.string),
    number: clsx(outerClasses.number, classes.number),
  };

  const commonProps = {
    formik,
    classes: fieldClasses,
    showLabel: false,
    InputProps: {
      className: classes.input,
    },
  };

  const renderLabel = (
    <InputLabel classes={{ root: classes.label, shrink: classes.labelShrink }} shrink>
      {name}
    </InputLabel>
  );

  const renderStringProperty = () => {
    if (!stringProperty) return null;
    return <PropertyFieldTextInput property={stringProperty} {...commonProps} />;
  };

  const renderNumberProperty = () => {
    if (!numberProperty) return null;
    return <PropertyFieldNumberInput property={numberProperty} {...commonProps} />;
  };

  return (
    <FormControl className={classes.wrapper}>
      {renderLabel}
      {renderNumberProperty()}
      {renderStringProperty()}
    </FormControl>
  );
};

PropertyFieldNumberPlusString.propTypes = {
  property: PropTypes.shape({
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        formName: PropTypes.string,
      }),
    ),
  }).isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

PropertyFieldNumberPlusString.defaultProps = {
  classes: {},
};

export default PropertyFieldNumberPlusString;
