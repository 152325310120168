import { mergePaginate } from './helpers';

export default {
  fields: {
    reports: {
      keyArgs: ['type'],
      merge: mergePaginate,
    },
    collectionTypes: {
      keyArgs: ['type'],
      merge: mergePaginate,
    },
    collectionTypeProperties: {
      keyArgs: ['type'],
      merge: mergePaginate,
    },
    advertisements: {
      keyArgs: ['type'],
      merge: mergePaginate,
    },
    mediaOptimizationRequests: {
      keyArgs: ['type'],
      merge: mergePaginate,
    },
  },
};
