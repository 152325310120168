import { useMemo, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import clsx from 'clsx';

import Autocomplete from '../form/autocomplete';
import TextInput from '../form/text-input';
import Checkbox from '../form/checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    border: `1px solid ${theme.palette.border.input}`,
    width: 200,
    borderRadius: theme.spacing(0.5),
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  input: {},
  select: {},
  checkbox: {
    border: 'none',
  },
}));

const DashboardFilters = (props) => {
  const { fields } = props;
  const classes = useStyles();

  const fieldComponents = {
    autocomplete: Autocomplete,
    text: TextInput,
    checkbox: Checkbox,
  };

  const typeFields = useMemo(
    () => ({
      autocomplete: {
        classes: { root: clsx(classes.field, classes.select) },
        size: 'small',
      },
      text: {
        classes: { root: clsx(classes.field, classes.input) },
      },
      checkbox: {
        className: clsx(classes.field, classes.checkbox),
      },
    }),
    [],
  );

  const renderField = ({
    type = 'autocomplete',
    placeholder,
    id,
    value,
    onInputChange,
    options,
    onChange,
    label,
    name,
  }) => {
    const FieldComponent = fieldComponents[type];
    const defaultFields = typeFields[type];
    const componentProps = {
      ...defaultFields,
      placeholder,
      id,
      value,
      options,
      onChange,
      label,
      checked: value,
      name,
    };

    if (type === 'autocomplete') componentProps.onInputChange = onInputChange;

    return <FieldComponent key={id} {...componentProps} />;
  };

  const renderFields = _map(fields, renderField);

  return <div className={classes.root}>{renderFields}</div>;
};

DashboardFilters.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['text', 'autocomplete', 'checkbox']),
      placeholder: PropTypes.string,
      id: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]).isRequired,
      onInputChange: PropTypes.func,
      options: PropTypes.array,
      onChange: PropTypes.func.isRequired,
    }),
  ),
};

DashboardFilters.defaultProps = {
  fields: [],
};

export default memo(DashboardFilters);
