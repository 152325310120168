import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormLabel, DialogActions, DialogContent, Button, Typography } from '@material-ui/core';
import _get from 'lodash/get';
import { string, object } from 'yup';
import { useFormik } from 'formik';
import PublishIcon from '@material-ui/icons/Publish';

import {
  getFormikInputProps,
  getFormikCheckboxProps,
  getFormikDropzoneProps,
  checkSubmitIsDisabled,
} from '../form/helpers';

import TextInput from '../form/text-input';
import Checkbox from '../form/checkbox';
import Dropzone from '../form/dropzone';
import Loader from '../common/loader';

const validationSchema = object().shape({
  name: string().required('Name is required'),
  description: string().required('Description is required'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(60),
    maxWidth: theme.spacing(60),
  },
  field: {
    width: '100%',
    '& + &': {
      marginTop: theme.spacing(3),
    },
  },
  label: {
    textTransform: 'uppercase',
  },
  error: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
  picture: {
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CollectionTypePropertyItemForm = (props) => {
  const { error, onSubmit, loading, onClose, submitButtonText, initialValues } = props;
  const classes = useStyles();

  const formik = useFormik({
    validationSchema,
    onSubmit: onFormSubmit,
    initialValues,
  });
  const { submitForm } = formik;
  const buttonIsDisabled = checkSubmitIsDisabled(formik) || loading;

  const renderError = () => {
    const errorMessage = _get(error, 'errors[0].message');
    if (!errorMessage) return null;
    return <Typography>{errorMessage}</Typography>;
  };

  function onFormSubmit(submitValues = {}) {
    const uploadedMedia = _get(submitValues, 'media[0]', {});
    const { key, original_name, size } = uploadedMedia;
    const collectionTypePropertyItem = {
      name: submitValues?.name,
      description: submitValues?.description,
      published: submitValues?.published,
      spellings: submitValues?.spellings.split(',').map((s) => s.trim()),
    };

    if (key && original_name && size) {
      collectionTypePropertyItem.media = { key, original_name, size };
    }

    if (submitValues.spellings) {
      collectionTypePropertyItem.spellings = submitValues.spellings.split(',').map((s) => s.trim());
    }

    onSubmit({ collectionTypePropertyItem });
  }

  return (
    <>
      <DialogContent>
        <div className={classes.root}>
          <TextInput
            id="name"
            className={classes.field}
            label="Name"
            placeholder="Type name"
            {...getFormikInputProps({ name: 'name', formik })}
          />
          <TextInput
            id="description"
            className={classes.field}
            label="Description"
            placeholder="Type description"
            multiline
            rows={3}
            rowsMax={6}
            {...getFormikInputProps({ name: 'description', formik })}
          />
          <div className={classes.field}>
            <FormLabel className={classes.label}>Upload image</FormLabel>
            <Dropzone
              className={classes.dropzone}
              id="media"
              max={1}
              content={<PublishIcon className={classes.uploadIcon} />}
              variant="single"
              imageFit="contain"
              hideEditButton
              classes={{ content: classes.dropzoneContent, picture: classes.picture }}
              {...getFormikDropzoneProps({ name: 'media', formik })}
            />
          </div>
          <Checkbox
            label="Published"
            name="published"
            color="secondary"
            className={classes.field}
            {...getFormikCheckboxProps({ name: 'published', formik })}
          />
          <TextInput
            id="spellings"
            className={classes.field}
            label="Spellings"
            placeholder="Spellings separated by commas"
            multiline
            rows={3}
            rowsMax={6}
            {...getFormikInputProps({ name: 'spellings', formik })}
          />
          {renderError()}
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={submitForm}
          disabled={buttonIsDisabled}
          variant="contained"
          color="primary"
        >
          {submitButtonText}
        </Button>
      </DialogActions>
      <Loader show={loading} absolute />
    </>
  );
};

CollectionTypePropertyItemForm.propTypes = {
  error: PropTypes.object,
  propertyTypesOptions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  initialValues: PropTypes.object,
};

CollectionTypePropertyItemForm.defaultProps = {
  error: {},
  propertyTypesOptions: [],
  loading: false,
  submitButtonText: 'Save',
  initialValues: {},
};

export default CollectionTypePropertyItemForm;
