import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import _map from 'lodash/map';

import { COLLECTION_TYPE_PROPERTY_LIST_ITEMS } from '../../../graphql/query/collectionTypePropertyListItems';

import { getFormikSelectProps } from '../helpers';

import Select from '../select';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const PropertyFieldSelect = (props) => {
  const classes = useStyles();
  const {
    property,
    formik,
    classes: outerClasses,
    showLabel,
    className,
    name,
    formName,
    ...otherProps
  } = props;
  const { id, name: propertyName, description } = property;
  const variables = { propertyId: id };

  const { data } = useQuery(COLLECTION_TYPE_PROPERTY_LIST_ITEMS, { variables });
  const items = data?.collectionTypePropertyListItems?.results || [];

  const formatItem = (item) => {
    const { name: itemName, id: itemId, media } = item || {};
    const thumbnail = media?.src;
    return { value: itemId, label: itemName, data: { thumbnail } };
  };

  const options = _map(items, formatItem);

  return (
    <Select
      id={id}
      label={showLabel && propertyName}
      options={options}
      className={clsx(classes.root, outerClasses.select, className)}
      placeholder={description}
      {...otherProps}
      {...getFormikSelectProps({ formik, name: formName || id })}
    />
  );
};

PropertyFieldSelect.propTypes = {
  name: PropTypes.string,
  formName: PropTypes.string,
  property: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    select: PropTypes.string,
    root: PropTypes.string,
    thumbnail: PropTypes.string,
    option: PropTypes.string,
  }),
  showLabel: PropTypes.bool,
  className: PropTypes.string,
};

PropertyFieldSelect.defaultProps = {
  classes: {},
  showLabel: true,
  className: '',
  name: '',
  formName: '',
};

export default PropertyFieldSelect;
