import { createTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createTheme({
  minWidth: 320,
  spacing: 8, // theme.spacing(2) = 8 * 2
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      '"Segoe UI"',
      'Lato',
      'Inter',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontDisplay: 'swap',
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 400,
  },
  overrides: {
    MuiContainer: {
      root: {
        // maxWidth: '960px !important',
        paddingLeft: 18,
        paddingRight: 18,
      },
    },
    MuiSvgIcon: {
      root: {
        'button:active &': {
          transition: 'transform 50ms',
          transform: 'scale(0.9)',
          outline: 'none',
        },
      },
    },
    MuiButton: {
      sizeSmall: {
        height: 30,
        lineHeight: 1,
      },
    },
  },
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: {
      main: '#3E4250',
      button: '#5FDABC',
      buttonHover: 'rgba(95,218,188, 0.8)',
      light: 'rgba(98,54,255, 0.87)',
      pale: 'rgba(98,54,255, 0.5)',
      hover: '#9B78FF',
      opacity: 'rgba(98,54,255, 0.2)',
      google: {
        color: '#FFFFFF',
        bgColor: '#E02020',
      },
      facebook: {
        color: '#FFFFFF',
        bgColor: '#3b5998',
      },
    },
    secondary: {
      main: '#5FDABC',
      button: 'rgba(41, 45, 55, 0.08)',
      textButton: 'rgba(41, 45, 55, 0.4)',
      buttonHover: 'rgba(41, 45, 55, 0.18)',
    },
    status: {
      start: '#52B195',
      pending: '#F1CC27',
      ignore: '#E8573C',
    },
    border: {
      main: '#EFEFEF',
      input: '#979797',
      icon: '#93979F',
      button: '#D4D8DB',
      colorCircle: 'rgba(0, 0, 0, 0.14)',
      white: '#FFFFFF',
      badge: 'rgba(148, 152, 161, 0.5)',
    },
    text: {
      primary: '#3E4250',
      secondary: 'rgba(62, 66, 80, 0.5)',
      light: 'rgba(62, 66, 80, .7)',
      pale: 'rgba(62, 66, 80, .54)',
      faint: 'rgba(43, 50, 68, .3)',
      grey: 'rgba(172,174,183)',
      white: '#fff',
      lightWhite: 'rgba(255,255,255,.5)',
      link: '#5A8BFF',
    },
    background: {
      dark: '#2B3244',
      main: '#fff',
      default: '#fff',
      modalContainerWhite: 'rgba(255, 255, 255, 0.3)',
      page: '#F5F8FA',
      lightGray: 'rgba(0,0,0,0.1)',
      itemModal: '#E5E5E5',
      modalTab: 'rgba(0,0,0,.05)',
      comment: 'rgb(245,248,250)',
      itemDescription: '#F6F6F7',
      ratingBottom: `rgb(206,246,244)`,
      ratingColumn: 'rgba(0,0,0,.02)',
      control: 'rgba(43, 50, 68, 0.05)',
      controlHover: 'rgba(43, 50, 68, 0.1)',
      defaultItemPopup: '#fff, #000',
      drawer: 'rgba(0, 0, 0, 0.65)',
      card: '#F5F7FA',
      silver: 'linear-gradient(180deg, #DEDEDE 0%, #BCBEC2 100%)',
      gold: 'linear-gradient(180deg, #FFE58A 0%, #F3BA28 100%)',
      cardEditing: 'rgba(10, 211, 199, 0.36)',
      modalBackdrop: 'rgba(43,50,68,0.9)',
    },
    shadow: {
      main: 'rgba(13, 55, 100, .3)',
      light: '0px 1px 4px rgba(0,0,0,0.1)',
      button:
        '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.12), 0px 1px 6px 0px rgba(0,0,0,0.12)',
      fullWidthBottom: '0px 2px 4px -1px rgba(42, 50, 68, 0.12)',
      fullWidthBottomHover: '0px 4px 10px -1px rgba(42, 50, 68, 0.22)',
      header: '0px 1px 4px rgba(42, 50, 68, 0.12)',
      card: '0px 4px 12px rgba(16, 28, 61, 0.12)',
    },
    tab: {
      main: 'rgba(255, 255, 255, .7)',
      secondary: 'rgb(62, 66, 80, 0.5)',
      mainSelected: '#fff',
      secondarySelected: 'rgba(62, 66, 80)',
    },
    placeholder: {
      input: 'rgba(62,66,80,.4)',
    },
    error: {
      main: red.A400,
      background: 'rgba(255, 23, 68, 0.2)',
    },
    success: {
      main: green[600],
      background: 'rgba(67, 160, 71, 0.2)',
    },
    icon: {
      main: 'rgba(43, 50, 68, 0.5)',
      hover: 'rgba(0, 0, 0, 0.44)',
      yellow: '#ECB325',
      blue: '#6ED7F0',
      orange: '#F38649',
      violet: 'rgb(159, 65, 175)',
      darkGray: '#979797',
      lightGray: 'rgba(255, 255, 255, 0.9)',
    },
    hover: {
      main: 'rgba(0, 0, 0, 0.05)',
    },
    scrollbar: {
      main: 'rgba(0, 0, 0, 0.1)',
      hover: 'rgba(0, 0, 0, 0.2)',
    },
    skeleton: {
      color: 'rgba(0,0,0,0.1)',
      highlightColor: 'rgba(0,0,0,0.1)',
    },
    feature: {
      scheme_default: {
        color: 'rgb(95, 218, 188)',
        background: 'rgba(95, 218, 188, 0.15)',
      },
      scheme_1: {
        color: 'rgb(100, 175, 64)',
        background: 'rgba(100, 175, 65, 0.15)',
      },
      scheme_2: {
        color: 'rgb(65, 109, 175)',
        background: 'rgba(65, 109, 175, 0.15)',
      },
      scheme_3: {
        color: 'rgb(159, 65, 175)',
        background: 'rgba(159, 65, 175, 0.15)',
      },
    },
  },
});

export default theme;
