import { useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Swatch } from 'react-color/lib/components/common';

import { colorIsLight } from '../../../helpers/color';

const useStyles = makeStyles((theme) => ({
  swatchWrapper: ({ color, circleSpacing, circleSize, active, dotColor }) => ({
    width: circleSize,
    height: circleSize,
    marginRight: circleSpacing,
    marginBottom: circleSpacing,
    '& div': {
      borderRadius: theme.spacing(0.5),
      backgroundColor: color,
      boxShadow: `inset 0 0 0 ${circleSize / 2 + 1}px ${color}`,
      transform: 'scale(1)',
      transition: '100ms transform ease, 100ms box-shadow ease',
      position: 'relative',
      border: `1px solid ${theme.palette.border.main}`,
      '&:after': {
        content: '""',
        borderRadius: '50%',
        height: 12,
        width: 12,
        position: 'absolute',
        backgroundColor: dotColor,
        top: 'calc(50% - 6px)',
        left: 'calc(50% - 6px)',
        maxHeight: active ? circleSize : 0,
        maxWidth: active ? circleSize : 0,
      },
      '&:hover': {
        transform: 'scale(1.2)',
      },
      '&:active': {
        boxShadow: `0 0 5px ${theme.palette.shadow.main}`,
      },
    },
  }),
}));

const ColorPickerModal = ({ color, onClick, active, circleSize, circleSpacing, className }) => {
  const theme = useTheme();
  const dotColor = useMemo(() => getDotColor, [color]);
  const classes = useStyles({ color, circleSpacing, circleSize, active, dotColor });

  function getDotColor() {
    const isLight = colorIsLight(color || '#fff') === 'light';
    if (isLight) return theme.palette.icon.darkGray;
    return theme.palette.icon.lightGray;
  }

  return (
    <div className={clsx(classes.swatchWrapper, className)}>
      <Swatch className={clsx(classes.swatch, 'swatch')} color={color} onClick={onClick} />
    </div>
  );
};

ColorPickerModal.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  circleSize: PropTypes.number,
  circleSpacing: PropTypes.number,
  className: PropTypes.string,
};

ColorPickerModal.defaultProps = {
  circleSize: 28,
  circleSpacing: 14,
  onClick: () => {},
  active: false,
  className: '',
};

export default ColorPickerModal;
