import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';

import { constantToString } from '../../helpers/constant';

import { ADD_MEDIA_OPTIMIZATION_REQUEST_NOTE } from '../../graphql/mutation/mediaOptimizationRequest';
import { MEDIA_OPTIMIZATION_REQUEST } from '../../graphql/query/mediaOptimizationRequest';

import ReportNotes from '../report-notes';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  statuses: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  status: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  statusChip: {
    color: theme.palette.text.white,
    borderRadius: theme.spacing(0.5),
  },
  main: {
    display: 'flex',
    alignItems: 'stretch',
    position: 'relative',
  },
  history: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px 0`,
  },
  historyStages: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  historyStage: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    alignItems: 'center',
  },
  historyDate: {
    marginRight: theme.spacing(2),
    display: 'inline',
  },
  historyStatus: {
    marginRight: theme.spacing(1),
    border: `1px solid ${theme.palette.text.primary}`,
    padding: `0 ${theme.spacing(1)}px`,
  },
  historyDecision: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
  divider: {
    width: 1,
    backgroundColor: theme.palette.border.main,
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '50%',
  },
  notes: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    alignItems: 'flex-start',
  },
}));

const ImageReportHistory = (props) => {
  const { request } = props;
  const classes = useStyles();
  const { history, notes } = request;
  const [noteText, setNoteText] = useState('');
  const [addMediaOptimizationRequestNote] = useMutation(ADD_MEDIA_OPTIMIZATION_REQUEST_NOTE, {
    refetchQueries: [{ query: MEDIA_OPTIMIZATION_REQUEST, variables: { id: request?.id } }],
  });

  const onNoteChange = (e) => setNoteText(e.target.value);

  const onNoteSend = () => {
    addMediaOptimizationRequestNote({ variables: { id: request?.id, text: noteText } });
    setNoteText('');
  };

  const renderHistoryStage = ({ date, decision, action, status }) => {
    const stageDecision = decision ? `(${constantToString(decision).toUpperCase()})` : null;
    return (
      <div key={date} className={classes.historyStage}>
        <Typography className={classes.historyDate}>{dayjs(date).format('DD.MM.YYYY')}</Typography>
        <Typography className={classes.historyStatus}>{constantToString(status)}</Typography>
        <Typography>{constantToString(action)}</Typography>
        <Typography className={classes.historyDecision}>{stageDecision}</Typography>
      </div>
    );
  };

  const renderHistoryStages = () => {
    if (_isEmpty(history)) return null;
    return history.map(renderHistoryStage);
  };

  if (_isEmpty(request)) return null;

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.history}>
          <Typography>History</Typography>
          <div className={classes.historyStages}>{renderHistoryStages()}</div>
        </div>
        <div className={classes.divider} />
        <div className={classes.notes}>
          <Typography>Notes</Typography>
          <ReportNotes
            value={noteText}
            onChange={onNoteChange}
            onSubmit={onNoteSend}
            notes={notes}
          />
        </div>
      </div>
    </div>
  );
};

ImageReportHistory.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.string,
    history: PropTypes.array,
    notes: PropTypes.array,
  }).isRequired,
};

ImageReportHistory.defaultProps = {};

export default ImageReportHistory;
