/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, DialogTitle } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import _transform from 'lodash/transform';
import { REPLACE_COLLECTION_TYPE_PROPERTY_ITEM } from '../../graphql/mutation/collectionTypePropertyListItems';
import { GET_COLLECTION_TYPE_PROPERTY_BY_SLUG } from '../../graphql/query/collectionTypeProperties';

import ReplaceCollectionTypePropertyItemForm from '../replace-collection-type-property-item-form';

const ReplaceCollectionTypePropertyItemModal = (props) => {
  const { onClose, updateItems, initReplacingItem, ...modalProps } = props;
  const { slug } = useParams();

  const { data } = useQuery(GET_COLLECTION_TYPE_PROPERTY_BY_SLUG, { variables: { slug } });
  const propertyData = data?.collectionTypePropertyBySlug || {};
  const propertyName = propertyData?.name;
  const propertyDescription = propertyData?.description;
  const propertyTitle = `${propertyName} (${propertyDescription})`;

  const [replaceCollectionTypePropertyItem, { loading, error }] = useMutation(
    REPLACE_COLLECTION_TYPE_PROPERTY_ITEM,
    { onCompleted },
  );

  const initialValues = {
    replacing: initReplacingItem || {},
    target: {},
  };

  function onCompleted() {
    if (updateItems) updateItems();
    onClose();
  }

  function onSubmit(submitVariables) {
    const transform = (result, value, key) => {
      result[`${key}_id`] = value?.value;
    };
    const variables = _transform(submitVariables, transform);
    replaceCollectionTypePropertyItem({ variables });
  }

  const renderForm = propertyData && (
    <ReplaceCollectionTypePropertyItemForm
      property={propertyData}
      onSubmit={onSubmit}
      initialValues={initialValues}
      loading={loading}
      error={error}
      onClose={onClose}
    />
  );

  return (
    <Dialog onClose={onClose} {...modalProps}>
      <DialogTitle>Replace {propertyTitle} item</DialogTitle>
      {renderForm}
    </Dialog>
  );
};

ReplaceCollectionTypePropertyItemModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  updateItems: PropTypes.func,
  initReplacingItem: PropTypes.object,
};

ReplaceCollectionTypePropertyItemModal.defaultProps = {
  updateItems: () => {},
  initReplacingItem: null,
};

export default ReplaceCollectionTypePropertyItemModal;
