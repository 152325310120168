/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const EDIT_EXPLORE_LINE = gql`
  mutation EditExploreLine($property_id: ID!, $value: [String]!, $name: String) {
    editExploreLine(property_id: $property_id, value: $value, name: $name) {
      id
      name
      property_id
      value
    }
  }
`;
