import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

const DropzoneButton = (props) => {
  const { children, ...buttonProps } = props;

  return <Button {...buttonProps}>{children}</Button>;
};

DropzoneButton.propTypes = {
  children: PropTypes.node,
};

DropzoneButton.defaultProps = {
  children: '',
};

export default DropzoneButton;
