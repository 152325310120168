import { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import _capitalize from 'lodash/capitalize';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { GET_COLLECTION_TYPES } from '../../graphql/query/collectionTypes';

import DashboardTable from '../dashboard-table';
import DashboardFilters from '../dashboard-filters';
import CreateCollectionTypeButton from '../create-collection-type-button';
import EditCollectionTypeButton from '../edit-collection-type-button';

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    marginTop: theme.spacing(2),
    overflow: 'hidden',
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const EditButton = ({ row, onEditType }) => (
  <IconButton size="small" onClick={() => onEditType(row?.id)}>
    <EditIcon />
  </IconButton>
);

EditButton.propTypes = {
  row: PropTypes.object.isRequired,
  onEditType: PropTypes.func.isRequired,
};

const CollectionTypesCatalogue = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [name, setName] = useState('');

  const variables = { limit: rowsPerPage };
  const {
    data,
    loading,
    refetch: refetchTypes,
  } = useQuery(GET_COLLECTION_TYPES, {
    variables,
  });
  const typesData = data?.collectionTypes;
  const types = typesData?.results;

  const debounceUpdateTypes = useCallback(_debounce(updateTypes, 500), []);

  useEffect(() => {
    debounceUpdateTypes({ name });
  }, [name]);

  const COLUMNS = [
    {
      id: 'name',
      label: 'Name',
      minWidth: 200,
    },
    {
      id: 'edit',
      minWidth: 40,
      width: 40,
      format: (type) => <EditCollectionTypeButton type={type} updateTypes={updateTypes} />,
    },
  ];

  function updateTypes(newVariables) {
    setPage(0);
    refetchTypes({ ...variables, ...newVariables });
  }

  const onPageChange = ({ direction }) => {
    const hasNewPage = typesData[`has${_capitalize(direction)}`];
    if (!hasNewPage) return;

    const next = direction === 'next' ? typesData.next : '';
    const previous = direction === 'previous' ? typesData.previous : '';
    const newPageVariables = { ...variables, next, previous };
    refetchTypes(newPageVariables);
  };

  const onChangeName = (e) => {
    const value = e?.target?.value;
    setName(value);
  };

  const filterFields = [
    {
      type: 'text',
      placeholder: 'Filter by name',
      id: 'name',
      value: name,
      onChange: onChangeName,
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.filters}>
        <DashboardFilters fields={filterFields} />
        <CreateCollectionTypeButton />
      </div>
      <div className={classes.table}>
        <DashboardTable
          data={types}
          loading={loading}
          columns={COLUMNS}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={typesData?.resultsNumber}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default CollectionTypesCatalogue;
