import { gql } from '@apollo/client';

export const GET_NEWS = gql`
  query News($id: ID!) {
    news(id: $id) {
      id
      visible
      title
      desc
      createdAt
      type
      user {
        name
        email
      }
    }
  }
`;

export const GET_ALL_NEWS = gql`
  {
    allNewsItems {
      id
      visible
      title
      type
      desc
      createdAt
      url
    }
  }
`;
