import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_MEDIA_BY_KEY = gql`
  mutation DeleteMediaByKey($key: String!) {
    deleteMediaByKey(key: $key) {
      src
    }
  }
`;

export const UPDATE_MEDIA = gql`
  mutation UpdateMedia($media: MediaInput!) {
    updateMedia(media: $media) {
      src
    }
  }
`;
