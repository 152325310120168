import { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import CreateCollectionTypePropertyModal from '../create-collection-type-property-modal';

const CreateCollectionTypePropertyButton = ({ updateProperties }) => {
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const onOpenCreateModal = () => setOpenCreateModal(true);

  const closeCreateModal = () => setOpenCreateModal(false);

  return (
    <>
      <Button
        onClick={onOpenCreateModal}
        color="secondary"
        variant="contained"
        startIcon={<AddIcon />}
        size="small"
      >
        <span>Create property</span>
      </Button>
      <CreateCollectionTypePropertyModal
        open={openCreateModal}
        onClose={closeCreateModal}
        updateProperties={updateProperties}
      />
    </>
  );
};

CreateCollectionTypePropertyButton.propTypes = {
  updateProperties: PropTypes.func,
};

CreateCollectionTypePropertyButton.defaultProps = {
  updateProperties: () => {},
};

export default CreateCollectionTypePropertyButton;
