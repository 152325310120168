import { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { Button, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { getFormikSelectProps, getFormikAutocompleteProps } from '../form/helpers';
import { GET_COLLECTION_TYPES } from '../../graphql/query/collectionTypes';
import { GET_AD_COLLECTION_TYPE_PROPERTIES } from '../../graphql/query/collectionTypeProperties';

import Select from '../form/select';
import PropertyFieldAutocomplete from '../form/property-field/property-field-autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
  },
  condition: {
    display: 'flex',
    alignItems: 'center',
  },
  conditionClose: {
    marginLeft: theme.spacing(1),
  },
  addForm: {
    display: 'flex',
  },
  field: {
    width: theme.spacing(24),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  select: {
    width: theme.spacing(24),
  },
  buttons: {
    marginLeft: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

const AdvertisementConditionsForm = (props) => {
  const { formik } = props;
  const { values, setFieldValue } = formik;
  const {
    properties,
    collection_type,
    collection_type_property,
    collection_type_property_list_item,
  } = values;
  const classes = useStyles();

  const [showConditionForm, setShowConditionForm] = useState(false);

  const [fetchTypes, { data: typesData }] = useLazyQuery(GET_COLLECTION_TYPES);
  const types = typesData?.collectionTypes.results || [];
  const typesOptions = types.map(getOption);

  const [fetchTypeProperties, { data: typePropertiesData }] = useLazyQuery(
    GET_AD_COLLECTION_TYPE_PROPERTIES,
  );
  const typeProperties = typePropertiesData?.collectionTypeProperties?.results || [];
  const typePropertyOptions = typeProperties.map(getOption);

  const property = useMemo(() => {
    const currentProperty = typeProperties.find(({ id }) => id === collection_type_property);
    return { ...currentProperty, name: 'collection_type_property_list_item' };
  }, [collection_type_property]);

  useEffect(() => {
    if (showConditionForm) {
      fetchTypes();
    }
  }, [showConditionForm]);

  useEffect(() => {
    if (collection_type) {
      fetchTypeProperties({
        variables: { collectionType: collection_type, propertyType: 'List' },
      });
    }
    setFieldValue('collection_type_property', '');
  }, [collection_type]);

  useEffect(() => {
    setFieldValue('collection_type_property_list_item', '');
  }, [collection_type_property]);

  function getOption({ name, id }) {
    return {
      value: id,
      label: name,
    };
  }

  const getOptionName = (options, value) => {
    const option = _find(options, { value });
    return option?.label;
  };

  const getNewCondition = () => {
    const typeName = getOptionName(typesOptions, collection_type);
    const propertyName = getOptionName(typePropertyOptions, collection_type_property);

    return {
      collection_type: { id: collection_type, name: typeName },
      collection_type_property: { id: collection_type_property, name: propertyName },
      collection_type_property_list_item: {
        id: collection_type_property_list_item.value,
        name: collection_type_property_list_item.label,
      },
    };
  };

  const onAddCondition = () => {
    const newProperty = getNewCondition();
    setShowConditionForm(false);
    setFieldValue('properties', [...properties, newProperty]);
    setFieldValue('collection_type', '');
    setFieldValue('collection_type_property', '');
    setFieldValue('collection_type_property_list_item', '');
  };

  const onRemoveCondition = (index) => {
    // eslint-disable-next-line no-undef
    const newProperties = properties.filter((value, i) => i !== index);
    setFieldValue('properties', [...newProperties]);
  };

  const renderShowCondition = (condition, i) => {
    const {
      collection_type: typeValue,
      collection_type_property: propertyValue,
      collection_type_property_list_item: listItemValue,
    } = condition;
    const key = `${typeValue?.id}_${propertyValue?.id}_${listItemValue?.id}`;
    const name = `${typeValue?.name} : ${propertyValue?.name} : ${listItemValue?.name}`;
    return (
      <div key={key} className={classes.condition}>
        <Typography>{name}</Typography>
        <IconButton
          size="small"
          className={classes.conditionClose}
          onClick={() => onRemoveCondition(i)}
        >
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  const renderShowConditions = _map(properties, renderShowCondition);

  const renderPropertySelect = (
    <div className={classes.field}>
      <Select
        className={classes.select}
        id="collection_type_property"
        placeholder="Collection type property"
        options={typePropertyOptions}
        {...getFormikSelectProps({ name: 'collection_type_property', formik })}
      />
    </div>
  );

  const renderItemSelect = (
    <div className={classes.field}>
      <PropertyFieldAutocomplete
        className={classes.select}
        id="collection_type_property_list_item"
        placeholder="Type property list item"
        showLabel={false}
        formik={formik}
        property={property}
        canCreateNew={false}
        {...getFormikAutocompleteProps({ name: 'collection_type_property_list_item', formik })}
      />
    </div>
  );

  const renderAddButton = (
    <Button
      color="secondary"
      variant="contained"
      startIcon={<AddIcon />}
      size="small"
      className={classes.button}
      onClick={onAddCondition}
    >
      Add
    </Button>
  );

  const renderAddShowConditionsForm = (
    <div className={classes.addForm}>
      <div className={classes.field}>
        <Select
          className={classes.select}
          id="collection_type"
          placeholder="Collection type"
          options={typesOptions}
          {...getFormikSelectProps({ name: 'collection_type', formik })}
        />
      </div>
      {collection_type && renderPropertySelect}
      {collection_type_property && renderItemSelect}
      <div className={classes.buttons}>
        {collection_type_property_list_item && renderAddButton}
        <Button size="small" className={classes.button} onClick={() => setShowConditionForm(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );

  const renderAddConditionButton = (
    <Button color="secondary" startIcon={<AddIcon />} onClick={() => setShowConditionForm(true)}>
      Add condition
    </Button>
  );

  const renderAddShowConditions = showConditionForm
    ? renderAddShowConditionsForm
    : renderAddConditionButton;

  return (
    <div className={classes.root}>
      {renderShowConditions}
      {renderAddShowConditions}
    </div>
  );
};

AdvertisementConditionsForm.propTypes = {
  formik: PropTypes.object.isRequired,
};

AdvertisementConditionsForm.defaultProps = {};

export default AdvertisementConditionsForm;
