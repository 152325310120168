/* eslint-disable import/prefer-default-export */
export const NAV_LINKS = [
  {
    name: 'Reports',
    href: '/',
  },
  {
    name: 'Catalogue',
    href: '/catalogue',
  },
  {
    name: 'News',
    href: '/news',
  },
  {
    name: 'Advertisement',
    href: '/advertisement',
  },
  {
    name: 'Images',
    href: '/images',
  },
  {
    name: 'Pages',
    href: '/pages',
  },
];

export const DASHBOARD_TABS = [
  {
    label: 'New',
    value: 'NEW',
  },
  {
    label: 'Work in progress',
    value: 'WORK_IN_PROGRESS',
  },
  {
    label: 'Archive',
    value: 'ARCHIVE',
  },
];

export const CATALOGUE_TABS = [
  {
    label: 'Types',
    value: 'TYPES',
  },
  {
    label: 'Properties',
    value: 'PROPERTIES',
  },
];

export const DEFAULT_COLOR_PICKER_COLORS = [
  'rgb(229, 43, 43)',
  'rgb(137, 11, 11)',
  'rgb(196, 131, 6)',
  'rgb(255, 154, 2)',
  'rgb(255, 234, 45)',
  'rgb(251, 255, 57)',
  'rgb(169, 229, 43)',
  'rgb(102, 143, 14)',
  'rgb(27, 202, 171)',
  'rgb(93, 221, 249)',
  'rgb(40, 120, 240)',
  'rgb(13, 11, 130)',
  'rgb(155, 45, 242)',
  'rgb(211, 15, 191)',
  'rgb(249, 132, 181)',
  'rgb(0, 0, 0)',
  'rgb(146, 146, 146)',
  'rgb(255, 255, 255)',
];
