import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, Button, Typography } from '@material-ui/core';
import _get from 'lodash/get';
import _values from 'lodash/values';
import { useFormik } from 'formik';

import { getFormikAutocompleteProps, checkSubmitIsDisabled } from '../form/helpers';
import { removeEmptyProperties, validateAutocompleteValue } from '../form/validate';

import Loader from '../common/loader';
import PropertyFieldAutocomplete from '../form/property-field/property-field-autocomplete';
import SaveConfirmPopup from '../save-confirm-popup';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(60),
    maxWidth: theme.spacing(60),
  },
  description: {
    margin: theme.spacing(2, 0, 1),
  },
  error: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

function validate(values) {
  const errors = {};
  const { replacing, target } = values;
  const replacingValue = replacing?.value;
  const targetValue = target?.value;
  const valuesAreSame = replacingValue && targetValue && targetValue === replacingValue;

  errors.replacing = validateAutocompleteValue(replacing, {
    required: true,
    requiredMessage: 'Replacing is required',
  });
  errors.target = validateAutocompleteValue(target, {
    required: true,
    requiredMessage: 'Target is required',
  });
  errors.sameValue = valuesAreSame && "Items mustn't be the same";

  return removeEmptyProperties(errors);
}

const ReplaceCollectionTypePropertyItemForm = (props) => {
  const { error, onSubmit, loading, onClose, initialValues, property } = props;
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const replacingProperty = { ...property, name: 'replacing' };
  const targetProperty = { ...property, name: 'target' };

  const formik = useFormik({
    onSubmit,
    initialValues,
    validate,
  });
  const { submitForm } = formik;

  const buttonIsDisabled = checkSubmitIsDisabled(formik) || loading;

  const onOpenConfirm = () => setConfirmOpen(true);

  const onCloseConfirm = () => setConfirmOpen(false);

  const onApprove = () => {
    submitForm();
    onCloseConfirm();
  };

  const customFilter = (formikInstance) => (valueObject) => {
    const { value: newValue } = valueObject || {};
    const values = formikInstance?.values;
    const valueExists = _values(values).find((v) => v?.value && newValue === v?.value);

    return !valueExists;
  };

  const renderError = () => {
    const errorMessage = _get(error, 'errors[0].message');
    if (!errorMessage) return null;
    return <Typography>{errorMessage}</Typography>;
  };

  const autocompleteProps = {
    className: classes.field,
    placeholder: 'Type name',
    showLabel: false,
    customFilter,
    formik,
    canCreateNew: false,
  };

  return (
    <>
      <DialogContent>
        <div className={classes.root}>
          <Typography className={classes.description}>
            Pick the item which you want replace
          </Typography>
          <PropertyFieldAutocomplete
            id="replacing"
            property={replacingProperty}
            {...autocompleteProps}
            {...getFormikAutocompleteProps({ name: 'replacing', formik })}
          />
          <Typography className={classes.description}>Pick the target item</Typography>
          <PropertyFieldAutocomplete
            id="target"
            property={targetProperty}
            {...autocompleteProps}
            {...getFormikAutocompleteProps({ name: 'target', formik })}
          />
          {renderError()}
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={onOpenConfirm}
          disabled={buttonIsDisabled}
          variant="contained"
          color="primary"
        >
          Replace
        </Button>
      </DialogActions>
      <Loader show={loading} absolute />
      <SaveConfirmPopup
        onSave={onApprove}
        open={confirmOpen}
        onClose={onCloseConfirm}
        saveText="Replace"
      />
    </>
  );
};

ReplaceCollectionTypePropertyItemForm.propTypes = {
  error: PropTypes.object,
  propertyTypesOptions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  initialValues: PropTypes.object,
  property: PropTypes.object.isRequired,
};

ReplaceCollectionTypePropertyItemForm.defaultProps = {
  error: {},
  propertyTypesOptions: [],
  loading: false,
  submitButtonText: 'Save',
  initialValues: {},
};

export default ReplaceCollectionTypePropertyItemForm;
