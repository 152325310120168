import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import UploadIcon from '@material-ui/icons/Publish';

import { MEDIA_TYPES } from '../../../helpers/fileTypes';
import { getFormikDropzoneProps } from '../helpers';

import Dropzone from '../dropzone';

const useStyles = makeStyles((theme) => ({
  dropzoneWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0,
    margin: 0,
    width: '100%',
    minWidth: 100,
    maxHeight: '100%',
    height: '100%',
  },
  dropzoneTip: {
    fontSize: '1rem',
    color: theme.palette.text.pale,
    marginTop: theme.spacing(1),
  },
  uploadIcon: {
    height: theme.spacing(20),
    width: theme.spacing(20),
    stroke: theme.palette.border.icon,
  },
}));

const PropertyFieldMedia = (props) => {
  const {
    property,
    formik,
    classes: outerClasses,
    dropzoneClasses,
    onUploaded,
    showLabel,
    imageFit,
    mediaSize,
    aspectRatio,
  } = props;
  const { id, name, formName } = property;
  const classes = useStyles();

  const renderLabel = () => {
    if (!showLabel) return null;
    return <span className={classes.dropzoneTip}>{name}</span>;
  };

  return (
    <div className={clsx(classes.dropzoneWrapper, outerClasses.dropzoneWrapper)}>
      <Dropzone
        id={id}
        className={dropzoneClasses.root}
        classes={dropzoneClasses}
        dataTest={`property-field__${name}`}
        max={1}
        types={MEDIA_TYPES}
        content={<UploadIcon className={classes.uploadIcon} />}
        variant="single"
        hideEditButton
        imageFit={imageFit}
        mediaSize={mediaSize}
        aspectRatio={aspectRatio}
        {...getFormikDropzoneProps({ formik, name: formName || id, onUploaded })}
      />
      {renderLabel()}
    </div>
  );
};

PropertyFieldMedia.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    formName: PropTypes.string,
  }).isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    media: PropTypes.string,
    dropzoneTip: PropTypes.string,
    dropzoneWrapper: PropTypes.string,
    uploadIcon: PropTypes.string,
  }),
  showLabel: PropTypes.bool,
  onUploaded: PropTypes.func,
  dropzoneClasses: PropTypes.object,
  imageFit: PropTypes.oneOf(['cover', 'contain']),
  mediaSize: PropTypes.array,
  aspectRatio: PropTypes.string,
};

PropertyFieldMedia.defaultProps = {
  classes: {},
  dropzoneClasses: {},
  showLabel: true,
  onUploaded: () => {},
  imageFit: 'contain',
  mediaSize: [],
  aspectRatio: 'auto',
};

export default PropertyFieldMedia;
