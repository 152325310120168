import { gql } from '@apollo/client';

export const REPORTS = gql`
  query Reports(
    $status: String
    $reporter: ID
    $respondent: ID
    $type: ID
    $limit: Int
    $next: String
    $previous: String
  ) {
    reports(
      status: $status
      reporter: $reporter
      respondent: $respondent
      type: $type
      limit: $limit
      next: $next
      previous: $previous
    ) {
      results {
        id
        entity {
          name
          type
        }
        description
        created_at
        reporter {
          id
          name
        }
        respondent {
          id
          name
        }
        status
      }
      hasNext
      next
      previous
      hasPrevious
      resultsNumber
    }
  }
`;

export const REPORT = gql`
  query Report($id: ID!) {
    report(id: $id) {
      id
      url
      description
      created_at
      reporter {
        id
        name
        email
        slug
      }
      respondent {
        id
        name
        email
        slug
      }
      status
      entity {
        name
        type
      }
      reason {
        name
      }
      history {
        date
        decision
        action
        status
      }
      notes {
        note
        date
      }
      operator {
        name
      }
      reportEntity {
        actions
      }
      changeRequests {
        id
        text
        resolved
      }
    }
  }
`;

export const REPORT_ENTITIES = gql`
  {
    reportEntities {
      name
      slug
    }
  }
`;

export const REPORT_STATUSES = gql`
  {
    reportStatuses {
      value
      label
    }
  }
`;
