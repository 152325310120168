export function checkType(types, files) {
  if (!Array.isArray(types) || !types.length) return null;

  let error = null;

  files.map((file) => {
    if (error) return;

    const fileType = file.type;
    const hasFormat = types.map((type) => type.mimetype).includes(fileType);

    if (hasFormat) return;

    const fileFormats = types.map((type) => type.format);
    const formatsString = [...new Set(fileFormats)].join(', ');

    error = `Wrong file type! You can upload only files with ${formatsString} ${
      types.length === 1 ? 'type' : 'types'
    }.`;
    return error;
  });

  return error;
}

export function checkMax(max, files) {
  if (!max || !Array.isArray(files) || !files.length) return null;
  if (files.length <= max) return null;
  return `Too many files! You can upload only ${max} ${max === 1 ? 'file' : 'files'}.`;
}

export function checkFileMaxSize(maxMb, files) {
  if (!maxMb || !Array.isArray(files) || !files.length) return null;

  let error = null;

  files.map((file) => {
    if (error) return;

    const fileSizeInMb = file.size / 1000000; // 1000000 bytes in 1 Mb

    if (fileSizeInMb <= maxMb) return null;

    error = `Too big file size! Max size of file is ${maxMb}Mb.`;
    return error;
  });

  return error;
}
