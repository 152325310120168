import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';

import { EDIT_ADVERTISMENT } from '../../graphql/mutation/advertisement';
import {
  ADVERTISEMENT_DASHBOARD,
  FETCH_EDIT_ADVERTISEMENT,
} from '../../graphql/query/advertisement';

import AdvertisementForm from '../../components/advertisement-form';
import BackLink from '../../components/common/back-link';
import Loader from '../../components/common/loader';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  form: {
    marginTop: theme.spacing(4),
  },
}));
const AdvertisementEdit = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const { data: advertisementData, loading: advertisementLoading } = useQuery(
    FETCH_EDIT_ADVERTISEMENT,
    { variables: { id } },
  );
  const advertisement = advertisementData?.advertisement;

  const [editAdvertisement, { loading: editLoading }] = useMutation(EDIT_ADVERTISMENT, {
    onCompleted: onCreationComplete,
    refetchQueries: [{ query: ADVERTISEMENT_DASHBOARD }],
  });

  function onCreationComplete() {
    history.push('/advertisement');
  }

  const onSubmit = (values) => {
    editAdvertisement({ variables: { id, advertisement: values } });
  };

  const getPropertyInitValue = ({ id: propertyId, name }) => ({ id: propertyId, name });

  const formatInitProperties = (properties) => {
    if (_isEmpty(properties)) return [];
    return properties.map(({ id: propertyId, name, collectionType, collectionTypeProperty }) => ({
      collection_type: getPropertyInitValue(collectionType),
      collection_type_property: getPropertyInitValue(collectionTypeProperty),
      collection_type_property_list_item: { id: propertyId, name },
    }));
  };

  const { published, expire, name, url, client, media, properties } = advertisement || {};

  const initialValues = {
    published,
    properties: formatInitProperties(properties),
    expire,
    name,
    url,
    client_id: { label: client?.client, value: client?.id },
    media: [media],
  };

  const renderForm = () => {
    if (!advertisement) return null;
    return (
      <div className={classes.form}>
        <AdvertisementForm
          initialValues={initialValues}
          submitLoading={editLoading}
          onSubmit={onSubmit}
          clicks={advertisement?.clicks}
          views={advertisement?.views}
        />
      </div>
    );
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <BackLink to="/advertisement">Back to list</BackLink>
      {renderForm()}
      <Loader show={advertisementLoading} absolute transparentBackground />
    </Container>
  );
};

export default AdvertisementEdit;
