import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _indexOf from 'lodash/indexOf';
import { makeStyles } from '@material-ui/core/styles';
import { Popover, InputAdornment } from '@material-ui/core';
import { Colorize } from '@material-ui/icons';

import { getBaseInputStyles } from '../base-input';
import { DEFAULT_COLOR_PICKER_COLORS } from '../../../constants';

import TextInput from '../text-input';
import ColorPickerModal from './color-picker-modal';
import ColorPickerMultipleInput from './multiple-input';

const useStyles = makeStyles((theme) => ({
  ...getBaseInputStyles(theme),
  wrapper: ({ colors, multiple }) => ({
    flex: 1,
    display: 'flex',
    backgroundColor: multiple ? 'transparent' : _get(colors, '[0]', 'transparent'),
    transition: 'background-color 200ms ease',
    '& > div': {
      flex: 1,
    },
    '& + &': {
      marginLeft: theme.spacing(1),
    },
    '& input': {
      cursor: 'pointer',
      color: 'transparent !important',
      backgroundColor: 'inherit',
      transition: 'background-color 200ms ease',
    },
    '& .Mui-disabled': {
      color: theme.palette.text.pale,
    },
    '& .Mui-focused': {
      color: theme.palette.primary.main,
    },
  }),
  iconWrapper: {
    backgroundColor: 'rgba(255,255,255,.4)',
    width: 28,
    height: 28,
    minWidth: 28,
    minHeight: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  pickerWrapper: {
    padding: theme.spacing(1.5),
  },
  picker: ({ width, circleSpacing }) => ({
    width,
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: -circleSpacing,
    marginBottom: -circleSpacing,
  }),
  icon: {
    height: 22,
    width: 22,
  },
  chip: {
    width: 50,
    maxWidth: 50,
    minWidth: 50,
    border: `1px solid ${theme.palette.border.main}`,
    position: 'relative',
    margin: '2px 4px 2px 0 ',
    height: 28,
    minHeight: 28,
    flex: 0,
    zIndex: 1,
    '&:before': {
      content: '""',
      color: 'transparent',
      position: 'absolute',
      height: 20,
      width: 20,
      top: 'calc(50% - 10px)',
      right: 3,
      borderRadius: '50%',
      border: `1px solid rgba(255,255,255,.4)`,
    },
    '&:last-of-type': {
      margin: '0 12px 0 0 ',
    },
  },
  chipIcon: {
    position: 'absolute',
    height: 22,
    width: 22,
    top: 'calc(50% - 11px)',
    right: 2,
    zIndex: 1,
    margin: 0,
    color: theme.palette.icon.main,
  },
  popoverPaper: {
    marginTop: theme.spacing(1),
  },
}));

const ColorPicker = ({
  value,
  onChange,
  onBlur,
  id,
  label,
  required,
  multiple,
  colorsLimit,
  colors,
  circleSize,
  circleSpacing,
  width,
  classes: outerClasses,
}) => {
  const checkedColors = getCheckedColors(value);
  const classes = useStyles({ colors: checkedColors, width, circleSpacing, multiple });

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => setOpen(false), [value]);

  function getCheckedColors(valueColors) {
    if (valueColors && Array.isArray(valueColors)) return valueColors;
    if (valueColors && typeof valueColors === 'string') return [valueColors];
    return [];
  }

  const onClickOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    onBlur();
  };

  const onDeleteColor = (deletedColor) => {
    const index = _indexOf(checkedColors, deletedColor);
    const newValue = [...checkedColors];
    newValue[index] = undefined;
    onBlur();
    onChange(newValue);
  };

  const onAddColor = (newColor) => {
    onBlur();
    if (multiple) {
      const valueArray = Array.isArray(value) ? value : [];
      if (valueArray.includes(newColor)) return onChange(valueArray.slice(-colorsLimit));
      const newArray = [...valueArray];
      const index = currentItem ?? valueArray.length - 1;
      newArray[index] = newColor;
      return onChange(newArray.slice(-colorsLimit));
    }
    onChange(newColor);
  };

  const renderSwatch = (color) => (
    <ColorPickerModal
      key={color}
      color={color}
      onClick={onAddColor}
      active={checkedColors.includes(color)}
      circleSize={circleSize}
      circleSpacing={circleSpacing}
    />
  );

  const renderPicker = <div className={classes.picker}>{colors.map(renderSwatch)}</div>;

  const renderSingleInput = (
    <TextInput
      aria-describedby={id}
      // value={value}
      id={id}
      label={label}
      onClick={onClickOpen}
      disabled
      required={required}
      classes={classes}
      endAdornment={
        <InputAdornment className={classes.iconWrapper} position="end">
          <Colorize className={classes.icon} />
        </InputAdornment>
      }
    />
  );

  const renderMultipleInput = (
    <ColorPickerMultipleInput
      aria-describedby={id}
      value={value}
      id={id}
      label={label}
      onClickOpen={onClickOpen}
      setCurrentItem={setCurrentItem}
      value={value}
      focused={open}
      colorsLimit={colorsLimit}
      onDeleteColor={onDeleteColor}
      classes={outerClasses}
    />
  );

  const renderInput = multiple ? renderMultipleInput : renderSingleInput;

  return (
    <div className={classes.wrapper}>
      {renderInput}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{ paper: classes.popoverPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.pickerWrapper}>{renderPicker}</div>
      </Popover>
    </div>
  );
};

ColorPicker.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
  circleSize: PropTypes.number,
  circleSpacing: PropTypes.number,
  width: PropTypes.number,
  colorsLimit: PropTypes.number,
  classes: PropTypes.object,
};

ColorPicker.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  label: '',
  required: false,
  multiple: true,
  colors: DEFAULT_COLOR_PICKER_COLORS,
  circleSize: 24,
  circleSpacing: 10,
  width: 204,
  colorsLimit: 3,
  classes: {},
};

export default ColorPicker;
