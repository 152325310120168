import { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import CreateCollectionTypePropertyItemModal from '../create-collection-type-property-item-modal';

const CreateCollectionTypePropertyItemButton = (props) => {
  const { updateItems } = props;
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const onOpenCreateModal = () => setOpenCreateModal(true);

  const closeCreateModal = () => setOpenCreateModal(false);

  return (
    <>
      <Button
        onClick={onOpenCreateModal}
        color="secondary"
        variant="contained"
        startIcon={<AddIcon />}
        size="small"
      >
        <span>Create item</span>
      </Button>
      <CreateCollectionTypePropertyItemModal
        updateItems={updateItems}
        open={openCreateModal}
        onClose={closeCreateModal}
      />
    </>
  );
};

CreateCollectionTypePropertyItemButton.propTypes = {
  updateItems: PropTypes.func,
};

CreateCollectionTypePropertyItemButton.defaultProps = {
  updateItems: () => {},
};

export default CreateCollectionTypePropertyItemButton;
