import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Tabs, Tab } from '@material-ui/core';
import _map from 'lodash/map';
import _capitalize from 'lodash/capitalize';

import PagesHome from '../pages-home';
import PagesExplore from '../pages-explore';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  tabsRoot: {
    marginBottom: theme.spacing(2),
  },
  tabsIndicator: {
    display: 'none',
  },
  tab: {
    height: '100%',
    minWidth: 'auto',
    fontSize: 14,
    fontWeight: 600,
    position: 'relative',
    transition: 'color 100ms ease',
    color: theme.palette.text.pale,
    '&:hover': {
      color: theme.palette.text.light,
    },
  },
  selected: {
    color: theme.palette.text.primary,
  },
}));

const Pages = () => {
  const classes = useStyles();
  const tabs = ['home', 'explore'];
  const [tab, setTab] = useState('home');

  const onTabSelect = (e, newValue) => {
    setTab(newValue);
  };

  const renderTab = (tabName) => (
    <Tab
      key={tabName}
      label={_capitalize(tabName)}
      value={tabName}
      classes={{
        root: classes.tab,
        selected: classes.selected,
      }}
    />
  );

  const renderTabs = _map(tabs, renderTab);

  const tabsContent = {
    home: <PagesHome />,
    explore: <PagesExplore />,
  };

  const content = tabsContent[tab];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Tabs
        onChange={onTabSelect}
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.tabsFlexContainer,
          indicator: classes.tabsIndicator,
        }}
        value={tab}
        TabIndicatorProps={{ children: <span /> }}
      >
        {renderTabs}
      </Tabs>
      {content}
    </Container>
  );
};

Pages.propTypes = {};

Pages.defaultProps = {};

export default Pages;
