import { useEffect, useState } from 'react';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, useQuery } from '@apollo/client';

import { SEARCH_COLLECTION_TYPE_PROPERTY_LIST_ITEMS } from '../../../graphql/query/collectionTypePropertyListItems';
import { CREATE_RECENT_COLLECTION_TYPE_PROPERTY_ITEM } from '../../../graphql/mutation/collectionTypePropertyListItems';

import { getFormikAutocompleteProps } from '../helpers';

import Autocomplete from '../autocomplete';
import TextInput from '../text-input';

const useStyles = makeStyles({
  thumbnail: {
    height: 16,
    width: 16,
    marginRight: 8,
    objectFit: 'contain',
  },
  option: {
    display: 'inline-flex',
    alignItems: 'center',
  },
});

const PropertyFieldAutocomplete = (props) => {
  const {
    property,
    formik,
    classes: autocompleteClasses,
    showLabel,
    customFilter,
    canCreateNew,
  } = props;
  const { id, name, formName, description } = property || {};
  const classes = useStyles();
  const [term, setTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const value = _get(formik, `values[${name}]`) || _get(formik, `values[${formName}]`);
  const placeholder = open ? 'Start typing...' : description;

  const { data, loading, refetch } = useQuery(SEARCH_COLLECTION_TYPE_PROPERTY_LIST_ITEMS, {
    variables: { propertyId: id, filter: { term } },
    notifyOnNetworkStatusChange: true,
  });

  const [createRecentCollectionTypePropertyItem] = useMutation(
    CREATE_RECENT_COLLECTION_TYPE_PROPERTY_ITEM,
    {
      refetchQueries: [
        {
          query: SEARCH_COLLECTION_TYPE_PROPERTY_LIST_ITEMS,
          variables: { propertyId: id, filter: { term: '' } },
        },
      ],
      onCompleted: () => refetch({ filter: { term: '' } }),
    },
  );

  useEffect(() => {
    if (term && term === value?.label) return;
    refetch({ filter: { term } });
  }, [term]);

  useEffect(() => {
    if (!loading) {
      const newItems = data?.searchRecentCollectionTypePropertyListItems?.results || [];
      setItems(newItems);
    }
  }, [loading]);

  const type = data?.searchRecentCollectionTypePropertyListItems?.type;
  const isRecent = type === 'Recent';

  const filterValues = (valueObject) => {
    const { label, value: filteringValue } = valueObject;
    if (!label || !filteringValue) return false;
    if (!customFilter) return term !== label;
    return customFilter(formik)(valueObject);
  };

  const formatItem = (item) => {
    const { name: itemName, id: propertyId, media } = item || {};
    const thumbnail = media?.src;
    return { value: propertyId, label: itemName, data: { thumbnail, type } };
  };

  const groupByType = (option) => option?.data?.type;

  const groupBy = isRecent ? groupByType : null;

  const formatSelectItems = _map(items, formatItem).filter(filterValues);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  const onTermChange = (e, termValue) => {
    setTerm(termValue);
  };

  const onInputChange = _debounce(onTermChange, 500);

  const onChange = ({ value: newValue, reason }) => {
    const valueId = newValue?.value;
    const itemsIds = _map(items, ({ id: itemId }) => itemId);
    const isOption = itemsIds.includes(valueId);
    const isNewOption = reason === 'create-option' || reason === 'select-option';

    if (isNewOption && valueId && isOption) {
      createRecentCollectionTypePropertyItem({
        variables: { property_id: id, list_item_id: valueId },
      });
    }
  };

  const renderOption = ({ label, data: optionData }) => {
    const thumbnail = optionData?.thumbnail;
    const renderThumbnail = (
      <img
        className={classes.thumbnail}
        src={thumbnail}
        width={50}
        height={50}
        alt={label}
        loading="lazy"
      />
    );
    return (
      <span className={classes.option}>
        {thumbnail && renderThumbnail}
        {label}
      </span>
    );
  };

  const renderInput = (autocompleteParams, inputParams) => {
    const endAdornment = (
      <>
        {loading ? <CircularProgress color="inherit" size={16} /> : null}
        {autocompleteParams.InputProps.endAdornment}
      </>
    );
    const params = {
      ...autocompleteParams,
      InputProps: { ...autocompleteParams.InputProps, margin: 'none', endAdornment },
    };
    return <TextInput {...inputParams} {...params} />;
  };

  return (
    <Autocomplete
      className={autocompleteClasses.list}
      id={id}
      label={showLabel ? name : ''}
      options={formatSelectItems}
      customizedOptions
      placeholder={placeholder}
      renderOption={renderOption}
      autoSelect
      onInputChange={onInputChange}
      renderInput={renderInput}
      filterSelectedOptions={false}
      groupBy={groupBy}
      onOpen={onOpen}
      onClose={onClose}
      canCreateNew={canCreateNew}
      {...getFormikAutocompleteProps({ formik, name: formName || name, onChange })}
    />
  );
};

PropertyFieldAutocomplete.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string,
    formName: PropTypes.string,
    id: PropTypes.string,
    property_type: PropTypes.oneOf([
      'String',
      'List',
      'Number',
      'Color',
      'Media',
      'Select',
      'Options',
      'Number + String',
    ]),
    items: PropTypes.array,
    description: PropTypes.string,
  }).isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    string: PropTypes.string,
    number: PropTypes.string,
    list: PropTypes.string,
    color: PropTypes.string,
    media: PropTypes.string,
    thumbnail: PropTypes.string,
    option: PropTypes.string,
  }),
  showLabel: PropTypes.bool,
  customFilter: PropTypes.func,
  canCreateNew: PropTypes.bool,
};

PropertyFieldAutocomplete.defaultProps = {
  classes: {},
  showLabel: true,
  customFilter: null,
  canCreateNew: true,
};

export default PropertyFieldAutocomplete;
