import PropTypes from 'prop-types';

import { getFormikColorProps } from '../helpers';

import ColorPicker from '../color-picker';

const PropertyFieldColorPicker = (props) => {
  const { property, formik, classes } = props;
  const { id, name, multiple, formName } = property;

  return (
    <ColorPicker
      className={classes.color}
      id={id}
      label={name}
      multiple={multiple > 1}
      colorsLimit={multiple}
      {...getFormikColorProps({ formik, name: formName || name })}
    />
  );
};

PropertyFieldColorPicker.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    multiple: PropTypes.number,
    formName: PropTypes.string,
  }).isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    color: PropTypes.string,
  }),
};

PropertyFieldColorPicker.defaultProps = {
  classes: {},
};

export default PropertyFieldColorPicker;
