import { gql } from '@apollo/client';

export const CREATE_COLLECTION_TYPE = gql`
  mutation CreateCollectionType($collectionType: CollectionTypeInput!) {
    createCollectionType(collectionType: $collectionType) {
      id
      name
    }
  }
`;

export const EDIT_COLLECTION_TYPE = gql`
  mutation EditCollectionType($id: ID!, $collectionType: CollectionTypeInput!) {
    editCollectionType(id: $id, collectionType: $collectionType) {
      id
      name
    }
  }
`;
