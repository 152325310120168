import { gql } from '@apollo/client';

export const GET_COLLECTION_TYPES = gql`
  query CollectionTypes($limit: Int, $next: String, $previous: String, $name: String) {
    collectionTypes(limit: $limit, next: $next, previous: $previous, name: $name) {
      results {
        id
        name
        slug
      }
      hasNext
      next
      previous
      hasPrevious
      resultsNumber
    }
  }
`;

export const GET_COLLECTION_TYPE = gql`
  query getCollectionType($id: ID!) {
    collectionType(id: $id) {
      id
      name
      slug
      properties
    }
  }
`;
