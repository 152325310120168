import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button } from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';
import dayjs from 'dayjs';
import { DASHBOARD_TABS } from '../../constants';
import { constantToString } from '../../helpers/constant';
import useHash from '../../hooks/useHash';

import {
  MEDIA_OPTIMIZATION_REQUEST,
  MEDIA_OPTIMIZATION_REQUESTS,
} from '../../graphql/query/mediaOptimizationRequest';
import { SET_MEDIA_OPTIMIZATION_REQUEST_STATUS } from '../../graphql/mutation/mediaOptimizationRequest';
import { REPORTER_OPTIONS } from '../../graphql/query/user';

import DashboardTabs from '../../components/dashboard-tabs';
import DashboardTable from '../../components/dashboard-table';
import DashboardFilters from '../../components/dashboard-filters';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    display: 'grid',
    gridTemplateRows: `${theme.spacing(6)}px ${theme.spacing(4.5)}px 1fr`,
    gridGap: theme.spacing(2),
    boxSizing: 'border-box',
  },
  tabs: {},
  filters: {},
  table: {
    overflow: 'hidden',
  },
}));

const ImagesDashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const hash = useHash();
  const [currentTab, setCurrentTab] = useState(hash || 'NEW');
  const [reporter, setReporter] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const variables = {
    status: currentTab,
    reporter: reporter?.value,
    limit: rowsPerPage,
  };
  const refetchQueries = DASHBOARD_TABS.map(({ value }) => ({
    query: MEDIA_OPTIMIZATION_REQUESTS,
    variables: { ...variables, status: value },
  }));
  const [getReporters, { data: reportersData }] = useLazyQuery(REPORTER_OPTIONS);
  const [getRequests, { data, loading, refetch: requestsRefetch }] = useLazyQuery(
    MEDIA_OPTIMIZATION_REQUESTS,
  );
  const requestsData = data?.mediaOptimizationRequests;
  const requests = requestsData?.results;

  const reporters = reportersData?.searchUsers?.results || [];
  const reporterOptions = reporters.map((entry) => getOption(entry));
  const [SetMediaOptimizationRequestStatus] = useMutation(SET_MEDIA_OPTIMIZATION_REQUEST_STATUS);

  useEffect(() => {
    getReporters({ variables: { query: '' } });
  }, []);

  useEffect(() => {
    getRequests({ variables });
  }, [variables.status, variables.reporter]);

  const COLUMNS = [
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      format: ({ status }) => constantToString(status).toUpperCase(),
    },
    { id: 'reporter.name', label: 'Reporter', minWidth: 100 },
    {
      id: 'created_at',
      label: 'Date',
      minWidth: 50,
      format: ({ created_at }) => dayjs(created_at).format('DD.MM.YYYY'),
    },
    { id: 'description', label: 'Description', minWidth: 170 },
    {
      id: 'ignore',
      label: '',
      minWidth: 70,
      width: 70,
      format: (report) => getTableButton(report),
    },
  ];

  const onPageChange = ({ direction }) => {
    const hasNewPage = requestsData[`has${_capitalize(direction)}`];
    if (!hasNewPage) return;

    const next = direction === 'next' ? requestsData.next : '';
    const previous = direction === 'previous' ? requestsData.previous : '';
    const newPageVariables = { ...variables, next, previous };
    requestsRefetch(newPageVariables);
  };

  function getOption(item, valueProperty = 'id', labelProperty = 'name') {
    return { value: _get(item, valueProperty), label: _get(item, labelProperty) };
  }

  const getRowLink = ({ id }) => `/images/${id}`;

  const setStatus = (id, action) => {
    SetMediaOptimizationRequestStatus({
      variables: { id, action },
      refetchQueries: [...refetchQueries, { query: MEDIA_OPTIMIZATION_REQUEST, variables: { id } }],
    });
  };

  function getTableButton(report) {
    return (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          setStatus(report?.id, 'IGNORE');
        }}
      >
        Ignore
      </Button>
    );
  }

  const onTabSelect = (e, newValue) => {
    setCurrentTab(newValue);
    history.push(`/images#${newValue}`);
  };

  const onReporterChange = (e, value) => {
    getReporters({ variables: { query: value } });
  };

  const filterFields = [
    {
      placeholder: 'Filter by reporter',
      id: 'reporter',
      value: reporter,
      onInputChange: _debounce(onReporterChange, 200),
      options: reporterOptions,
      onChange: (e, value) => setReporter(value || {}),
    },
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.tabs}>
        <DashboardTabs tabs={DASHBOARD_TABS} onSelect={onTabSelect} tab={currentTab} />
      </div>
      <div className={classes.filters}>
        <DashboardFilters fields={filterFields} />
      </div>
      <div className={classes.table}>
        <DashboardTable
          data={requests}
          loading={loading}
          columns={COLUMNS}
          getRowLink={getRowLink}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={requestsData?.resultsNumber}
          onPageChange={onPageChange}
        />
      </div>
    </Container>
  );
};

export default ImagesDashboard;
