import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _flatten from 'lodash/flatten';
import { Button } from '@material-ui/core';

import PropertyField from '../form/property-field';
import { getInitLineValues } from './helpers';
import { checkSubmitIsDisabled } from '../form/helpers';

const useStyles = makeStyles((theme) => ({
  form: {},
  fields: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2, 0),
    flexDirection: 'column',
  },
  fieldWrapper: {
    width: '100%',
    flex: 1,
    maxWidth: '100%',
    display: 'flex',
    backgroundColor: theme.palette.background.card,
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    position: 'relative',
    flexDirection: 'column',
  },
  field: {
    width: '100%',
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  listField: {
    width: '100%',
  },
  stringField: {
    width: '100%',
  },
}));

const ExploreForm = (props) => {
  const { onSubmit, initialValues, exploreLines } = props;
  const classes = useStyles();
  const VALUE_PREFIX = 'propertyValue';
  const properties = _map(exploreLines, ({ property }) => property);
  const initPropertyValues = getInitLineValues(exploreLines, VALUE_PREFIX);
  const formikInitValues = {
    ...initialValues,
    ...initPropertyValues,
  };

  const formik = useFormik({
    initialValues: formikInitValues,
    onSubmit,
    validate: () => ({}),
  });
  const { values: formValues, handleSubmit } = formik;
  const fields = getFields(properties, formValues);
  const submitIsDisabled = checkSubmitIsDisabled(formik);

  function filterListProperties({ property_type }) {
    return property_type !== 'List';
  }

  function getListPropertyField(property, values) {
    const id = property?.id;
    const propertyValues = _get(values, `[${id}].${VALUE_PREFIX}`);
    return _map(propertyValues, (value, i) => ({
      ...property,
      formName: `${id}.${VALUE_PREFIX}[${i}]`,
    }));
  }

  function getListPropertyFields(lineProperty, values) {
    if (_isEmpty(lineProperty)) return [];
    const listFields = _map(lineProperty, (prop) => getListPropertyField(prop, values));
    return _flatten(listFields);
  }

  function getNotListPropertyField(property) {
    return { ...property, formName: `${property?.id}.${VALUE_PREFIX}` };
  }

  function getNotListPropertyFields(lineProperty, values) {
    if (_isEmpty(lineProperty)) return [];
    const listFields = _map(lineProperty, (prop) => getNotListPropertyField(prop, values));
    return _flatten(listFields);
  }

  function getFields(lineProperty, values) {
    const listProps = _filter(lineProperty, { property_type: 'List' });
    const notListProps = _filter(lineProperty, filterListProperties);
    const listFields = getListPropertyFields(listProps, values);
    const notListFields = getNotListPropertyFields(notListProps, values);
    const propertiesFields = _orderBy([...notListFields, ...listFields], ['order'], ['asc']);
    return propertiesFields;
  }

  const renderListField = (property) => (
    <div key={property?.formName || property?.id} className={classes.fieldWrapper}>
      <div className={classes.field}>
        <PropertyField
          property={property}
          formik={formik}
          classes={{ list: classes.listField }}
          formName={property?.formName}
        />
      </div>
    </div>
  );

  const renderColorField = (property) => {
    const formName = `${property?.id}.nameValue`;
    const colorNameFiledProps = {
      formik,
      classes: { string: classes.stringField },
      property: {
        formName,
        name: 'Colors line name',
        id: formName,
        property_type: 'String',
      },
    };
    return (
      <div key={property?.formName || property?.id} className={classes.fieldWrapper}>
        <div className={classes.field}>
          <PropertyField {...colorNameFiledProps} />
        </div>
        <div className={classes.field}>
          <PropertyField property={property} formik={formik} formName={property?.formName} />
        </div>
      </div>
    );
  };

  const renderField = (property) => {
    const type = property?.property_type;

    if (type === 'Color') return renderColorField(property);

    return renderListField(property);
  };

  const renderFields = <div className={classes.fields}>{_map(fields, renderField)}</div>;

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      {renderFields}
      <Button type="submit" variant="contained" color="secondary" disabled={submitIsDisabled}>
        Save
      </Button>
    </form>
  );
};

ExploreForm.propTypes = {
  exploreLines: PropTypes.array,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

ExploreForm.defaultProps = {
  exploreLines: [],
  initialValues: {},
};

export default ExploreForm;
