import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, DialogTitle } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { EDIT_COLLECTION_TYPE_PROPERTY } from '../../graphql/mutation/collectionTypeProperties';
import {
  GET_COLLECTION_TYPE_PROPERTIES_TYPES,
  GET_COLLECTION_TYPE_PROPERTY,
} from '../../graphql/query/collectionTypeProperties';

import CollectionTypePropertyForm from '../collection-type-property-form';
import Loader from '../common/loader';

const EditCollectionTypePropertyModal = (props) => {
  const { onClose, updateProperties, property, loading, ...modalProps } = props;
  const propertyId = property?.id;
  const theme = useTheme();

  const [editCollectionTypeProperty, { loading: editLoading, error }] = useMutation(
    EDIT_COLLECTION_TYPE_PROPERTY,
    {
      onCompleted,
      refetchQueries: [{ query: GET_COLLECTION_TYPE_PROPERTY, variables: { id: propertyId } }],
    },
  );

  const { data: typesData } = useQuery(GET_COLLECTION_TYPE_PROPERTIES_TYPES);
  const propertyTypes = typesData?.collectionTypePropertyTypes || [];
  const propertyTypesOptions = propertyTypes.map((typeName) => ({
    label: typeName,
    value: typeName,
  }));

  function onCompleted() {
    updateProperties();
    onClose();
  }

  function onSubmit(variables) {
    editCollectionTypeProperty({
      variables: { ...variables, id: propertyId },
    });
  }

  const renderForm = (
    <CollectionTypePropertyForm
      error={error}
      propertyTypesOptions={propertyTypesOptions}
      onSubmit={onSubmit}
      onClose={onClose}
      loading={editLoading}
      initialValues={property}
    />
  );

  return (
    <Dialog onClose={onClose} {...modalProps}>
      <DialogTitle>Edit collection type property</DialogTitle>
      {property && renderForm}
      <Loader show={loading} minHeight={260} minWidth={theme.spacing(66)} />
    </Dialog>
  );
};

EditCollectionTypePropertyModal.propTypes = {
  property: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  onClose: PropTypes.func.isRequired,
  updateProperties: PropTypes.func,
  loading: PropTypes.bool,
};

EditCollectionTypePropertyModal.defaultProps = {
  updateProperties: () => {},
  loading: false,
  property: null,
};

export default EditCollectionTypePropertyModal;
