/* eslint-disable react/jsx-no-target-blank */
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LinkIcon from '@material-ui/icons/Link';
import env from '@beam-australia/react-env';
import clsx from 'clsx';
import { Typography, Chip, IconButton } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { useMutation, useQuery } from '@apollo/client';

import { SET_MEDIA_OPTIMIZATION_REQUEST_STATUS } from '../../graphql/mutation/mediaOptimizationRequest';
import {
  MEDIA_OPTIMIZATION_REQUEST,
  MEDIA_OPTIMIZATION_REQUEST_STATUSES,
} from '../../graphql/query/mediaOptimizationRequest';

import Select from '../form/select';
import BackLink from '../common/back-link';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
  },
  information: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  controls: {
    marginLeft: theme.spacing(2),
  },
  control: {
    display: 'flex',
    flexDirection: 'column',
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  row: {
    marginTop: theme.spacing(2),
  },
  flexRow: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  name: {
    marginLeft: theme.spacing(2),
    fontSize: 20,
    fontWeight: 700,
  },
  entryLink: {
    marginLeft: theme.spacing(1),
  },
  discalimer: {
    fontSize: 12,
    fontWeight: 700,
  },
  select: {
    backgroundColor: theme.palette.background.select,
    borderRadius: theme.spacing(0.5),
    minWidth: 220,
    marginTop: theme.spacing(1),
  },
}));

const ImageReportDescription = (props) => {
  const { request } = props;
  const classes = useStyles();
  const { item, description, reporter, id, status, operator } = request || {};
  const itemUrl = createUrl(item?.slug);
  const refetchQueries = [{ query: MEDIA_OPTIMIZATION_REQUEST, variables: { id } }];
  const { data } = useQuery(MEDIA_OPTIMIZATION_REQUEST_STATUSES);
  const [setMediaOptimizationRequestStatus] = useMutation(SET_MEDIA_OPTIMIZATION_REQUEST_STATUS, {
    refetchQueries,
  });
  const statuses = data?.mediaOptimizationRequestStatuses || [];

  function createUrl(slug) {
    return `${env('SITE_URL')}/?slug=${slug}&itemType=single&modalType=item`;
  }

  const onSetStatus = (value) => {
    setMediaOptimizationRequestStatus({ variables: { id, status: value } });
  };

  const renderOperator = () => {
    if (!operator) return null;
    return (
      <div className={classes.row}>
        <Typography className={classes.discalimer}>Operator</Typography>
        <Typography>{operator?.name}</Typography>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <BackLink to="/images">Back to list</BackLink>
      <div className={classes.content}>
        <div className={classes.information}>
          <div className={classes.flexRow}>
            <Chip
              color="primary"
              className={classes.type}
              label="Collection Item"
              variant="outlined"
            />
            <Typography className={classes.name}>{item?.name}</Typography>
          </div>
          <div className={classes.flexRow}>
            <IconButton size="small" onClick={() => copy(itemUrl)}>
              <LinkIcon />
            </IconButton>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={itemUrl}
              className={clsx(classes.link, classes.entryLink)}
            >
              {itemUrl}
            </a>
          </div>
          <div className={classes.row}>
            <Typography className={classes.discalimer}>Description</Typography>
            <Typography>{description}</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.discalimer}>Reporter</Typography>
            <Typography>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={createUrl(`/collector/${reporter?.slug}`)}
                className={classes.link}
              >
                {`${reporter?.name} <${reporter?.email}>`}
              </a>
            </Typography>
          </div>
        </div>
        <div className={classes.controls}>
          <div className={classes.control}>
            <Typography className={classes.discalimer}>Set a status</Typography>
            <Select
              id="status"
              options={statuses}
              value={status}
              onChange={onSetStatus}
              classes={{ root: classes.select }}
            />
          </div>
          {renderOperator()}
        </div>
      </div>
    </div>
  );
};

ImageReportDescription.propTypes = {
  request: PropTypes.shape({
    url: PropTypes.string,
    entity: PropTypes.object,
    reason: PropTypes.object,
    description: PropTypes.string,
    reportEntity: PropTypes.object,
  }).isRequired,
};

ImageReportDescription.defaultProps = {};

export default ImageReportDescription;
