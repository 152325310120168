import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import _isEmpty from 'lodash/isEmpty';
import _reverse from 'lodash/reverse';

import TextInput from '../form/text-input';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'flex-start',
  },
  inputWrapper: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  input: {
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.border.input}`,
    padding: `0 ${theme.spacing(1)}px !important`,
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
  sendButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  date: {
    marginRight: theme.spacing(2),
    display: 'inline',
  },
  stage: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    alignItems: 'center',
  },
}));

const ReportNotes = (props) => {
  const { value, onChange, onSubmit, notes } = props;
  const classes = useStyles();

  const renderNote = ({ date, note }) => (
    <Typography key={date} className={classes.stage}>
      <span className={classes.date}>{dayjs(date).format('DD.MM.YYYY')}</span>
      {note}
    </Typography>
  );

  const renderNotes = () => {
    if (_isEmpty(notes)) return null;
    const reversedNotes = _reverse([...notes]);
    return reversedNotes.map(renderNote);
  };

  return (
    <div className={classes.root}>
      <TextInput
        id="note"
        value={value}
        onChange={onChange}
        rows={2}
        multiline
        className={classes.inputWrapper}
        InputProps={{ classes: { root: classes.input } }}
        placeholder="Add note"
      />
      <Button
        fullWidth={false}
        variant="contained"
        size="small"
        onClick={() => onSubmit(value)}
        disabled={!value}
        className={classes.sendButton}
        color="primary"
      >
        Add
      </Button>
      {renderNotes()}
    </div>
  );
};

ReportNotes.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  notes: PropTypes.array,
};

ReportNotes.defaultProps = {
  notes: [],
};

export default ReportNotes;
