import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import keycloak from './keycloak';
import typePolicies from '../graphql/typePolicies';

const cacheOptions = { typePolicies };

const httpLink = createHttpLink({
  uri: window?.__ENV?.REACT_APP_API_URL,
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  const token = keycloak?.token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  uri: window?.__ENV?.REACT_APP_API_URL,
  cache: new InMemoryCache(cacheOptions),
  link: authLink.concat(httpLink),
});

export default client;
