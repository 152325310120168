import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

import { colorIsLight } from '../../../helpers/color';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    minWidth: theme.spacing(4),
    height: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.control,
    padding: `${theme.spacing(0.5)}px 0`,
    flex: 1,
    transition: 'background-color 200ms ease, width 200ms ease',
    '&+&': {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: theme.palette.border.colorCircle,
    },
  },
  colorExample: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: ({ color }) => color,
  },
  iconWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    transform: ({ color }) => (color ? 'rotate(45deg)' : 'none'),
    transition: 'transform 200ms ease',
  },
  lightIcon: {
    color: theme.palette.icon.darkGray,
  },
  darkIcon: {
    color: theme.palette.icon.lightGray,
  },
}));

const ColorPickerInput = (props) => {
  const { color, onClick, onItemDelete, index, classes: outerClasses } = props;
  const classes = useStyles({ color });
  const isLight = useMemo(() => colorIsLight(color || '#fff') === 'light', [color]);

  const onItemClick = (e) => {
    onClick(e, index, color);
  };

  const onIconClick = (e) => {
    if (!color) return onClick(e, index, color);
    e.nativeEvent.stopImmediatePropagation();
    onItemDelete(color);
  };

  return (
    <div
      className={clsx(classes.wrapper, outerClasses.colorInput)}
      onClick={onItemClick}
      tabIndex={0}
      onKeyDown={onItemClick}
      role="button"
    >
      <div className={classes.colorExample}>
        <div className={classes.iconWrapper}>
          <AddIcon
            className={clsx(classes.icon, isLight ? classes.lightIcon : classes.darkIcon)}
            fontSize="small"
            onClick={onIconClick}
          />
        </div>
      </div>
    </div>
  );
};

ColorPickerInput.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  classes: PropTypes.object,
};

ColorPickerInput.defaultProps = {
  color: '',
  classes: {},
};

export default ColorPickerInput;
