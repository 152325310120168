import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  back: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
  },
  backIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    marginRight: theme.spacing(0.5),
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const BackLink = (props) => {
  const { to, classes: outerClasses, children } = props;
  const classes = useStyles();

  return (
    <Link className={clsx(classes.back, classes.link, outerClasses.root)} to={to}>
      <ArrowBackIcon className={clsx(classes.backIcon, outerClasses.icon)} />
      {children}
    </Link>
  );
};

BackLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
};

BackLink.defaultProps = {
  classes: {},
};

export default BackLink;
