import { gql } from '@apollo/client';

export const SET_REPORT_STATUS = gql`
  mutation SetReportStatus($id: ID!, $status: String!) {
    setReportStatus(id: $id, status: $status) {
      id
      entity {
        name
      }
      description
      created_at
      reporter {
        id
        name
      }
      respondent {
        id
        name
      }
      status
    }
  }
`;

export const SET_REPORT_ACTION = gql`
  mutation SetReportAction($id: ID!, $action: String!) {
    setReportAction(id: $id, action: $action) {
      id
      entity {
        name
      }
      description
      created_at
      reporter {
        id
        name
      }
      respondent {
        id
        name
      }
      status
    }
  }
`;

export const ADD_REPORT_NOTE = gql`
  mutation AddReportNote($id: ID!, $text: String!) {
    addReportNote(id: $id, text: $text) {
      id
      entity {
        name
      }
      description
      created_at
      reporter {
        id
        name
      }
      respondent {
        id
        name
      }
      status
    }
  }
`;

export const SEND_REPORT_CHANGE_REQUEST = gql`
  mutation SendReportChangeRequest($id: ID!, $text: String!) {
    sendReportChangeRequest(id: $id, text: $text) {
      id
      text
    }
  }
`;

export const EDIT_REPORT_CHANGE_REQUEST_STATUS = gql`
  mutation EditReportChangeRequestStatus($id: ID!, $resolved: Boolean!) {
    editReportChangeRequestStatus(id: $id, resolved: $resolved) {
      id
    }
  }
`;

export const DELETE_REPORT_CHANGE_REQUEST = gql`
  mutation DeleteReportChangeRequest($id: ID!) {
    deleteReportChangeRequest(id: $id) {
      id
    }
  }
`;
