import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  errors: {
    position: 'absolute',
    color: theme.palette.error.main,
    left: 0,
    right: 0,
    top: 0,
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.shadow.light,
  },
  error: {
    '& + &': {
      marginTop: 8,
    },
  },
}));

const DropzoneErrors = (props) => {
  const { errors } = props;

  const classes = useStyles();

  if (!Array.isArray(errors) || !errors.length) return null;

  const renderError = (error) => (
    <span className={classes.error} key={error}>
      {error}
    </span>
  );

  return <div className={classes.errors}>{errors.map(renderError)}</div>;
};

DropzoneErrors.propTypes = {
  errors: PropTypes.array,
};

DropzoneErrors.defaultProps = {
  errors: [],
};

export default DropzoneErrors;
