import PropTypes from 'prop-types';

const PropertyDashboardThumbnail = ({ property }) => {
  const media = property?.media;
  const src = media?.src;

  if (!src) return null;

  return (
    <img
      alt="thumbnail"
      loading="lazy"
      width={50}
      height={50}
      src={src}
      style={{ objectFit: 'contain' }}
    />
  );
};

PropertyDashboardThumbnail.propTypes = {
  property: PropTypes.shape({ media: PropTypes.object }),
};

PropertyDashboardThumbnail.defaultProps = {
  property: { media: {} },
};

export default PropertyDashboardThumbnail;
