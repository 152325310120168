import { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import { Chip, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';

import { constantToString } from '../../helpers/constant';

import { ADD_REPORT_NOTE, SEND_REPORT_CHANGE_REQUEST } from '../../graphql/mutation/reports';
import { REPORT } from '../../graphql/query/reports';

import ReportNotes from '../report-notes';
import ReportChangeRequests from '../report-change-requests';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  statuses: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  status: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  statusChip: {
    color: theme.palette.text.white,
    borderRadius: theme.spacing(0.5),
  },
  main: {
    display: 'flex',
    marginTop: theme.spacing(3),
    alignItems: 'stretch',
    position: 'relative',
    height: '100%',
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(2)}px 0 0`,
    alignItems: 'flex-start',
    '& + &': {
      borderLeft: `1px solid ${theme.palette.border.main}`,
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  historyStages: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  historyStage: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    alignItems: 'center',
  },
  historyDate: {
    marginRight: theme.spacing(2),
    display: 'inline',
  },
  historyStatus: {
    marginRight: theme.spacing(1),
    border: `1px solid ${theme.palette.text.primary}`,
    padding: `0 ${theme.spacing(1)}px`,
  },
  historyDecision: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
}));

const ReportWorkspace = (props) => {
  const { report } = props;

  const classes = useStyles();
  const theme = useTheme();
  const { history, notes, changeRequests } = report;
  const [noteText, setNoteText] = useState('');
  const [changeRequestText, setChangeRequestText] = useState('');

  const [addReportNote] = useMutation(ADD_REPORT_NOTE, {
    refetchQueries: [{ query: REPORT, variables: { id: report?.id } }],
  });

  const [sendReportChangeRequest] = useMutation(SEND_REPORT_CHANGE_REQUEST, {
    refetchQueries: [{ query: REPORT, variables: { id: report?.id } }],
  });

  const getColor = (status) => {
    const colors = {
      WORK_IN_PROGRESS: theme.palette.status.start,
      PENDING_RESPONSE: theme.palette.status.pending,
      RESOLVED_BY_USER: theme.palette.status.pending,
      IGNORE: theme.palette.status.ignore,
      default: theme.palette.primary.main,
    };
    const color = colors[status];
    if (color) return color;
    return colors.default;
  };

  const onNoteChange = (e) => setNoteText(e.target.value);

  const onNoteSend = () => {
    addReportNote({ variables: { id: report?.id, text: noteText } });
    setNoteText('');
  };

  const onChangeRequestChange = (e) => setChangeRequestText(e.target.value);

  const onChangeRequestSend = () => {
    sendReportChangeRequest({ variables: { id: report?.id, text: changeRequestText } });
    setChangeRequestText('');
  };

  const renderStatus = ({ status = '' }, i) => {
    if (!status) return null;
    const label = status.replaceAll('_', ' ').toLowerCase();
    const icon = i !== 0 ? <ArrowRightAltIcon /> : null;
    const color = getColor(status);
    return (
      <div className={classes.status} key={i}>
        {icon}
        <Chip
          className={classes.statusChip}
          style={{ backgroundColor: color }}
          label={_capitalize(label)}
        />
      </div>
    );
  };

  const renderStatuses = (statuses) => {
    if (_isEmpty(statuses)) return null;
    return statuses.map(renderStatus).filter(Boolean);
  };

  const renderHistoryStage = ({ date, decision, action, status }) => {
    const stageDecision = decision ? `(${constantToString(decision).toUpperCase()})` : null;
    return (
      <div key={date} className={classes.historyStage}>
        <Typography className={classes.historyDate}>{dayjs(date).format('DD.MM.YYYY')}</Typography>
        <Typography className={classes.historyStatus}>{constantToString(status)}</Typography>
        <Typography>{constantToString(action)}</Typography>
        <Typography className={classes.historyDecision}>{stageDecision}</Typography>
      </div>
    );
  };

  const renderHistoryStages = () => {
    if (_isEmpty(history)) return null;
    return history.map(renderHistoryStage);
  };

  if (_isEmpty(report)) return null;

  return (
    <div className={classes.root}>
      <Typography>Status</Typography>
      <div className={classes.statuses}>{renderStatuses(history)}</div>
      <div className={classes.main}>
        <div className={classes.section}>
          <Typography>History</Typography>
          <div className={classes.historyStages}>{renderHistoryStages()}</div>
        </div>
        <div className={classes.section}>
          <Typography>Notes</Typography>
          <ReportNotes
            value={noteText}
            onChange={onNoteChange}
            onSubmit={onNoteSend}
            notes={notes}
          />
        </div>
        <div className={classes.section}>
          <Typography>Change requests</Typography>
          <ReportChangeRequests
            reportId={report?.id}
            value={changeRequestText}
            onChange={onChangeRequestChange}
            onSubmit={onChangeRequestSend}
            changeRequests={changeRequests}
          />
        </div>
      </div>
    </div>
  );
};

ReportWorkspace.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string,
    history: PropTypes.array,
    notes: PropTypes.array,
    changeRequests: PropTypes.array,
  }).isRequired,
};

ReportWorkspace.defaultProps = {};

export default ReportWorkspace;
