import { makeStyles } from '@material-ui/core/styles';
import { useParams, Link } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import env from '@beam-australia/react-env';

import { GET_NEWS } from '../../graphql/query/news';

import Loader from '../../components/common/loader';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  content: {
    display: 'flex',
  },
  information: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    marginTop: theme.spacing(2),
  },
  back: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
  },
  backIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    marginRight: theme.spacing(0.5),
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  name: {
    marginLeft: theme.spacing(2),
    fontSize: 20,
    fontWeight: 700,
  },
  discalimer: {
    fontSize: 12,
    fontWeight: 700,
  },
}));

const News = () => {
  const classes = useStyles();
  const { id } = useParams();

  const { data, loading } = useQuery(GET_NEWS, { variables: { id } });
  const news = data?.news;
  const { user, title, desc } = news || {};

  function createUrl(slug) {
    return `${env('SITE_URL')}/?slug=${slug}&itemType=single&modalType=item`;
  }

  const renderContent = () => {
    if (!news) return null;
    return (
      <div className={classes.content}>
        <div className={classes.information}>
          <div className={classes.row}>
            <Typography className={classes.discalimer}>Title</Typography>
            <Typography className={classes.name}>{title}</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.discalimer}>Description</Typography>
            <Typography>{desc}</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.discalimer}>Creator</Typography>
            <Typography>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={createUrl(`/collector/${user?.slug}`)}
                className={classes.link}
              >
                {`${user?.name} <${user?.email}>`}
              </a>
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Link className={clsx(classes.back, classes.link)} to="/news">
        <ArrowBackIcon className={classes.backIcon} />
        Back to list
      </Link>
      {renderContent()}
      <Loader show={!data || loading} absolute transparentBackground />
    </Container>
  );
};

export default News;
