import { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import { GET_COLLECTION_TYPE_PROPERTY } from '../../graphql/query/collectionTypeProperties';

import EditCollectionTypePropertyModal from '../edit-collection-type-property-modal';

const EditCollectionTypePropertyButton = ({ updateProperties, property: editingProperty }) => {
  const { id } = editingProperty || {};
  const [open, setOpen] = useState(false);

  const [getProperty, { data: propertyData, loading }] = useLazyQuery(GET_COLLECTION_TYPE_PROPERTY);
  const property = propertyData?.collectionTypeProperty || null;

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    getProperty({ variables: { id } });
    setOpen(true);
  };

  return (
    <>
      <IconButton size="small" onClick={onClick}>
        <EditIcon />
      </IconButton>
      <EditCollectionTypePropertyModal
        property={property}
        open={open}
        onClose={() => setOpen(false)}
        loading={loading}
        updateProperties={updateProperties}
      />
    </>
  );
};

EditCollectionTypePropertyButton.propTypes = {
  property: PropTypes.shape({ id: PropTypes.string }).isRequired,
  updateProperties: PropTypes.func,
};

EditCollectionTypePropertyButton.defaultProps = {
  updateProperties: () => {},
};

export default EditCollectionTypePropertyButton;
