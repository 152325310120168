import { string, date, array, object } from 'yup';

export const validateString = (value, options = {}) => {
  const { required, requiredMessage } = options;
  const requiredSchema = string().required(requiredMessage);

  try {
    let schema = string();

    if (required) {
      schema = schema.concat(required ? requiredSchema : null);
    }

    const isValid = schema.isValidSync(value);
    if (isValid) return undefined;
    const error = schema.validateSync(value);
    return error.message;
  } catch (error) {
    return requiredMessage;
  }
};

export const validateDate = (value, options = {}) => {
  const { required, requiredMessage } = options;
  const requiredSchema = date().required(requiredMessage);

  try {
    let schema = date();

    if (required) {
      schema = schema.concat(required ? requiredSchema : null);
    }

    const isValid = schema.isValidSync(value);
    if (isValid) return undefined;
    const error = schema.validateSync(value);
    return error.message;
  } catch (error) {
    return requiredMessage;
  }
};

export const validateArray = (value, options = {}) => {
  const { required, requiredMessage } = options;
  const requiredSchema = array().required(requiredMessage);

  try {
    let schema = array();

    if (required) {
      schema = schema.concat(required ? requiredSchema : null);
    }

    const isValid = schema.isValidSync(value);
    if (isValid) return undefined;
    const error = schema.validateSync(value);
    return error.message;
  } catch (error) {
    return requiredMessage;
  }
};

export const validateAutocompleteValue = (value, options = {}) => {
  const { required, requiredMessage } = options;
  const requiredSchema = object().required(requiredMessage);

  try {
    let schema = object().shape({
      label: string(),
      value: string(),
    });

    if (required) {
      schema = schema.concat(required ? requiredSchema : null);
    }

    const isValid = schema.isValidSync(value);
    if (isValid) return undefined;
    const error = schema.validateSync(value);
    return error.message;
  } catch (error) {
    return requiredMessage;
  }
};

export const validateEmailIsRequired = (value, invalidMessage, requiredMessage) => {
  try {
    const schema = string().email(invalidMessage).required(requiredMessage);
    const isValid = schema.isValidSync(value);
    if (isValid) return undefined;
    const error = schema.validateSync(value);
    return error.message;
  } catch (error) {
    return error.message;
  }
};

export const removeEmptyProperties = (errors = {}) => {
  return Object.entries(errors).reduce((newErrors, [key, value]) => {
    if (!value) return newErrors;
    return { ...newErrors, [key]: value };
  }, {});
};
