import { useCallback, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Collapse, FormHelperText } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import browserImageSize from 'browser-image-size';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { MEDIA_TYPES } from '../../../helpers/fileTypes';

import { checkMax } from './validate';

import MediaService from '../../../services/media';
import DropzoneCard from './card';
import DropzoneErrors from './errors';
import DropzoneSingle from './single';
import DropzoneButton from './button';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: `1px dashed ${theme.palette.border.input}`,
    height: 220,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'border 200ms ease, box-shadow 200ms ease',
    position: 'relative',
    userSelect: 'none',
    outline: 'none',

    '&.active, &:hover': {
      border: `1px dashed ${theme.palette.primary.main}`,
      '& svg': {
        color: theme.palette.primary.main,
      },
    },

    '&.error': {
      borderColor: theme.palette.error.main,
    },
  },
  buttonWrapper: {
    height: 'auto',
    width: 'auto',
    border: 'none',
    '&.active, &:hover': {
      border: 'none',
    },
  },
  cardsWrapper: ({ isOutside }) => ({
    maxHeight: '100%',
    maxWidth: isOutside ? `calc(100% + ${theme.spacing(3) * 2}px)` : '100%',
    overflow: 'auto',
    height: '100%',
    width: isOutside ? `calc(100% + ${theme.spacing(3) * 2}px)` : '100%',
    margin: isOutside
      ? `0 -${theme.spacing(3)}px ${theme.spacing(2)}px`
      : `0 0 ${theme.spacing(2)}px`,
    padding: isOutside ? 0 : 16,
    display: 'flex',
  }),
  cardsOutside: {
    display: 'flex',
    minWidth: '100%',
    width: '100%',
    '& .dropzone-card + .dropzone-card': {
      marginLeft: theme.spacing(1),
    },
  },
  cardsOutsideSlider: {
    padding: `0 ${theme.spacing(3)}px`,
    flex: 1,
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
    gridGap: theme.spacing(1),
    width: '100%',
    minWidth: '100%',
    '@media screen and (min-width: 800px)': {
      gridGap: theme.spacing(2),
    },
  },
  remove: {
    position: 'absolute',
    top: 4,
    right: 4,
    cursor: 'pointer',
  },
  content: {
    color: theme.palette.text.pale,
    fontSize: '1rem',
    verticalAlign: 'center',
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

const Dropzone = forwardRef((props, ref) => {
  const {
    max,
    types,
    onFileUploaded,
    onRemoveFile,
    files,
    maxMbSize,
    content,
    activeContent,
    onFileDialogCancel,
    onDragLeave,
    variant,
    helperText,
    formError,
    imageFit,
    initKey,
    dataTest,
    className,
    hideEditButton,
    buttonProps,
    children,
    multiple,
    classes: outerClasses,
  } = props;
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();

  const isOutside = variant === 'outside';
  const isSingle = variant === 'single';
  const isButton = variant === 'button';
  const classes = useStyles({ isOutside });
  const client = useApolloClient();
  const [errors, setErrors] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [lastUploadedFile, setLastUploadedFile] = useState();
  const onDrop = useCallback(handleDrop, []);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: types.map(({ mimetype }) => mimetype),
    onFileDialogCancel,
    onDragLeave,
    maxSize: maxMbSize ? maxMbSize * 1000000 : null, // 1000000 bytes in 1 Mb
    multiple,
  });

  useImperativeHandle(ref, () => ({
    addError: (error) => addError(error),
    setErrors: (parentErrors) => setErrors(parentErrors),
    open,
  }));

  useEffect(() => {
    if (!lastUploadedFile) return;
    // For get files list after media uploading
    onFileUploaded({
      file: lastUploadedFile,
      files,
      max: isSingle ? 1 : max,
      deleteFileCallback: deleteFromStorage,
    });
  }, [lastUploadedFile]);

  // FUNCS

  function addError(error) {
    setErrors((oldErrors) => [...oldErrors, error]);
  }

  const getErrors = (acceptedFiles) => {
    const maxError = checkMax(max, acceptedFiles);

    return [maxError].filter(Boolean);
  };

  function deleteFromStorage(file) {
    const mediaId = _get(file, 'key');
    if (!mediaId) return;
    if (mediaId === initKey) return;
    MediaService.delete(mediaId);
  }

  const onRemoveClick = (file) => {
    onRemoveFile(file, files);
    deleteFromStorage(file);
  };

  const onCardRemoveClick = (e, file) => {
    e.stopPropagation();
    e.preventDefault();
    onRemoveClick(file);
  };

  const updateErrors = (acceptedFiles) => {
    setErrors([]);
    const dropErrors = getErrors(acceptedFiles);
    dropErrors.map((error) => addError(error));
    return dropErrors;
  };

  const getTime = () => new Date().toISOString().slice(0, 19).replace(/-/g, '').replace(/:/g, '');

  const addPreview = (name, key, previewUrl) => {
    setPreviews((oldPreviews) => [...oldPreviews, { name, key, previewUrl }]);
  };

  const removePreview = (fileName, previewUrl) => {
    setPreviews((oldPreviews) => oldPreviews.filter(({ key }) => key !== fileName));
    URL.revokeObjectURL(previewUrl);
  };

  const renderHelperText = () => {
    if (!helperText) return null;
    return <FormHelperText error={Boolean(formError)}>{helperText}</FormHelperText>;
  };

  const onError = (errorMessage) => {
    enqueueSnackbar(errorMessage, {
      variant: 'error',
      persist: true,
    });
  };

  async function handleDrop(acceptedFiles) {
    if (_isEmpty(acceptedFiles)) return;

    const dropErrors = updateErrors(acceptedFiles);

    if (!_isEmpty(dropErrors)) return;

    const uploadFile = async (file) => {
      const time = getTime();
      const fileName = `${time}_${file.name}`;
      const previewUrl = URL.createObjectURL(file);

      addPreview(file.name, fileName, previewUrl);

      const [uploadedFile, imageSize] = await Promise.all([
        await MediaService.upload({ client, file, fileName }, { onError }),
        browserImageSize(file),
      ]);

      removePreview(fileName, previewUrl);

      if (uploadedFile?.error) {
        return onError(uploadedFile?.error);
      }

      const { key, original_name, src } = uploadedFile?.data || {};
      const size = [imageSize.width, imageSize.height];
      setLastUploadedFile({
        key,
        original_name,
        src,
        name: file.name,
        size,
      });
    };

    acceptedFiles.map(uploadFile);
  }

  // RENDER

  const renderCard = (file, isPreview) => {
    if (!file) return null;

    const { name, original_name, src, previewUrl } = file;
    const fileName = original_name || name || '';

    return (
      <DropzoneCard
        key={fileName}
        name={fileName}
        url={src || previewUrl}
        isPreview={isPreview}
        isLoading={isPreview}
        onRemove={(e) => onCardRemoveClick(e, file)}
      />
    );
  };

  const renderCards = (acceptedFiles, isPreview) => {
    if (_isEmpty(acceptedFiles)) return null;
    return acceptedFiles.map((file) => renderCard(file, isPreview));
  };

  const renderPlaceholder = () => {
    const textClass = clsx(classes.content, formError && 'error');

    if (isDragActive) {
      return <span className={textClass}>{activeContent || t('Drop the files here ...')}</span>;
    }
    return (
      <span className={textClass}>
        {content || t("Drag 'n' drop some files here, or click to select files")}
      </span>
    );
  };

  const renderSingle = () => {
    const file = _get(files, '[0]');
    const preview = _get(previews, '[0]');
    return (
      <DropzoneSingle
        file={file || preview || {}}
        isPreview={!file}
        imageFit={imageFit}
        hideEditButton={hideEditButton}
        classes={outerClasses}
      />
    );
  };

  const renderContent = () => {
    if (isOutside) return renderPlaceholder(isDragActive);

    if (isButton) return <DropzoneButton {...buttonProps}>{children}</DropzoneButton>;

    if (_isEmpty(files) && _isEmpty(previews)) return renderPlaceholder(isDragActive);

    if (isSingle) return renderSingle(files, previews);

    return (
      <div className={classes.cardsWrapper}>
        <div className={classes.cards}>
          {renderCards(files, false)}
          {renderCards(previews, true)}
        </div>
      </div>
    );
  };

  const renderCardsOutside = () => {
    if (!isOutside) return null;

    const hasFiles = !_isEmpty(files);
    const hasPreviews = !_isEmpty(previews);
    const isOpen = !!(hasFiles || hasPreviews);

    return (
      <Collapse in={isOpen}>
        <div className={classes.cardsWrapper}>
          <div className={classes.cardsOutsideSlider}>
            <div className={classes.cardsOutside}>
              {renderCards(files, false)}
              {renderCards(previews, true)}
            </div>
          </div>
        </div>
      </Collapse>
    );
  };

  // RETURN

  return (
    <>
      {renderCardsOutside()}
      <div
        {...getRootProps()}
        className={clsx(
          classes.wrapper,
          isDragActive && classes.wrapperActive,
          isButton && classes.buttonWrapper,
          formError && 'error',
          className,
        )}
        data-test={dataTest}
      >
        <input data-test={`${dataTest}_input`} {...getInputProps()} />
        {renderContent()}
        <DropzoneErrors errors={errors} />
      </div>
      {renderHelperText()}
    </>
  );
});

Dropzone.propTypes = {
  max: PropTypes.number,
  types: PropTypes.array,
  onFileUploaded: PropTypes.func,
  maxMbSize: PropTypes.number,
  content: PropTypes.node,
  activeContent: PropTypes.node,
  files: PropTypes.array,
  id: PropTypes.string.isRequired,
  onRemoveFile: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  onDragLeave: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'outside', 'single', 'button']),
  helperText: PropTypes.string,
  formError: PropTypes.string,
  imageFit: PropTypes.string,
  initKey: PropTypes.string,
  dataTest: PropTypes.string,
  className: PropTypes.string,
  hideEditButton: PropTypes.bool,
  buttonProps: PropTypes.object,
  children: PropTypes.node,
  multiple: PropTypes.bool,
  classes: PropTypes.object,
};

Dropzone.defaultProps = {
  files: [],
  onRemoveFile: () => {},
  content: '',
  activeContent: '',
  max: 10,
  types: MEDIA_TYPES,
  onFileUploaded: () => {},
  maxMbSize: 50,
  onFileDialogCancel: () => {},
  onDragLeave: () => {},
  variant: 'default',
  helperText: '',
  formError: '',
  imageFit: 'initial',
  initKey: '',
  dataTest: '',
  className: '',
  hideEditButton: false,
  buttonProps: {},
  children: '',
  multiple: true,
  classes: {},
};

export default Dropzone;
