import PropTypes from 'prop-types';

import PropertyFieldTextInput from './property-field-text-input';
import PropertyFieldSelect from './property-field-select';
import PropertyFieldNumberInput from './property-field-number-input';
import PropertyFieldAutocomplete from './property-field-autocomplete';
import PropertyFieldColorPicker from './property-field-color-picker';
import PropertyFieldMedia from './property-field-media';
import PropertyFieldNumberPlusString from './property-field-number-plus-string';

const PropertyField = (props) => {
  const { property = {} } = props;
  const { property_type } = property || {};

  const fieldTypes = {
    String: PropertyFieldTextInput,
    Number: PropertyFieldNumberInput,
    List: PropertyFieldAutocomplete,
    Color: PropertyFieldColorPicker,
    Media: PropertyFieldMedia,
    Select: PropertyFieldSelect,
    'Number + String': PropertyFieldNumberPlusString,
    default: PropertyFieldTextInput,
  };

  const Field = fieldTypes[property_type] || fieldTypes.default;

  return <Field {...props} />;
};

PropertyField.propTypes = {
  property: PropTypes.shape({
    property_type: PropTypes.oneOf([
      'String',
      'List',
      'Number',
      'Color',
      'Media',
      'Select',
      'Options',
      'Number + String',
    ]),
  }).isRequired,
  formik: PropTypes.object.isRequired,
};

export default PropertyField;
