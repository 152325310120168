import { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useLazyQuery } from '@apollo/client';
import _capitalize from 'lodash/capitalize';
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';

import {
  GET_COLLECTION_TYPE_PROPERTIES,
  GET_COLLECTION_TYPE_PROPERTIES_TYPES,
} from '../../graphql/query/collectionTypeProperties';
import { GET_COLLECTION_TYPES } from '../../graphql/query/collectionTypes';

import DashboardTable from '../dashboard-table';
import DashboardFilters from '../dashboard-filters';
import CreateCollectionTypePropertyButton from '../create-collection-type-property-button';
import EditCollectionTypePropertyButton from '../edit-collection-type-property-button';
import CollectionTypePropertyOptionsLink from '../collection-type-property-options-link';

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    marginTop: theme.spacing(2),
    overflow: 'hidden',
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const CollectionTypePropertiesCatalogue = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [name, setName] = useState('');
  const [type, setType] = useState({});
  const [propertyType, setPropertyType] = useState({});

  const variables = {
    limit: rowsPerPage,
    collectionType: type?.value,
    name,
    propertyType: propertyType?.value,
  };
  const {
    data,
    loading,
    refetch: refetchProperties,
  } = useQuery(GET_COLLECTION_TYPE_PROPERTIES, {
    variables,
  });
  const propertiesData = data?.collectionTypeProperties;
  const properties = propertiesData?.results;

  const [getTypes, { data: typesData }] = useLazyQuery(GET_COLLECTION_TYPES);
  const types = typesData?.collectionTypes?.results || [];
  const typeOptions = types.map(({ name: typeName, id }) => ({ label: typeName, value: id }));

  const { data: propertyTypesData } = useQuery(GET_COLLECTION_TYPE_PROPERTIES_TYPES);
  const propertyTypes = propertyTypesData?.collectionTypePropertyTypes || [];
  const propertyTypesOptions = propertyTypes.map((typeName) => ({
    label: typeName,
    value: typeName,
  }));

  const debounceUpdateProperties = useCallback(_debounce(updateProperties, 500), []);

  useEffect(() => {
    getTypes({ variables: { limit: 50 } });
  }, []);

  useEffect(() => {
    debounceUpdateProperties({ ...variables, name });
  }, [name]);

  useEffect(() => {
    updateProperties();
  }, [rowsPerPage, type?.value, propertyType?.value]);

  const COLUMNS = [
    {
      id: 'name',
      label: 'Name',
      width: 200,
      minWidth: 200,
    },
    {
      id: 'description',
      label: 'Description',
      minWidth: 200,
    },
    {
      id: 'collectionTypes',
      label: 'Collection type',
      minWidth: 120,
      format: ({ collectionTypes = [] }) => _map(collectionTypes, ({ name: n }) => n).join(', '),
    },
    {
      id: 'property_type',
      label: 'Property type',
      minWidth: 120,
      width: 120,
    },
    {
      id: 'options',
      label: 'Options',
      minWidth: 40,
      width: 40,
      format: (property) => <CollectionTypePropertyOptionsLink property={property} />,
    },
    {
      id: 'edit',
      minWidth: 40,
      width: 40,
      format: (property) => (
        <EditCollectionTypePropertyButton property={property} updateProperties={updateProperties} />
      ),
    },
  ];

  function updateProperties(newVariables = {}) {
    setPage(0);
    refetchProperties({ ...variables, ...newVariables });
  }

  const onChangeName = (e) => {
    const value = e?.target?.value;
    setName(value);
  };

  const onPageChange = ({ direction }) => {
    const hasNewPage = typesData[`has${_capitalize(direction)}`];
    if (!hasNewPage) return;
    const next = direction === 'next' ? typesData.next : '';
    const previous = direction === 'previous' ? typesData.previous : '';
    const newPageVariables = { ...variables, next, previous };
    refetchProperties(newPageVariables);
  };

  const onTypeChange = (e, value) => {
    if (!value) return null;
    getTypes({ variables: { name: value } });
  };

  const filterFields = [
    {
      type: 'text',
      placeholder: 'Filter by name',
      id: 'name',
      value: name,
      onChange: onChangeName,
    },
    {
      id: 'collection_type',
      placeholder: 'Filter by type',
      value: type,
      onInputChange: _debounce(onTypeChange, 500),
      options: typeOptions,
      onChange: (e, value) => setType(value || {}),
    },
    {
      value: propertyType,
      onChange: (e, value) => setPropertyType(value || {}),
      id: 'property_type',
      placeholder: 'Filter by property type',
      options: propertyTypesOptions,
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.filters}>
        <DashboardFilters fields={filterFields} />
        <CreateCollectionTypePropertyButton updateProperties={updateProperties} />
      </div>
      <div className={classes.table}>
        <DashboardTable
          data={properties}
          loading={loading}
          columns={COLUMNS}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={typesData?.resultsNumber}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default CollectionTypePropertiesCatalogue;
