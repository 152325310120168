import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';

import Logo from '../../components/common/logo';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    zIndex: 999999,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#09C3B8',
    color: '#FFF',
  },
  logo: {
    width: '20vw',
    height: 'auto',
  },
  title: {
    fontSize: '2em',
    textTransform: 'uppercase',
    lineHeight: 1,
    margin: 0,
  },
  container: {
    width: '20vw',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

const Relogin = ({ logout }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Logo classNames={classes.logo} secondaryColor={theme.palette.primary.main} />
        <h1>{t('Only operator can use this console')}</h1>
        <Button variant="contained" onClick={logout} disableElevation size="large">
          {t('Logout')}
        </Button>
      </div>
    </div>
  );
};

Relogin.propTypes = {
  logout: PropTypes.func,
};

Relogin.defaultProps = {
  logout: () => {},
};

export default Relogin;
