import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, Button, Typography } from '@material-ui/core';
import _get from 'lodash/get';
import { string, object } from 'yup';
import { useFormik } from 'formik';

import { getFormikInputProps, getFormikSelectProps, checkSubmitIsDisabled } from '../form/helpers';

import TextInput from '../form/text-input';
import Select from '../form/select';

const validationSchema = object().shape({
  name: string().required('Name is required'),
  description: string().required('Description is required'),
  property_type: string().required('Property type is required'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(60),
    maxWidth: theme.spacing(60),
  },
  field: {
    width: '100%',
    '& + &': {
      marginTop: theme.spacing(3),
    },
  },
  error: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

const CollectionTypePropertyForm = (props) => {
  const {
    error,
    propertyTypesOptions,
    onSubmit,
    loading,
    onClose,
    submitButtonText,
    initialValues,
  } = props;
  const classes = useStyles();

  const formik = useFormik({
    validationSchema,
    onSubmit: onFormSubmit,
    initialValues,
  });
  const { submitForm, values, setFieldValue } = formik;
  const typeValue = values?.property_type;
  const showMultiple = ['List', 'Options', 'Select'].includes(typeValue);
  const buttonIsDisabled = checkSubmitIsDisabled(formik) || loading;

  useEffect(() => {
    if (!showMultiple) {
      setFieldValue('multiple', 1);
    }
  }, [typeValue]);

  const renderError = () => {
    const errorMessage = _get(error, 'errors[0].message');
    if (!errorMessage) return null;
    return <Typography>{errorMessage}</Typography>;
  };

  function onFormSubmit(submitValues = {}) {
    const collectionTypeProperty = {
      name: submitValues?.name,
      description: submitValues?.description,
      property_type: submitValues?.property_type,
      multiple: submitValues?.multiple || 1,
    };
    onSubmit({ collectionTypeProperty });
  }

  const renderMultipleField = (
    <TextInput
      id="multiple"
      className={classes.field}
      label="Multiple"
      placeholder="Pick multiple number"
      type="number"
      {...getFormikInputProps({ name: 'multiple', formik })}
    />
  );

  return (
    <>
      <DialogContent>
        <div className={classes.root}>
          <TextInput
            id="name"
            className={classes.field}
            label="Name"
            placeholder="Type name"
            {...getFormikInputProps({ name: 'name', formik })}
          />
          <TextInput
            id="description"
            className={classes.field}
            label="Description"
            placeholder="Type description"
            multiline
            rows={3}
            rowsMax={6}
            {...getFormikInputProps({ name: 'description', formik })}
          />
          <Select
            id="property_type"
            options={propertyTypesOptions}
            className={classes.field}
            label="Property type"
            placeholder="Select property type"
            {...getFormikSelectProps({ name: 'property_type', formik })}
          />
          {showMultiple && renderMultipleField}
          {renderError()}
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={submitForm}
          disabled={buttonIsDisabled}
          variant="contained"
          color="primary"
        >
          {submitButtonText}
        </Button>
      </DialogActions>
    </>
  );
};

CollectionTypePropertyForm.propTypes = {
  error: PropTypes.object,
  propertyTypesOptions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  initialValues: PropTypes.object,
};

CollectionTypePropertyForm.defaultProps = {
  error: {},
  propertyTypesOptions: [],
  loading: false,
  submitButtonText: 'Save',
  initialValues: {},
};

export default CollectionTypePropertyForm;
