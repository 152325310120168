import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation } from '@apollo/client';

import {
  EDIT_REPORT_CHANGE_REQUEST_STATUS,
  DELETE_REPORT_CHANGE_REQUEST,
} from '../../graphql/mutation/reports';
import { REPORT } from '../../graphql/query/reports';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    transition: 'background-color 200ms ease',
    backgroundColor: ({ resolved }) => {
      if (!resolved) return 'transparent';
      return theme.palette.success.background;
    },
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  text: {
    padding: 0,
    margin: 0,
  },
  buttons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  button: {},
}));

const ReportChangeRequest = (props) => {
  const { request, reportId } = props;
  const { text, resolved, id } = request || {};
  const classes = useStyles({ resolved });
  const buttonText = resolved ? 'Reopen' : 'Resolve';

  const [editReportChangeRequestStatus] = useMutation(EDIT_REPORT_CHANGE_REQUEST_STATUS, {
    refetchQueries: [{ query: REPORT, variables: { id: reportId } }],
  });

  const [deleteReportChangeRequest] = useMutation(DELETE_REPORT_CHANGE_REQUEST, {
    refetchQueries: [{ query: REPORT, variables: { id: reportId } }],
  });

  const toggleReportStatus = () => {
    editReportChangeRequestStatus({ variables: { id, resolved: !resolved } });
  };

  const deleteRequest = () => {
    deleteReportChangeRequest({ variables: { id } });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>{text}</Typography>
      <div className={classes.buttons}>
        <Button
          onClick={toggleReportStatus}
          variant="contained"
          size="small"
          color="primary"
          className={classes.button}
        >
          {buttonText}
        </Button>
        <IconButton size="small" onClick={deleteRequest}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

ReportChangeRequest.propTypes = {
  request: PropTypes.object.isRequired,
  reportId: PropTypes.string.isRequired,
};

ReportChangeRequest.defaultProps = {};

export default ReportChangeRequest;
