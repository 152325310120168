import { gql } from '@apollo/client';
import { MEDIA } from '../fragments/media';

export const SEARCH_COLLECTION_TYPE_PROPERTY_LIST_ITEMS = gql`
  query SearchRecentCollectionTypePropertyListItems(
    $next: String
    $previous: String
    $limit: Int
    $propertySlug: String
    $propertyId: ID
    $filter: CollectionTypePropertyItemFilterInput
  ) {
    searchRecentCollectionTypePropertyListItems(
      next: $next
      previous: $previous
      limit: $limit
      propertySlug: $propertySlug
      propertyId: $propertyId
      filter: $filter
    ) {
      results {
        name
        id
        spellings {
          id
          spelling
        }
        media {
          ...media
        }
      }
      type
    }
  }
  ${MEDIA}
`;

export const GET_COLLECTION_TYPE_PROPERTY_LIST_ITEM = gql`
  query CollectionTypePropertyListItem($id: ID!) {
    collectionTypePropertyListItem(id: $id) {
      id
      name
      slug
      published
      description
      spellings {
        id
        spelling
      }
      media {
        ...media
        key
        original_name
      }
    }
  }
  ${MEDIA}
`;

export const GET_AD_COLLECTION_TYPE_PROPERTY_LIST_ITEMS = gql`
  query CollectionTypePropertyListItems($propertyId: ID) {
    collectionTypePropertyListItems(propertyId: $propertyId) {
      results {
        id
        name
        slug
        spellings {
          id
          spelling
        }
      }
    }
  }
`;

export const GET_COLLECTION_TYPE_PROPERTY_LIST_ITEMS = gql`
  query CollectionTypePropertyListItems(
    $next: String
    $previous: String
    $limit: Int
    $propertySlug: String
    $filter: CollectionTypePropertyItemFilterInput
  ) {
    collectionTypePropertyListItems(
      next: $next
      previous: $previous
      limit: $limit
      propertySlug: $propertySlug
      filter: $filter
    ) {
      results {
        id
        name
        spellings {
          id
          spelling
        }
        slug
        published
        description
        media {
          ...media
        }
      }
      previous
      hasPrevious
      next
      hasNext
      resultsNumber
    }
  }
  ${MEDIA}
`;

export const COLLECTION_TYPE_PROPERTY_LIST_ITEMS = gql`
  query CollectionTypePropertyListItems(
    $next: String
    $previous: String
    $limit: Int
    $propertySlug: String
    $propertyId: ID
    $filter: CollectionTypePropertyItemFilterInput
  ) {
    collectionTypePropertyListItems(
      next: $next
      previous: $previous
      limit: $limit
      propertySlug: $propertySlug
      propertyId: $propertyId
      filter: $filter
    ) {
      results {
        name
        spellings {
          id
          spelling
        }
        id
        media {
          ...media
        }
      }
      type
    }
  }
  ${MEDIA}
`;
