import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { getBaseInputStyles } from './base-input';

const useStyles = makeStyles((theme) => ({
  ...getBaseInputStyles(theme),
}));

const TextInput = (props) => {
  const {
    id,
    endAdornment,
    startAdornment,
    error,
    helperText,
    dataTest,
    name,
    className,
    InputProps,
    inputProps,
    InputLabelProps,
    required,
    shrink,
    ...textFieldProps
  } = props;
  const classes = useStyles();

  return (
    <TextField
      {...textFieldProps}
      id={id}
      className={clsx(classes.wrapper, className)}
      size="small"
      native="true"
      error={error}
      helperText={helperText}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: shrink || InputLabelProps.shrink,
        required: required || InputLabelProps.required,
        classes: {
          ...InputLabelProps.classes,
          root: clsx(classes.label, InputLabelProps.classes?.root, InputLabelProps.className),
          shrink: clsx(classes.labelShrink, InputLabelProps.classes?.shrink),
        },
      }}
      InputProps={{
        ...InputProps,
        classes: { multiline: classes.multiline, ...InputProps.classes },
        className: clsx(classes.root, InputProps.className),
        startAdornment: startAdornment || InputProps.startAdornment,
        endAdornment: endAdornment || InputProps.endAdornment,
      }}
      inputProps={{
        ...inputProps,
        className: clsx(classes.input, inputProps.className),
        'data-test': dataTest,
        name: name || id,
      }}
    />
  );
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  disabled: PropTypes.any,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  type: PropTypes.string,
  dataTest: PropTypes.string,
  name: PropTypes.string,
  multiline: PropTypes.bool,
  className: PropTypes.string,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  shrink: PropTypes.bool,
};

TextInput.defaultProps = {
  value: '',
  name: '',
  onChange: () => {},
  onFocus: () => {},
  onClick: () => {},
  onBlur: () => {},
  startAdornment: null,
  endAdornment: null,
  disabled: false,
  error: false,
  helperText: '',
  type: 'text',
  dataTest: '',
  multiline: false,
  className: '',
  InputProps: {},
  inputProps: {},
  InputLabelProps: {},
  required: false,
  shrink: true,
};

export default TextInput;
