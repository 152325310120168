import { gql } from '@apollo/client';

export const SET_MEDIA_OPTIMIZATION_REQUEST_STATUS = gql`
  mutation SetMediaOptimizationRequestStatus($id: ID!, $status: String!) {
    setMediaOptimizationRequestStatus(id: $id, status: $status) {
      id
      description
      created_at
      reporter {
        id
        name
      }
      status
    }
  }
`;

export const SET_MEDIA_OPTIMIZATION_REQUEST_ACTION = gql`
  mutation SetMediaOptimizationRequestAction($id: ID!, $action: String!) {
    setMediaOptimizationRequestAction(id: $id, action: $action) {
      id
      description
      created_at
      reporter {
        id
        name
      }
      status
    }
  }
`;

export const ADD_MEDIA_OPTIMIZATION_REQUEST_NOTE = gql`
  mutation AddMediaOptimizationRequestNote($id: ID!, $text: String!) {
    addMediaOptimizationRequestNote(id: $id, text: $text) {
      id
      description
      created_at
      reporter {
        id
        name
      }
      status
    }
  }
`;
