import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, Typography, IconButton } from '@material-ui/core';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import dayjs from 'dayjs';
import _capitalize from 'lodash/capitalize';

import { ADVERTISEMENT_DASHBOARD } from '../../graphql/query/advertisement';
import { SEARCH_ADVERTISEMENT_CLIENTS } from '../../graphql/query/advertisementClient';
import { DELETE_ADVERTISMENT } from '../../graphql/mutation/advertisement';

import DashboardTable from '../../components/dashboard-table';
import DashboardFilters from '../../components/dashboard-filters';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    display: 'grid',
    gridTemplateRows: `${theme.spacing(4.5)}px 1fr`,
    gridGap: theme.spacing(2),
    boxSizing: 'border-box',
  },
  tabs: {},
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addLink: {
    textDecoration: 'none',
  },
  table: {
    overflow: 'hidden',
  },
  unpublished: {
    color: theme.palette.error.main,
  },
  published: {
    color: theme.palette.success.main,
  },
}));

const LinkComponent = ({ children, columnId, row, className }) => {
  const { id, url, target } = row || {};
  if (columnId === 'delete') return <div>{children}</div>;
  if (columnId === 'url') {
    return (
      <a href={url} target={target} rel="noopener noreferrer">
        {url}
      </a>
    );
  }
  return (
    <Link className={className} to={`/advertisement/${id}`}>
      {children}
    </Link>
  );
};

LinkComponent.propTypes = {
  columnId: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};

const AdvertisementDashboard = () => {
  const classes = useStyles();
  const [client, setClient] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const advertisementsVariables = { client_id: client?.value, limit: rowsPerPage };
  const {
    data: adsResData,
    loading,
    refetch: refetchAds,
  } = useQuery(ADVERTISEMENT_DASHBOARD, {
    variables: advertisementsVariables,
  });
  const adsData = adsResData?.advertisements;
  const ads = adsData?.results;

  const [searchAdvertisementClients, { data: clientsData }] = useLazyQuery(
    SEARCH_ADVERTISEMENT_CLIENTS,
  );
  const clients = clientsData?.searchAdvertisementClients || [];
  const clientsOptions = clients?.map(({ id, client: label }) => ({ value: id, label }));

  const [deleteAdvertisement] = useMutation(DELETE_ADVERTISMENT, {
    refetchQueries: [{ query: ADVERTISEMENT_DASHBOARD, variables: advertisementsVariables }],
  });

  useEffect(() => {
    searchAdvertisementClients({ variables: { limit: 50, term: '' } });
  }, []);

  useEffect(() => {
    refetchAds(advertisementsVariables);
    setPage(0);
  }, [client?.value]);

  const COLUMNS = [
    {
      id: 'published',
      label: 'Published',
      minWidth: 130,
      width: 130,
      format: renderPublished,
    },
    {
      id: 'name',
      label: 'Name',
      minWidth: 100,
    },
    {
      id: 'client.client',
      label: 'Client',
      minWidth: 100,
    },
    { id: 'url', label: 'Link', minWidth: 100, format: renderLink },
    { id: 'expire', label: 'Expire', minWidth: 100, format: renderExpire },
    { id: 'delete', minWidth: 40, width: 40, format: renderDelete },
  ];

  const onPageChange = ({ direction }) => {
    const hasNewPage = adsData[`has${_capitalize(direction)}`];
    if (!hasNewPage) return;

    const next = direction === 'next' ? adsData.next : '';
    const previous = direction === 'previous' ? adsData.previous : '';
    const newPageVariables = { ...advertisementsVariables, next, previous };
    refetchAds(newPageVariables);
  };

  const getRowLink = ({ id }) => `/advertisement/${id}`;

  const onClientTextChange = (e, term) => {
    if (term) {
      searchAdvertisementClients({ variables: { term } });
    }
  };

  function renderPublished({ published }) {
    const classProp = published ? 'published' : 'unpublished';
    return (
      <Typography className={classes[classProp]}>
        {published ? 'Published' : 'Unpublished'}
      </Typography>
    );
  }

  function renderLink({ url, target }) {
    return (
      <a href={url} target={target} rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  function renderExpire({ expire }) {
    return <Typography variant="body2">{dayjs(expire).format('ll')}</Typography>;
  }

  function renderDelete({ id }) {
    return (
      <IconButton size="small" onClick={() => onAdvertisementDelete(id)}>
        <DeleteIcon />
      </IconButton>
    );
  }

  function onAdvertisementDelete(id) {
    deleteAdvertisement({ variables: { id } });
  }

  const filterFields = [
    {
      placeholder: 'Filter by client',
      id: 'client',
      value: client,
      onInputChange: _debounce(onClientTextChange, 200),
      options: clientsOptions,
      onChange: (e, value) => setClient(value || {}),
    },
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.filters}>
        <DashboardFilters fields={filterFields} />
        <Link className={classes.addLink} to="/advertisement/create">
          <Button variant="contained" color="secondary" size="small" startIcon={<AddIcon />}>
            Create Advertisement
          </Button>
        </Link>
      </div>
      <div className={classes.table}>
        <DashboardTable
          data={ads}
          loading={loading}
          columns={COLUMNS}
          getRowLink={getRowLink}
          LinkComponent={LinkComponent}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={adsData?.resultsNumber}
          onPageChange={onPageChange}
        />
      </div>
    </Container>
  );
};

export default AdvertisementDashboard;
