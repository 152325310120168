import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import { ReactKeycloakProvider } from '@react-keycloak-fork/web';
import keycloak from './utils/keycloak';

import App from './App';
import GlobalLoading from './components/global-loading';
import * as serviceWorker from './serviceWorker';

import apolloClient from './utils/apollo';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<GlobalLoading />}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <ReactKeycloakProvider authClient={keycloak}>
            <App />
          </ReactKeycloakProvider>
        </BrowserRouter>
      </ApolloProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
