import { useLocation } from 'react-router-dom';

const useHash = () => {
  const location = useLocation();
  const fullHash = location?.hash;
  const hash = fullHash?.replace('#', '')?.split('&')[0];
  if (hash.includes('state=')) return '';
  return hash;
};

export default useHash;
