import { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';

import CreateCollectionTypeModal from '../create-collection-type-modal';

const CreateCollectionTypeButton = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const onOpenCreateModal = () => setOpenCreateModal(true);

  const closeCreateModal = () => setOpenCreateModal(false);

  return (
    <>
      <Button
        onClick={onOpenCreateModal}
        color="secondary"
        variant="contained"
        startIcon={<AddIcon />}
        size="small"
      >
        <span>Create type</span>
      </Button>
      <CreateCollectionTypeModal open={openCreateModal} onClose={closeCreateModal} />
    </>
  );
};

export default CreateCollectionTypeButton;
