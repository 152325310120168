import { gql } from '@apollo/client';

export const CREATE_COLLECTION_TYPE_PROPERTY = gql`
  mutation CreateCollectionTypeProperty($collectionTypeProperty: CollectionTypePropertyInput!) {
    createCollectionTypeProperty(collectionTypeProperty: $collectionTypeProperty) {
      id
      name
    }
  }
`;

export const EDIT_COLLECTION_TYPE_PROPERTY = gql`
  mutation EditCollectionTypeProperty(
    $id: ID!
    $collectionTypeProperty: CollectionTypePropertyInput!
  ) {
    editCollectionTypeProperty(id: $id, collectionTypeProperty: $collectionTypeProperty) {
      id
      name
    }
  }
`;
