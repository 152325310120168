import PropTypes from 'prop-types';

import { getFormikInputProps } from '../helpers';

import TextInput from '../text-input';

const PropertyFieldNumberInput = (props) => {
  const { property, formik, classes, showLabel, ...otherProps } = props;
  const { id, name, description, placeholder, formName } = property;

  return (
    <TextInput
      id={id}
      label={showLabel && name}
      type="number"
      className={classes.number}
      placeholder={placeholder || description}
      {...otherProps}
      {...getFormikInputProps({ formik, name: formName || id })}
    />
  );
};

PropertyFieldNumberInput.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    formName: PropTypes.string,
  }).isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    number: PropTypes.string,
  }),
  showLabel: PropTypes.bool,
};

PropertyFieldNumberInput.defaultProps = {
  classes: {},
  showLabel: true,
};

export default PropertyFieldNumberInput;
