import { gql } from '@apollo/client';

export const CREATE_ADVERTISMENT = gql`
  mutation CreateAdvertisement($advertisement: AdvertisementInput!) {
    createAdvertisement(advertisement: $advertisement) {
      id
      property_ids
      name
      url
      published
    }
  }
`;

export const EDIT_ADVERTISMENT = gql`
  mutation EditAdvertisement($id: ID!, $advertisement: AdvertisementInput!) {
    editAdvertisement(id: $id, advertisement: $advertisement) {
      id
      property_ids
      name
      url
      published
    }
  }
`;

export const DELETE_ADVERTISMENT = gql`
  mutation DeleteAdvertisement($id: ID!) {
    deleteAdvertisement(id: $id) {
      id
      property_ids
      name
      url
      published
    }
  }
`;
