import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ME = gql`
  {
    me {
      id
      email
      name
      first_name
      userpic {
        id
        src
        placeholder
        size
      }
    }
  }
`;
