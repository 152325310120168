import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, DialogTitle } from '@material-ui/core';

import { CREATE_COLLECTION_TYPE_PROPERTY } from '../../graphql/mutation/collectionTypeProperties';
import { GET_COLLECTION_TYPE_PROPERTIES_TYPES } from '../../graphql/query/collectionTypeProperties';

import CollectionTypePropertyForm from '../collection-type-property-form';

const CreateCollectionTypePropertyModal = (props) => {
  const { onClose, updateProperties, ...modalProps } = props;

  const [createCollectionTypeProperty, { loading, error }] = useMutation(
    CREATE_COLLECTION_TYPE_PROPERTY,
    { onCompleted },
  );

  const { data } = useQuery(GET_COLLECTION_TYPE_PROPERTIES_TYPES);
  const propertyTypes = data?.collectionTypePropertyTypes || [];
  const propertyTypesOptions = propertyTypes.map((typeName) => ({
    label: typeName,
    value: typeName,
  }));
  const initialValues = {
    property_type: '',
    multiple: 1,
  };

  function onCompleted() {
    updateProperties();
    onClose();
  }

  function onSubmit(variables) {
    createCollectionTypeProperty({ variables });
  }

  return (
    <Dialog onClose={onClose} {...modalProps}>
      <DialogTitle>Create collection type property</DialogTitle>
      <CollectionTypePropertyForm
        error={error}
        propertyTypesOptions={propertyTypesOptions}
        onSubmit={onSubmit}
        onClose={onClose}
        loading={loading}
        initialValues={initialValues}
        submitButtonText="Create"
      />
    </Dialog>
  );
};

CreateCollectionTypePropertyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  updateProperties: PropTypes.func,
};

CreateCollectionTypePropertyModal.defaultProps = {
  updateProperties: () => {},
};

export default CreateCollectionTypePropertyModal;
