import { useEffect } from 'react';
import PropTypes from 'prop-types';
import _times from 'lodash/times';
import { FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { getBaseInputStyles } from '../base-input';

import ColorPickerInput from './color-picker-input';

const useStyles = makeStyles((theme) => ({
  ...getBaseInputStyles(theme),
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    padding: 0,
    alignItems: 'center',
    '& input': {
      flex: 1,
      padding: 0,
      minWidth: 100,
    },
  },
  inputRoot: {
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));

const ColorPickerMultipleInput = (props) => {
  const {
    id,
    label,
    value,
    focused,
    onClickOpen,
    setCurrentItem,
    colorsLimit,
    onDeleteColor,
    classes: outerClasses,
  } = props;
  const classes = useStyles();

  useEffect(() => setCurrentItem(null), [value]);

  const onItemClick = (e, i) => {
    setCurrentItem(i);
    onClickOpen(e);
  };

  const renderLabel = (
    <InputLabel shrink focused={focused} className={clsx(classes.label, outerClasses.label)}>
      {label}
    </InputLabel>
  );

  const renderPicker = (i) => {
    const color = value && Array.isArray(value) && value[i];
    return (
      <ColorPickerInput
        key={i}
        index={i}
        onClick={onItemClick}
        color={color}
        onItemDelete={onDeleteColor}
        classes={outerClasses}
      />
    );
  };

  const renderPickers = () => {
    const array = _times(colorsLimit, Number);
    return array.map(renderPicker);
  };

  return (
    <FormControl id={id} className={clsx(classes.wrapper, outerClasses.formControl)}>
      {renderLabel}
      <div className={clsx(classes.inputRoot, outerClasses.inputRoot)}>{renderPickers()}</div>
    </FormControl>
  );
};

ColorPickerMultipleInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  focused: PropTypes.bool.isRequired,
  onClickOpen: PropTypes.func,
  setCurrentItem: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  classes: PropTypes.object,
  colorsLimit: PropTypes.number.isRequired,
  onDeleteColor: PropTypes.func,
};

ColorPickerMultipleInput.defaultProps = {
  label: '',
  onClickOpen: () => {},
  setCurrentItem: () => {},
  onDeleteColor: () => {},
  classes: {},
};

export default ColorPickerMultipleInput;
