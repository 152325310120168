import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import MergeTypeIcon from '@material-ui/icons/MergeType';

import ReplaceCollectionTypePropertyItemModal from '../replace-collection-type-property-item-modal';

const ReplaceCollectionTypePropertyItemButton = ({ updateItems, variant, listItem }) => {
  const initReplacingItem = listItem && { label: listItem?.name, value: listItem?.id };
  const [openModal, setOpenModal] = useState(false);

  const onOpenModal = () => setOpenModal(true);

  const closeModal = () => setOpenModal(false);

  const renderFullSizeButton = (
    <Button
      onClick={onOpenModal}
      color="secondary"
      variant="contained"
      startIcon={<MergeTypeIcon />}
      size="small"
    >
      <span>Replace item</span>
    </Button>
  );

  const renderIconButton = (
    <IconButton size="small" onClick={onOpenModal}>
      <MergeTypeIcon />
    </IconButton>
  );

  const buttonTypes = {
    icon: renderIconButton,
    default: renderFullSizeButton,
  };

  const renderButton = buttonTypes[variant] || buttonTypes.default;

  return (
    <>
      {renderButton}
      <ReplaceCollectionTypePropertyItemModal
        open={openModal}
        onClose={closeModal}
        updateItems={updateItems}
        initReplacingItem={initReplacingItem}
      />
    </>
  );
};

ReplaceCollectionTypePropertyItemButton.propTypes = {
  updateItems: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'icon']),
  listItem: PropTypes.object,
};

ReplaceCollectionTypePropertyItemButton.defaultProps = {
  updateItems: () => {},
  variant: 'default',
  listItem: null,
};

export default ReplaceCollectionTypePropertyItemButton;
