import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox as MaterialCheckbox } from '@material-ui/core';

const Checkbox = (props) => {
  const { checked, name, onChange, color, label, className } = props;

  return (
    <FormControlLabel
      className={className}
      control={<MaterialCheckbox checked={checked} onChange={onChange} name={name} color={color} />}
      label={label}
    />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  color: 'primary',
  className: '',
};

export default Checkbox;
