/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const HOME_SPOTLIGHT = gql`
  query Spotlight {
    spotlight {
      id
      headline
      text
      type
      mediaKey
      media {
        id
        size
        src
        key
        original_name
      }
    }
  }
`;

export const HOME_SPOTLIGHT_TYPES = gql`
  query SpotlightTypes {
    __type(name: "SpotlightTypes") {
      enumValues {
        name
      }
    }
  }
`;
