import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    height: '100%',
    maxWidth: '100%',
    width: '100%',
  },
  image: ({ imageFit }) => ({
    objectFit: imageFit,
    maxHeight: '100%',
    maxWidth: '100%',
    width: imageFit === 'cover' ? '100%' : 'auto',
    height: imageFit === 'cover' ? '100%' : 'auto',
    borderRadius: theme.spacing(1),
    '&.loading': {
      opacity: '0.5',
    },
  }),
  progress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 'calc(50% - 24px)',
    left: 'calc(50% - 24px)',
  },
  edit: {
    backgroundColor: 'rgba(255,255,255,0.6)',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.7)',
    },
  },
}));

const DropzoneSingle = (props) => {
  const { file, isPreview, imageFit, hideEditButton, classes: outerClasses } = props;
  const classes = useStyles({ imageFit });
  const { src, previewUrl } = file;

  const renderSpinner = () => {
    if (!isPreview) return null;
    return <CircularProgress size={48} className={classes.progress} />;
  };

  const renderEditButton = () => {
    if (hideEditButton) return null;
    return (
      <IconButton className={classes.edit}>
        <EditOutlinedIcon />
      </IconButton>
    );
  };

  return (
    <div className={clsx(classes.root, outerClasses.root)}>
      <picture className={outerClasses.picture}>
        {/* <source srcSet={previewUrl} /> */}
        {/* <source srcSet={src} /> */}
        <img
          src={previewUrl || src}
          alt="alt"
          className={clsx(classes.image, outerClasses.image, isPreview && 'loading')}
        />
      </picture>
      {renderEditButton()}
      {renderSpinner()}
    </div>
  );
};

DropzoneSingle.propTypes = {
  file: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
  imageFit: PropTypes.string.isRequired,
  hideEditButton: PropTypes.bool,
  classes: PropTypes.object,
};

DropzoneSingle.defaultProps = {
  isPreview: false,
  hideEditButton: false,
  classes: {},
};

export default DropzoneSingle;
