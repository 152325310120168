/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const MEDIA_OPTIMIZATION_REQUESTS = gql`
  query MediaOptimizationRequests(
    $next: String
    $previous: String
    $limit: Int
    $status: String
    $userId: ID
  ) {
    mediaOptimizationRequests(
      next: $next
      previous: $previous
      limit: $limit
      status: $status
      userId: $userId
    ) {
      results {
        id
        description
        created_at
        status
        reporter {
          id
          name
        }
      }
      previous
      hasPrevious
      next
      hasNext
      resultsNumber
    }
  }
`;

export const MEDIA_OPTIMIZATION_REQUEST = gql`
  query MediaOptimizationRequest($id: ID!) {
    mediaOptimizationRequest(id: $id) {
      id
      description
      created_at
      media_id
      item {
        id
        slug
        name
      }
      reporter {
        id
        slug
        email
        name
      }
      history {
        date
        action
        status
      }
      notes {
        note
        date
      }
      operator {
        id
        name
      }
      src
      status
    }
  }
`;

export const MEDIA_OPTIMIZATION_REQUEST_STATUSES = gql`
  {
    mediaOptimizationRequestStatuses {
      value
      label
    }
  }
`;
