import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import _isEmpty from 'lodash/isEmpty';
import _mapKeys from 'lodash/mapKeys';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import { EXPLORE_LINES } from '../../graphql/query/explore';
import { EDIT_EXPLORE_LINE } from '../../graphql/mutation/explore';

import Loader from '../../components/common/loader';
import ExploreForm from '../../components/explore-form';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
  },
}));

const PagesExplore = () => {
  const classes = useStyles();
  const { data, loading } = useQuery(EXPLORE_LINES);
  const exploreLines = data?.exploreLines;
  const showFrom = !_isEmpty(exploreLines);
  const initialValues = {};

  const [editExploreLine, { error }] = useMutation(EDIT_EXPLORE_LINE, {
    refetchQueries: [{ query: EXPLORE_LINES }],
  });
  const errorMessage = _get(error, 'errors[0].message');
  const hasError = !!errorMessage;

  const formatValues = (value) => {
    if (_isArray(value)) {
      return _map(value, (v) => v?.value || v).filter(Boolean);
    }
    return [value];
  };

  const updateLine = (fieldValue, property_id) => {
    const propertyValue = fieldValue?.propertyValue;
    const name = fieldValue?.nameValue;
    const value = formatValues(propertyValue);
    const variables = { property_id, value, name };
    editExploreLine({ variables });
  };

  const onSubmit = (values) => {
    _mapKeys(values, updateLine);
  };

  const renderError = <Typography className={classes.error}>{errorMessage}</Typography>;

  const renderForm = showFrom && (
    <ExploreForm initialValues={initialValues} onSubmit={onSubmit} exploreLines={exploreLines} />
  );

  return (
    <>
      <Typography variant="h5">Lines</Typography>
      {hasError && renderError}
      {renderForm}
      <Loader show={!data || loading} absolute transparentBackground />
    </>
  );
};

export default PagesExplore;
