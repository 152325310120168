import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  wrapper: ({ minHeight, minWidth, transparent }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparent ? 'transparent' : theme.palette.background.modalConteinerWhite,
    width: '100%',
    height: '100%',
    minHeight,
    minWidth,
  }),
  absolute: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  transparentBackground: {
    backgroundColor: 'transparent',
  },
  hidden: {
    maxHeight: '0 !important',
    maxWidth: '0 !important',
    width: '0 !important',
    height: '0 !important',
    minHeight: '0 !important',
    minWidth: '0 !important',
    visibility: 'hidden',
    overflow: 'hidden',
    padding: '0 !important',
    margin: '0 !important',
  },
}));

const Loader = (props) => {
  const {
    size,
    className,
    loaderClassName,
    show,
    absolute,
    transparentBackground,
    minHeight,
    minWidth,
    color,
  } = props;
  const classes = useStyles({ minHeight, minWidth, transparent: transparentBackground });

  const wrapperClass = clsx(
    classes.wrapper,
    absolute && classes.absolute,
    !show && classes.hidden,
    className,
  );

  return (
    <div className={wrapperClass}>
      <CircularProgress size={size} className={loaderClassName} disableShrink color={color} />
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  loaderClassName: PropTypes.string,
  show: PropTypes.bool,
  absolute: PropTypes.bool,
  transparentBackground: PropTypes.bool,
  size: PropTypes.number,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
};

Loader.defaultProps = {
  show: false,
  className: '',
  loaderClassName: '',
  absolute: false,
  transparentBackground: false,
  size: 100,
  minHeight: 0,
  minWidth: 0,
  color: 'primary',
};

export default Loader;
