import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

export const getBaseInputStyles = (theme) => ({
  formControl: {
    maxWidth: '100%',
  },
  input: {
    fontSize: 16,
    transition: theme.transitions.create(['border-color']),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    lineHeight: '24px',
    minHeight: theme.spacing(4),
    boxSizing: 'border-box',
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.placeholder.input,
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  multiline: {
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(1)} !important`,
    paddingLeft: 0,
    paddingRight: 0,
  },
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.background.control,
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&.MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(1.2),
    },
    '& fieldset': {
      border: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
  select: {
    border: 'none',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    fontSize: 16,
    lineHeight: '24px',
    minHeight: theme.spacing(4),
    boxSizing: 'border-box',
  },
  selectInput: {
    borderRight: `1px solid ${theme.palette.border.white}`,
  },
  selectRoot: {
    backgroundColor: theme.palette.background.control,
    borderRadius: theme.shape.borderRadius,
    minHeight: theme.spacing(4),
    paddingRight: theme.spacing(3),
    '& $select': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    },
  },
  autocompleteRoot: {
    paddingRight: `${theme.spacing(3)}px`,
    '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    },
  },
  selectEndAdornment: {
    borderLeft: `1px solid ${theme.palette.border.white}`,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    top: 0,
    width: theme.spacing(3),
    backgroundColor: theme.palette.background.control,
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 13,
    letterSpacing: '0.24em',
    transform: 'scale(1)',
  },
  labelShrink: {
    transform: 'translate(0, -2px)',
    transformOrigin: 'top left',
  },
  placeholder: {
    color: theme.palette.placeholder.input,
    fontSize: 16,
    lineHeight: '24px',
  },
});

const BaseInput = withStyles((theme) => ({
  ...getBaseInputStyles(theme),
}))(InputBase);

export default BaseInput;
