import { Typography } from '@material-ui/core';

import Spotlight from '../../components/spotlight';

const PagesHome = () => {
  return (
    <>
      <Typography variant="h5">Spotlight</Typography>
      <Spotlight />
    </>
  );
};

export default PagesHome;
