import DayjsUtils from '@date-io/dayjs';
import { DatePicker as Picker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';
import TextInput from './text-input';

const DatePicker = (props) => (
  <MuiPickersUtilsProvider utils={DayjsUtils}>
    <Picker
      {...props}
      autoOk
      TextFieldComponent={TextInput}
      format="L"
      initialFocusedDate={dayjs().toDate()}
    />
  </MuiPickersUtilsProvider>
);

export default DatePicker;
