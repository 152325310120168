import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';
import dayjs from 'dayjs';

import { DASHBOARD_TABS } from '../../constants';
import { constantToString } from '../../helpers/constant';
import useHash from '../../hooks/useHash';

import { REPORT, REPORTS, REPORT_ENTITIES } from '../../graphql/query/reports';
import { SET_REPORT_ACTION } from '../../graphql/mutation/reports';
import { REPORTER_OPTIONS, RESPONDER_OPTIONS } from '../../graphql/query/user';

import DashboardTabs from '../../components/dashboard-tabs';
import DashboardTable from '../../components/dashboard-table';
import DashboardFilters from '../../components/dashboard-filters';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    display: 'grid',
    gridTemplateRows: `${theme.spacing(6)}px ${theme.spacing(4.5)}px 1fr`,
    gridGap: theme.spacing(2),
    boxSizing: 'border-box',
  },
  tabs: {},
  filters: {},
  table: {
    overflow: 'hidden',
  },
}));

const ReportsDashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const hash = useHash();
  const [currentTab, setCurrentTab] = useState(hash || 'NEW');
  const [reporter, setReporter] = useState({});
  const [respondent, setRespondent] = useState({});
  const [type, setType] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const variables = {
    status: currentTab,
    reporter: reporter?.value,
    respondent: respondent?.value,
    type: type?.value,
    limit: rowsPerPage,
  };
  const refetchQueries = [{ query: REPORTS, variables }];
  const { data: entitiesData } = useQuery(REPORT_ENTITIES);
  const { data: reportersData, refetch: refetchReporters } = useQuery(REPORTER_OPTIONS, {
    variables: { query: '' },
  });
  const { data: respondersData, refetch: refetchResponders } = useQuery(RESPONDER_OPTIONS, {
    variables: { query: '' },
  });

  const { data, loading, refetch: refetchReports } = useQuery(REPORTS, { variables });
  const reportsData = data?.reports;
  const reports = _get(reportsData, 'results', []).filter(Boolean);
  const reporters = reportersData?.searchUsers?.results || [];
  const responders = respondersData?.searchUsers?.results || [];
  const entities = entitiesData?.reportEntities || [];
  const [setReportAction] = useMutation(SET_REPORT_ACTION);

  useEffect(() => {
    refetchReports(variables);
  }, [
    currentTab,
    variables.status,
    variables.reporter,
    variables.respondent,
    variables.type,
    variables.limit,
  ]);

  const COLUMNS = [
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      format: ({ status }) => constantToString(status).toUpperCase(),
    },
    { id: 'entity.type', label: 'Entity type', minWidth: 100 },
    { id: 'reporter.name', label: 'Reporter', minWidth: 100 },
    { id: 'respondent.name', label: 'Respondent', minWidth: 100 },
    {
      id: 'created_at',
      label: 'Date',
      minWidth: 50,
      format: ({ created_at }) => dayjs(created_at).format('DD.MM.YYYY'),
    },
    { id: 'description', label: 'Description', minWidth: 170 },
    {
      id: 'ignore',
      label: '',
      minWidth: 50,
      format: (report) => getTableButton(report),
    },
  ];

  const onPageChange = ({ direction }) => {
    const hasNewPage = reportsData[`has${_capitalize(direction)}`];

    if (!hasNewPage) return;

    const next = direction === 'next' ? reportsData.next : '';
    const previous = direction === 'previous' ? reportsData.previous : '';
    const newPageVariables = { ...variables, next, previous };
    refetchReports(newPageVariables);
  };

  const setStatus = (id, action) => {
    setReportAction({
      variables: { id, action },
      refetchQueries: [...refetchQueries, { query: REPORT, variables: { id } }],
    });
  };

  const getRowLink = ({ id }) => `/report/${id}`;

  function getTableButton(report) {
    return (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          setStatus(report?.id, 'IGNORE');
        }}
      >
        Ignore
      </Button>
    );
  }

  const onTabSelect = (e, newValue) => {
    setCurrentTab(newValue);
    history.push(`/#${newValue}`);
  };

  const onReporterChange = (e, value) => {
    refetchReporters({ query: value });
  };

  const onResponderChange = (e, value) => {
    refetchResponders({ query: value });
  };

  const getOption = (item, valueProperty = 'id', labelProperty = 'name') => ({
    value: _get(item, valueProperty),
    label: _get(item, labelProperty),
  });

  const reporterOptions = reporters.map((user) => getOption(user));
  const responderOptions = responders.map((user) => getOption(user));
  const entitiesOptions = entities.map((entry) => getOption(entry, 'slug', 'name'));

  const filterFields = [
    {
      placeholder: 'Filter by reporter',
      id: 'reporter',
      value: reporter,
      onInputChange: _debounce(onReporterChange, 200),
      options: reporterOptions,
      onChange: (e, value) => setReporter(value || {}),
    },
    {
      id: 'respondent',
      placeholder: 'Filter by respondent',
      value: respondent,
      onInputChange: _debounce(onResponderChange, 200),
      options: responderOptions,
      onChange: (e, value) => setRespondent(value || {}),
    },
    {
      value: type,
      onChange: (e, value) => setType(value || {}),
      id: 'type',
      placeholder: 'Filter by type',
      options: entitiesOptions,
    },
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.tabs}>
        <DashboardTabs tabs={DASHBOARD_TABS} onSelect={onTabSelect} tab={currentTab} />
      </div>
      <div className={classes.filters}>
        <DashboardFilters fields={filterFields} />
      </div>
      <div className={classes.table}>
        <DashboardTable
          data={reports}
          loading={loading}
          columns={COLUMNS}
          getRowLink={getRowLink}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={reportsData?.resultsNumber}
          onPageChange={onPageChange}
        />
      </div>
    </Container>
  );
};

export default ReportsDashboard;
