import theme from './index';

export default function globalStyles() {
  return {
    '@global': {
      '#__next, html, body': {
        height: '100%',
        'text-rendering': 'geometricPrecision',
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
      '#root': {
        height: '100%',
      },
      '.App': {
        height: '100%',
      },
      '*': {
        '&::-webkit-scrollbar': {
          width: theme.spacing(1),
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.scrollbar.main,
          outline: 'transparent',
          borderRadius: 2,
        },
        '&:hover': {
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.scrollbar.hover,
          },
        },
      },
    },
  };
}
