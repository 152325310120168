import PropTypes from 'prop-types';
// import _get from 'lodash/get';

const Logo = ({ className, secondaryColor }) => {
  const fillColor1 = '#fff';
  const fillColor2 = secondaryColor;
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 115.8313">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <path
            fill={fillColor1}
            d="M45.6151,111.4876,74.6222,61.2458a6.66,6.66,0,0,0,0-6.66L45.6151,4.3437A2.8958,2.8958,0,0,1,48.1229,0H95.4372a6.66,6.66,0,0,1,5.7679,3.33l29.5923,51.2554a6.66,6.66,0,0,1,0,6.66l-29.5923,51.2553a6.66,6.66,0,0,1-5.7679,3.33H48.1229A2.8958,2.8958,0,0,1,45.6151,111.4876Z"
          />
          <path
            fill={fillColor2}
            d="M34.9151,111.4876,63.9222,61.2458a6.66,6.66,0,0,0,0-6.66L34.9151,4.3437a2.8958,2.8958,0,0,0-5.0157,0L.8923,54.5855a6.6605,6.6605,0,0,0,0,6.66l29.0071,50.2418A2.8958,2.8958,0,0,0,34.9151,111.4876Z"
          />
          <path
            fill={fillColor1}
            d="M267.9747,69.3344H228.707v43.0219a3.475,3.475,0,0,1-3.4749,3.475H199.9792a3.4749,3.4749,0,0,1-3.4749-3.475V3.475A3.4749,3.4749,0,0,1,199.9792,0h25.2529a3.475,3.475,0,0,1,3.4749,3.475V43.5394h39.2677V3.475A3.4749,3.4749,0,0,1,271.45,0h25.2529a3.475,3.475,0,0,1,3.4749,3.475V112.3563a3.475,3.475,0,0,1-3.4749,3.475H271.45a3.4749,3.4749,0,0,1-3.4749-3.475Z"
          />
          <path
            fill={fillColor1}
            d="M314.7784,0H342.547a6.66,6.66,0,0,1,6.06,3.8976L367.2036,44.69,385.8,3.8976A6.66,6.66,0,0,1,391.86,0h27.2757A2.8958,2.8958,0,0,1,421.71,4.2228L384.3641,76.6552a11.0047,11.0047,0,0,0-1.2234,5.0427v30.6584a3.475,3.475,0,0,1-3.475,3.475H354.2486a3.475,3.475,0,0,1-3.4749-3.475V81.6979a11.0035,11.0035,0,0,0-1.2235-5.0427L312.2046,4.2228A2.8958,2.8958,0,0,1,314.7784,0Z"
          />
          <path
            fill={fillColor1}
            d="M437.2111,0h71.5855a3.475,3.475,0,0,1,3.4749,3.475V22.1558a3.475,3.475,0,0,1-3.4749,3.475H465.9389V46.0039h30.6994a3.475,3.475,0,0,1,3.4749,3.475V67.01a3.4749,3.4749,0,0,1-3.4749,3.4749H465.9389v41.8718a3.475,3.475,0,0,1-3.475,3.475H437.2111a3.475,3.475,0,0,1-3.475-3.475V3.475A3.475,3.475,0,0,1,437.2111,0Z"
          />
          <path
            fill={fillColor1}
            d="M596.5251,25.795H558.1035V44.69H591.596a3.475,3.475,0,0,1,3.4749,3.475V65.6952A3.4749,3.4749,0,0,1,591.596,69.17H558.1035V90.0363h38.4216A3.4749,3.4749,0,0,1,600,93.5112v18.8451a3.475,3.475,0,0,1-3.4749,3.475H529.3757a3.475,3.475,0,0,1-3.4749-3.475V3.475A3.475,3.475,0,0,1,529.3757,0h67.1494A3.475,3.475,0,0,1,600,3.475V22.32A3.4749,3.4749,0,0,1,596.5251,25.795Z"
          />
        </g>
      </g>
    </svg>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  secondaryColor: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
  secondaryColor: '#09C3B8',
};

export default Logo;
