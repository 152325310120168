import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, DialogTitle } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { EDIT_COLLECTION_TYPE } from '../../graphql/mutation/collectionTypes';
import { GET_COLLECTION_TYPE } from '../../graphql/query/collectionTypes';
import { GET_COLLECTION_TYPE_EDIT_PROPERTIES } from '../../graphql/query/collectionTypeProperties';

import CollectionTypeForm from '../collection-type-form';
import Loader from '../common/loader';

const getPropertyOptionLabel = (property = {}) => {
  const { name, description } = property;
  if (name === description || !description) return name;
  return `${name} (${description})`;
};

const EditCollectionTypeModal = (props) => {
  const { onClose, editTypeId, updateTypes, collectionType, loading, ...modalProps } = props;
  const theme = useTheme();

  const [createCollectionType, { loading: createLoading, error }] = useMutation(
    EDIT_COLLECTION_TYPE,
    {
      onCompleted,
      refetchQueries: [{ query: GET_COLLECTION_TYPE, variables: { id: editTypeId } }],
    },
  );

  const collectionTypeInitProperties = collectionType?.properties || [];

  const { data: collectionTypePropertiesData } = useQuery(GET_COLLECTION_TYPE_EDIT_PROPERTIES);
  const properties = collectionTypePropertiesData?.collectionTypeProperties?.results || [];
  const propertyOptions = properties?.map((property) => ({
    value: property?.id,
    label: getPropertyOptionLabel(property),
  }));
  const initialValues = {
    name: collectionType?.name,
    properties: propertyOptions?.filter(({ value }) =>
      collectionTypeInitProperties.includes(value),
    ),
  };

  function onCompleted() {
    updateTypes();
    onClose();
  }

  function onSubmit(variables) {
    createCollectionType({ variables: { ...variables, id: editTypeId } });
  }

  const renderForm = (
    <CollectionTypeForm
      error={error}
      propertyOptions={propertyOptions}
      onSubmit={onSubmit}
      onClose={onClose}
      loading={createLoading}
      initialValues={initialValues}
    />
  );

  return (
    <Dialog onClose={onClose} {...modalProps}>
      <DialogTitle>Create collection type</DialogTitle>
      {collectionType && renderForm}
      <Loader show={loading} minHeight={260} minWidth={theme.spacing(66)} />
    </Dialog>
  );
};

EditCollectionTypeModal.propTypes = {
  editTypeId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  updateTypes: PropTypes.func,
  collectionType: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  loading: PropTypes.bool,
};

EditCollectionTypeModal.defaultProps = {
  editTypeId: '',
  updateTypes: () => {},
  loading: false,
  collectionType: null,
};

export default EditCollectionTypeModal;
