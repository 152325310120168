import { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, IconButton } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import DeleteIcon from '@material-ui/icons/Delete';
import _capitalize from 'lodash/capitalize';

import { GET_COLLECTION_TYPE_PROPERTY_BY_SLUG } from '../../graphql/query/collectionTypeProperties';
import { GET_COLLECTION_TYPE_PROPERTY_LIST_ITEMS } from '../../graphql/query/collectionTypePropertyListItems';
import { DELETE_COLLECTION_TYPE_PROPERTY_ITEM } from '../../graphql/mutation/collectionTypePropertyListItems';

import DashboardTable from '../../components/dashboard-table';
import DashboardFilters from '../../components/dashboard-filters';
import CreateCollectionTypePropertyItemButton from '../../components/create-collection-type-property-item-button';
import ReplaceCollectionTypePropertyItemButton from '../../components/replace-collection-type-property-item-button';
import EditCollectionTypePropertyItemButton from '../../components/edit-collection-type-property-item-button';
import PropertyDashboardThumbnail from './property-dashboard-thumbnail';
import PropertyDashboardLink from './property-dashboard-link';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    display: 'grid',
    gridTemplateRows: `${theme.spacing(6)}px ${theme.spacing(4.5)}px 1fr`,
    gridGap: theme.spacing(2),
    boxSizing: 'border-box',
  },
  tabs: {},
  title: {
    lineHeight: `${theme.spacing(6)}px`,
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  addLink: {
    textDecoration: 'none',
  },
  table: {
    overflow: 'hidden',
  },
  unpublished: {
    color: theme.palette.error.main,
  },
  published: {
    color: theme.palette.success.main,
  },
}));

const PropertyDashboard = () => {
  const classes = useStyles();
  const { slug } = useParams();
  const [term, setTerm] = useState('');
  const [unpublished, setUnpublished] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const { data } = useQuery(GET_COLLECTION_TYPE_PROPERTY_BY_SLUG, { variables: { slug } });
  const propertyName = data?.collectionTypePropertyBySlug?.name;
  const propertyDescription = data?.collectionTypePropertyBySlug?.description;
  const propertyTitle = `${propertyName} (${propertyDescription})`;

  const variables = {
    propertySlug: slug,
    limit: rowsPerPage,
  };

  const {
    data: itemsResponseData,
    loading,
    refetch: refetchItems,
  } = useQuery(GET_COLLECTION_TYPE_PROPERTY_LIST_ITEMS, { variables });

  const itemsData = itemsResponseData?.collectionTypePropertyListItems;
  const items = itemsData?.results;

  const [deleteCollectionTypePropertyItem] = useMutation(DELETE_COLLECTION_TYPE_PROPERTY_ITEM, {
    onCompleted: updateItems,
  });

  const debounceUpdateItems = useCallback(_debounce(updateItems, 500), []);

  useEffect(() => {
    const filter = { term };

    if (unpublished) filter.published = false;

    debounceUpdateItems({ ...variables, filter });
  }, [term, unpublished]);

  useEffect(() => {
    updateItems();
  }, [rowsPerPage]);

  const COLUMNS = [
    {
      id: 'published',
      label: 'Published',
      minWidth: 130,
      width: 130,
      format: renderPublished,
    },
    {
      id: 'media',
      label: 'Media',
      minWidth: 50,
      width: 50,
      format: (property) => <PropertyDashboardThumbnail property={property} />,
    },
    {
      id: 'name',
      label: 'Name',
      minWidth: 100,
    },
    {
      id: 'description',
      label: 'Description',
      minWidth: 200,
    },
    {
      id: 'replace',
      minWidth: 40,
      width: 40,
      format: (listItem) => (
        <ReplaceCollectionTypePropertyItemButton
          updateItems={updateItems}
          listItem={listItem}
          variant="icon"
        />
      ),
    },
    {
      id: 'edit',
      minWidth: 40,
      width: 40,
      format: (property) => (
        <EditCollectionTypePropertyItemButton property={property} updateItems={updateItems} />
      ),
    },
    { id: 'delete', minWidth: 40, width: 40, format: renderDelete },
  ];

  function updateItems(newVariables = {}) {
    setPage(0);
    refetchItems({ ...variables, ...newVariables });
  }

  const onPageChange = ({ direction }) => {
    const hasNewPage = itemsData[`has${_capitalize(direction)}`];

    if (!hasNewPage) return;

    const next = direction === 'next' ? itemsData.next : '';
    const previous = direction === 'previous' ? itemsData.previous : '';
    const newPageVariables = { ...variables, next, previous };
    refetchItems(newPageVariables);
  };

  const getRowLink = ({ id }) => `/advertisement/${id}`;

  const onChangeTerm = (e) => setTerm(e?.target?.value);

  const onChangeUnpublished = (e) => setUnpublished(e?.target?.checked);

  function renderPublished({ published: isPublished }) {
    const classProp = isPublished ? 'published' : 'unpublished';
    return (
      <Typography className={classes[classProp]}>
        {isPublished ? 'Published' : 'Unpublished'}
      </Typography>
    );
  }

  function renderDelete({ id }) {
    return (
      <IconButton size="small" onClick={() => onItemDelete(id)}>
        <DeleteIcon />
      </IconButton>
    );
  }

  function onItemDelete(id) {
    deleteCollectionTypePropertyItem({ variables: { id } });
  }

  const filterFields = [
    {
      type: 'text',
      placeholder: 'Filter by name',
      id: 'term',
      value: term,
      onChange: onChangeTerm,
    },
    {
      type: 'checkbox',
      id: 'unpublished',
      name: 'unpublished',
      label: 'Only unpublished',
      value: unpublished,
      onChange: onChangeUnpublished,
    },
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography className={classes.title} variant="h5">
        Property: <b>{propertyTitle}</b>
      </Typography>
      <div className={classes.filters}>
        <DashboardFilters fields={filterFields} />
        <div className={classes.buttons}>
          <ReplaceCollectionTypePropertyItemButton updateItems={updateItems} />
          <CreateCollectionTypePropertyItemButton updateItems={updateItems} />
        </div>
      </div>
      <div className={classes.table}>
        <DashboardTable
          data={items}
          loading={loading}
          columns={COLUMNS}
          getRowLink={getRowLink}
          LinkComponent={PropertyDashboardLink}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={itemsData?.resultsNumber}
          onPageChange={onPageChange}
        />
      </div>
    </Container>
  );
};

export default PropertyDashboard;
