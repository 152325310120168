/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const EDIT_HOME_SPOTLIGHT = gql`
  mutation EditSpotlight($spotlight: SpotlightInput!) {
    editSpotlight(spotlight: $spotlight) {
      id
      headline
      text
      type
      mediaKey
      media {
        id
        size
        src
        key
        original_name
      }
    }
  }
`;
