import { gql } from '@apollo/client';

export const CREATE_COLLECTION_TYPE_PROPERTY_ITEM = gql`
  mutation CreateCollectionTypePropertyItem(
    $propertyId: ID!
    $collectionTypePropertyItem: CollectionTypePropertyItemInput!
  ) {
    createCollectionTypePropertyItem(
      propertyId: $propertyId
      collectionTypePropertyItem: $collectionTypePropertyItem
    ) {
      id
      name
    }
  }
`;

export const EDIT_COLLECTION_TYPE_PROPERTY_ITEM = gql`
  mutation EditCollectionTypePropertyItem(
    $id: ID!
    $collectionTypePropertyItem: CollectionTypePropertyItemInput!
  ) {
    editCollectionTypePropertyItem(
      id: $id
      collectionTypePropertyItem: $collectionTypePropertyItem
    ) {
      id
      name
    }
  }
`;

export const DELETE_COLLECTION_TYPE_PROPERTY_ITEM = gql`
  mutation DeleteCollectionTypePropertyItem($id: ID!) {
    deleteCollectionTypePropertyItem(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_RECENT_COLLECTION_TYPE_PROPERTY_ITEM = gql`
  mutation CreateRecentCollectionTypePropertyItem($property_id: ID!, $list_item_id: ID!) {
    createRecentCollectionTypePropertyItem(property_id: $property_id, list_item_id: $list_item_id) {
      id
    }
  }
`;

export const REPLACE_COLLECTION_TYPE_PROPERTY_ITEM = gql`
  mutation ReplaceCollectionTypePropertyItem($replacing_id: ID!, $target_id: ID!) {
    replaceCollectionTypePropertyItem(replacing_id: $replacing_id, target_id: $target_id) {
      id
    }
  }
`;
