import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import { Tabs, Tab } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tabsIndicator: {
    display: 'none',
  },
  tab: {
    height: '100%',
    minWidth: 'auto',
    fontSize: 14,
    fontWeight: 600,
    position: 'relative',
    transition: 'color 100ms ease',
    color: theme.palette.text.pale,
    '&:hover': {
      color: theme.palette.text.light,
    },
  },
  selected: {
    color: theme.palette.text.primary,
  },
}));

const DashboardTabs = (props) => {
  const { tabs, onSelect, tab } = props;
  const classes = useStyles();

  const renderTab = (tabItem) => (
    <Tab
      {...tabItem}
      classes={{
        root: classes.tab,
        selected: classes.selected,
      }}
      key={tabItem?.value}
      underline="none"
      wrapped
    />
  );

  const renderTabs = _map(tabs, renderTab);

  return (
    <div className={classes.tabs}>
      <Tabs
        onChange={onSelect}
        value={tab}
        classes={{
          indicator: classes.tabsIndicator,
        }}
      >
        {renderTabs}
      </Tabs>
    </div>
  );
};

DashboardTabs.propTypes = {
  tabs: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
};

DashboardTabs.defaultProps = {
  tabs: [],
};

export default DashboardTabs;
