import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { CREATE_ADVERTISMENT } from '../../graphql/mutation/advertisement';
import { ADVERTISEMENT_DASHBOARD } from '../../graphql/query/advertisement';

import AdvertisementForm from '../../components/advertisement-form';
import BackLink from '../../components/common/back-link';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  form: {
    marginTop: theme.spacing(4),
  },
}));

const AdvertisementCreate = () => {
  const classes = useStyles();
  const history = useHistory();

  const [createAdvertisement, { loading }] = useMutation(CREATE_ADVERTISMENT, {
    onCompleted: onCreationComplete,
    // onError: (error) => onSubmitErrors(error, enqueueSnackbar),
    refetchQueries: [{ query: ADVERTISEMENT_DASHBOARD }],
  });

  function onCreationComplete() {
    history.push('/advertisement');
  }

  const onSubmit = (values) => {
    createAdvertisement({ variables: { advertisement: values } });
  };

  const initialValues = {
    published: false,
    properties: [],
    expire: null,
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <BackLink to="/advertisement">Back to list</BackLink>
      <div className={classes.form}>
        <AdvertisementForm
          initialValues={initialValues}
          submitLoading={loading}
          onSubmit={onSubmit}
          saveButtonText="Create"
        />
      </div>
    </Container>
  );
};

export default AdvertisementCreate;
