import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, Toolbar, Container, Tabs, Tab } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak-fork/web';
import { Link, useLocation } from 'react-router-dom';

import { NAV_LINKS } from '../../constants';

import Logo from '../common/logo';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
  },
  logo: {
    height: 24,
  },
  tabsFlexContainer: {
    height: '100%',
  },
  tabsIndicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    bottom: `calc(50% - ${theme.spacing(2)}px)`,
    overflow: 'hidden',
    '& > span': {
      width: `calc(100% - ${theme.spacing(8)}px)`,
      minWidth: theme.spacing(2),
      maxWidth: '100%',
      backgroundColor: theme.palette.secondary.main,
      position: 'relative',
      transition: 'width 100ms ease',
    },
  },
  tab: {
    height: '100%',
    minWidth: 'auto',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.tab.main,
    position: 'relative',
    transition: 'color 100ms ease',
    '&:hover': {
      color: theme.palette.primary.secondary,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  selected: {
    color: theme.palette.tab.mainSelected,
  },
}));

const Header = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const classes = useStyles();

  const getValue = (path) => {
    if (!path) return false;
    const existsInMenu = NAV_LINKS.find(({ href }) => href === path);
    if (!existsInMenu) return false;
    return path;
  };

  const renderTab = (tab) => {
    const { href, name } = tab;
    return (
      <Tab
        classes={{
          root: classes.tab,
          selected: classes.selected,
        }}
        component={Link}
        to={href}
        key={href}
        value={href}
        label={name}
        underline="none"
        wrapped
      />
    );
  };

  const renderTabs = () => NAV_LINKS.map(renderTab);

  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar className={classes.root}>
          <Logo className={classes.logo} />
          <Tabs
            classes={{
              flexContainer: classes.tabsFlexContainer,
              indicator: classes.tabsIndicator,
            }}
            value={getValue(location.pathname)}
            TabIndicatorProps={{ children: <span /> }}
          >
            {renderTabs()}
          </Tabs>
          <Button size="small" variant="contained" color="secondary" onClick={keycloak.logout}>
            Logout
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
