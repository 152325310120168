/* eslint-disable react/jsx-no-target-blank */
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LinkIcon from '@material-ui/icons/Link';
import env from '@beam-australia/react-env';
import clsx from 'clsx';
import { Typography, Chip, IconButton } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { useMutation, useQuery } from '@apollo/client';

import { constantToString } from '../../helpers/constant';

import { SET_REPORT_ACTION, SET_REPORT_STATUS } from '../../graphql/mutation/reports';
import { REPORT, REPORT_STATUSES } from '../../graphql/query/reports';

import Select from '../form/select';
import SaveConfirmPopup from '../save-confirm-popup';
import BackLink from '../common/back-link';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
  },
  information: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  controls: {
    marginLeft: theme.spacing(2),
  },
  control: {
    display: 'flex',
    flexDirection: 'column',
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  row: {
    marginTop: theme.spacing(2),
  },
  flexRow: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  name: {
    marginLeft: theme.spacing(2),
    fontSize: 20,
    fontWeight: 700,
  },
  entryLink: {
    marginLeft: theme.spacing(1),
  },
  disclaimer: {
    fontSize: 12,
    fontWeight: 700,
  },
  select: {
    backgroundColor: theme.palette.background.select,
    borderRadius: theme.spacing(0.5),
    minWidth: 220,
    marginTop: theme.spacing(1),
  },
}));

const ReportDescription = (props) => {
  const { report } = props;
  const classes = useStyles();
  const {
    url,
    entity,
    description,
    reason,
    reporter,
    respondent,
    reportEntity,
    id,
    status,
    operator,
  } = report || {};
  const entryUrl = createUrl(url);
  const [action, setAction] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const refetchQueries = [{ query: REPORT, variables: { id } }];
  const { data } = useQuery(REPORT_STATUSES);
  const [setReportAction] = useMutation(SET_REPORT_ACTION, { refetchQueries });
  const [setReportStatus] = useMutation(SET_REPORT_STATUS, { refetchQueries });
  const statuses = data?.reportStatuses || [];
  const actions = getActions();

  function getActions() {
    const entryActions = reportEntity?.actions || [];
    return entryActions.map((value) => ({ value, label: constantToString(value) }));
  }

  function createUrl(path) {
    return `${env('SITE_URL')}${path}`;
  }

  const onApprove = () => {
    setConfirmOpen(false);
    setReportAction({ variables: { id, action } });
  };

  const onSetStatus = (value) => {
    setReportStatus({ variables: { id, status: value } });
  };

  const onSetAction = (value) => {
    setAction(value);
    setConfirmOpen(true);
  };

  const renderOperator = (
    <div className={classes.row}>
      <Typography className={classes.disclaimer}>Operator</Typography>
      <Typography>{operator?.name}</Typography>
    </div>
  );

  return (
    <div className={classes.root}>
      <BackLink to="/">Back to list</BackLink>
      <div className={classes.content}>
        <div className={classes.information}>
          <div className={classes.flexRow}>
            <Chip
              color="primary"
              className={classes.type}
              label={entity?.type}
              variant="outlined"
            />
            <Typography className={classes.name}>{entity?.name}</Typography>
          </div>
          <div className={classes.flexRow}>
            <IconButton size="small" onClick={() => copy(entryUrl)}>
              <LinkIcon />
            </IconButton>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={entryUrl}
              className={clsx(classes.link, classes.entryLink)}
            >
              {entryUrl}
            </a>
          </div>
          <div className={classes.row}>
            <Typography className={classes.disclaimer}>Reason / Description</Typography>
            <Typography>{`${reason?.name || ''} / ${description}`}</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.disclaimer}>Reporter / Respondent</Typography>
            <Typography>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={createUrl(`/collector/${reporter?.slug}`)}
                className={classes.link}
              >
                {`${reporter?.name} <${reporter?.email}>`}
              </a>
              {` / `}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={createUrl(`/collector/${respondent?.slug}`)}
                className={classes.link}
              >
                {`${respondent?.name} <${respondent?.email}>`}
              </a>
            </Typography>
          </div>
        </div>
        <div className={classes.controls}>
          <div className={classes.control}>
            <Typography className={classes.disclaimer}>Set a status</Typography>
            <Select
              id="status"
              options={statuses}
              value={status}
              onChange={onSetStatus}
              classes={{ root: classes.select }}
              placeholder="Set status"
            />
          </div>
          <div className={classes.control}>
            <Typography className={classes.disclaimer}>Choose an action</Typography>
            <Select
              id="decision"
              options={actions}
              value={action}
              onChange={onSetAction}
              classes={{ root: classes.select }}
              placeholder="Select action"
            />
          </div>
          {operator && renderOperator}
        </div>
      </div>
      <SaveConfirmPopup
        onSave={onApprove}
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
      />
    </div>
  );
};

ReportDescription.propTypes = {
  report: PropTypes.shape({
    url: PropTypes.string,
    entity: PropTypes.object,
    reason: PropTypes.object,
    description: PropTypes.string,
    reportEntity: PropTypes.object,
  }).isRequired,
};

ReportDescription.defaultProps = {};

export default ReportDescription;
