import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  card: {
    border: `2px solid ${theme.palette.border.main}`,
    borderRadius: 4,
    height: 120,
    padding: '20px 8px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    position: 'relative',
    boxSizing: 'border-box',
    minWidth: 80,
  },
  desription: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.6)',
    padding: '4px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  background: ({ url }) => ({
    backgroundImage: url ? `url(${url})` : 'none',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    '&.loading': {
      opacity: 0.5,
    },
  }),
  name: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 'bold',
  },
  remove: {
    backgroundColor: 'rgba(0,0,0,.6)',
    color: '#fff',
    position: 'absolute',
    top: 3,
    right: 3,
    borderRadius: '50%',
    display: 'flex',
    height: theme.spacing(3),
    width: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  progress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 'calc(50% - 24px)',
    left: 'calc(50% - 24px)',
  },
}));

const DropzoneCard = (props) => {
  const { name, url, isPreview, isLoading, onRemove } = props;

  const classes = useStyles({ url });

  const nameSplit = name.split('.');
  const fileName = nameSplit.slice(0, -1).join('.');

  const renderRemoveBtn = () => {
    if (isLoading) return <div className={clsx(classes.remove, classes.spinner)} />;
    return (
      <div
        className={classes.remove}
        onClick={(e) => onRemove(e, name)}
        onKeyDown={(e) => onRemove(e, name)}
        role="button"
        tabIndex={0}
        data-test="dropzone-card-close"
      >
        ⨯
      </div>
    );
  };

  const renderSpinner = () => {
    if (!isPreview && !isLoading) return null;
    return <CircularProgress size={48} className={classes.progress} />;
  };

  return (
    <div className={clsx('dropzone-card', classes.card)}>
      <div className={clsx(classes.background, isPreview && 'loading')} />
      {renderRemoveBtn()}
      <div className={classes.desription}>
        <span className={classes.name}>{fileName}</span>
      </div>
      {renderSpinner()}
    </div>
  );
};

DropzoneCard.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isPreview: PropTypes.bool,
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func,
};

DropzoneCard.defaultProps = {
  onRemove: () => {},
  isPreview: false,
  isLoading: false,
};

export default DropzoneCard;
