import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, DialogTitle } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { CREATE_COLLECTION_TYPE_PROPERTY_ITEM } from '../../graphql/mutation/collectionTypePropertyListItems';
import { GET_COLLECTION_TYPE_PROPERTY_BY_SLUG } from '../../graphql/query/collectionTypeProperties';

import CollectionTypePropertyItemForm from '../collection-type-property-item-form';

const CreateCollectionTypePropertyItemModal = (props) => {
  const { onClose, updateItems, ...modalProps } = props;
  const { slug } = useParams();

  const { data } = useQuery(GET_COLLECTION_TYPE_PROPERTY_BY_SLUG, { variables: { slug } });
  const propertyData = data?.collectionTypePropertyBySlug || {};
  const propertyName = propertyData?.name;
  const propertyDescription = propertyData?.description;
  const propertyTitle = `${propertyName} (${propertyDescription})`;

  const [createCollectionTypePropertyItem, { loading, error }] = useMutation(
    CREATE_COLLECTION_TYPE_PROPERTY_ITEM,
    { onCompleted },
  );

  const initialValues = {
    published: false,
  };

  function onCompleted() {
    onClose();
    updateItems();
  }

  function onSubmit(submitVariables) {
    const variables = { ...submitVariables, propertyId: propertyData?.id };
    createCollectionTypePropertyItem({ variables });
  }

  return (
    <Dialog onClose={onClose} {...modalProps}>
      <DialogTitle>Replace {propertyTitle} items</DialogTitle>
      <CollectionTypePropertyItemForm
        error={error}
        onSubmit={onSubmit}
        onClose={onClose}
        loading={loading}
        initialValues={initialValues}
        submitButtonText="Create"
      />
    </Dialog>
  );
};

CreateCollectionTypePropertyItemModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  updateItems: PropTypes.func.isRequired,
};

export default CreateCollectionTypePropertyItemModal;
