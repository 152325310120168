/* eslint-disable import/prefer-default-export */
import _uniqBy from 'lodash/uniqBy';

export const mergePaginate = (existing = {}, incoming = {}, { variables }) => {
  const { previous, next } = variables;

  if (!previous && !next) return incoming;

  const newResults = incoming?.results || [];
  const oldResults = existing?.results || [];

  const results = previous ? [...newResults, ...oldResults] : [...oldResults, ...newResults];
  const uniqResults = _uniqBy(results, '__ref');
  const mergedPaginate = { ...incoming, results: uniqResults };

  return mergedPaginate;
};
