import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import _map from 'lodash/map';
import _capitalize from 'lodash/capitalize';
import _words from 'lodash/words';
import _get from 'lodash/get';
import { useSnackbar } from 'notistack';

import { HOME_SPOTLIGHT, HOME_SPOTLIGHT_TYPES } from '../../graphql/query/home';
import { EDIT_HOME_SPOTLIGHT } from '../../graphql/mutation/home';

import { onSubmitErrors } from '../form/helpers';

import SpotlightForm from '../spotlight-form';
import Loader from '../common/loader';

const normalizeString = (string) => {
  const words = _words(string);
  const wordsString = words.join(' ');
  return _capitalize(wordsString);
};

const getTypes = ({ name }) => ({ value: name, label: normalizeString(name) });

const getMediaTypes = (type) => ({ value: type, label: _capitalize(type) });

const Spotlight = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [mediaType, setMediaType] = useState('new');
  const { data: spotlightData, loading } = useQuery(HOME_SPOTLIGHT);
  const { data: spotlightTypesData } = useQuery(HOME_SPOTLIGHT_TYPES);
  const spotlight = spotlightData?.spotlight;
  const spotlightTypes = spotlightTypesData?.__type?.enumValues;
  const { id, headline = '', text = '', mediaKey = '', type, media } = spotlight || {};
  const types = _map(spotlightTypes, getTypes);
  const mediaTypeStrings = ['new', 'existing'];
  const mediaTypes = _map(mediaTypeStrings, getMediaTypes);

  const [editSpotlight, { error }] = useMutation(EDIT_HOME_SPOTLIGHT, {
    onCompleted: onEditUserComplete,
    onError: (errors) => onSubmitErrors(errors, enqueueSnackbar),
  });

  useEffect(() => {
    if (mediaKey) setMediaType('existing');
  }, [id]);

  function onEditUserComplete() {
    enqueueSnackbar('Spotlight edited successfully', {
      variant: 'success',
      resumeHideDuration: 100,
    });
  }

  const onSubmit = (values) => {
    const mediaValue = _get(values, 'media[0]');
    const newMedia = {
      media: {
        id: mediaValue?.id,
        key: mediaValue?.key,
        original_name: mediaValue?.original_name,
        size: mediaValue?.size,
      },
      mediaKey: null,
      type: 'NONE',
    };
    const existingMedia = {
      media: null,
      mediaKey: values?.mediaKey,
      type: values?.type,
    };
    const mediaTypeFields = {
      new: newMedia,
      existing: existingMedia,
    };
    const mediaFields = mediaTypeFields[mediaType] || {};
    const spotlightVariables = {
      id,
      headline: values?.headline,
      text: values?.text,
      ...mediaFields,
    };
    editSpotlight({ variables: { spotlight: spotlightVariables } });
  };

  const initValues = {
    headline,
    text,
    mediaKey,
    type: type || 'NONE',
    media: media && [media],
  };

  const renderForm = (
    <SpotlightForm
      initValues={initValues}
      onSubmit={onSubmit}
      loading={loading}
      error={error}
      types={types}
      mediaTypes={mediaTypes}
      mediaType={mediaType}
      setMediaType={setMediaType}
    />
  );

  return (
    <div>
      {spotlightData && renderForm}
      <Loader show={!spotlightData || loading} absolute transparentBackground />
    </div>
  );
};

export default Spotlight;
