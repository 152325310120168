import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button, Typography } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import LinkIcon from '@material-ui/icons/Link';
import clsx from 'clsx';

import {
  getFormikInputProps,
  getFormikSelectProps,
  getFormikDropzoneProps,
  checkSubmitIsDisabled,
} from '../form/helpers';

import TextInput from '../form/text-input';
import Select from '../form/select';
import Dropzone from '../form/dropzone';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    width: '100%',
    '& + &': {
      marginTop: theme.spacing(3),
    },
  },
  mediaTypeSelect: {
    display: 'flex',
    alignItems: 'center',
  },
  mediaTypeSelectLabel: {
    marginRight: theme.spacing(2),
  },
  dropzone: {
    aspectRatio: '1 / 1',
    width: theme.spacing(60),
    height: theme.spacing(60),
  },
  dropzoneIcon: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  picture: {
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  linkIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginLeft: theme.spacing(0.5),
  },
  error: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
  submitButton: {
    marginTop: theme.spacing(4),
    width: 'auto',
  },
}));

const SpotlightForm = (props) => {
  const { initValues, onSubmit, loading, types, mediaTypes, mediaType, setMediaType } = props;
  const classes = useStyles();

  const formik = useFormik({
    initialValues: initValues,
    onSubmit,
    validate: () => ({}),
  });
  const { handleSubmit } = formik;
  const buttonIsDisabled = checkSubmitIsDisabled(formik) || loading;

  const renderMediaTypeSelect = (
    <div className={clsx(classes.field, classes.mediaTypeSelect)}>
      <Typography className={classes.mediaTypeSelectLabel}>Pick media type</Typography>
      <Select id="media_type" options={mediaTypes} value={mediaType} onChange={setMediaType} />
    </div>
  );

  const renderNewMediaFields = (
    <Dropzone
      className={clsx(classes.field, classes.dropzone)}
      id="media"
      max={1}
      content={<PublishIcon className={classes.dropzoneIcon} />}
      variant="single"
      imageFit="contain"
      hideEditButton
      classes={{ picture: classes.picture }}
      {...getFormikDropzoneProps({ name: 'media', formik })}
    />
  );

  const renderExistingMediaFields = (
    <>
      <a
        target="_blank"
        rel="noreferrer noopener"
        className={clsx(classes.field, classes.link)}
        href="https://github.com/SwanfieldDigital/Wiki/blob/wikiMaster/How%252Dto/Copy-media-key-from-UI.md"
      >
        How to copy media key from UI
        <LinkIcon className={classes.linkIcon} />
      </a>
      <TextInput
        id="mediaKey"
        className={classes.field}
        label="Media key"
        placeholder="Existing media key"
        {...getFormikInputProps({ name: 'mediaKey', formik })}
      />
      <Select
        className={classes.field}
        id="type"
        label="Link type"
        placeholder="Link type"
        options={types}
        {...getFormikSelectProps({ name: 'type', formik })}
      />
    </>
  );

  const mediaFieldOptions = {
    new: renderNewMediaFields,
    existing: renderExistingMediaFields,
  };
  const renderMediaFields = mediaFieldOptions[mediaType];

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div className={classes.fields}>
        <TextInput
          id="headline"
          className={classes.field}
          label="Headline"
          placeholder="Headline"
          {...getFormikInputProps({ name: 'headline', formik })}
        />
        <TextInput
          id="text"
          className={classes.field}
          label="Text"
          placeholder="Text"
          multiline
          minRows={3}
          maxRows={6}
          {...getFormikInputProps({ name: 'text', formik })}
        />
        {renderMediaTypeSelect}
        {renderMediaFields}
      </div>
      <Button
        disabled={buttonIsDisabled}
        variant="contained"
        color="primary"
        type="submit"
        className={classes.submitButton}
      >
        Save
      </Button>
    </form>
  );
};

SpotlightForm.propTypes = {
  initValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  types: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  mediaTypes: PropTypes.array.isRequired,
  mediaType: PropTypes.oneOf(['new', 'existing']).isRequired,
  setMediaType: PropTypes.func.isRequired,
};

SpotlightForm.defaultProps = {
  types: [],
};

export default SpotlightForm;
