import PropTypes from 'prop-types';

import { getFormikInputProps } from '../helpers';

import TextInput from '../text-input';

const PropertyFieldTextInput = (props) => {
  const { property, formik, classes, showLabel, ...otherProps } = props;
  const { id, name, description, formName } = property;

  return (
    <TextInput
      id={id}
      label={showLabel && name}
      className={classes.string}
      placeholder={description}
      {...otherProps}
      {...getFormikInputProps({ formik, name: formName || id })}
    />
  );
};

PropertyFieldTextInput.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    formName: PropTypes.string,
  }).isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    string: PropTypes.string,
  }),
  showLabel: PropTypes.bool,
};

PropertyFieldTextInput.defaultProps = {
  classes: {},
  showLabel: true,
};

export default PropertyFieldTextInput;
