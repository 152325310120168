import { gql } from '@apollo/client';

export const CREATE_NEWS = gql`
  mutation CreateNewsItem($userId: String!, $url: String!, $type: String!) {
    createNewsItem(userId: $userId, url: $url, type: $type) {
      id
      visible
      title
      desc
      createdAt
      type
      url
      user {
        name
        email
      }
    }
  }
`;

export const UPDATE_NEWS_VISIBLE = gql`
  mutation UpdateNewsItemVisible($id: ID!, $visible: Boolean!) {
    updateNewsItemVisible(id: $id, visible: $visible) {
      id
      visible
      title
      desc
      createdAt
      url
      type
    }
  }
`;

export const UPDATE_NEWS_TYPE = gql`
  mutation UpdateNewsItemType($id: ID!, $type: String!) {
    updateNewsItemType(id: $id, type: $type) {
      id
      visible
      title
      desc
      createdAt
      url
      type
    }
  }
`;

export const DELETE_NEWS = gql`
  mutation deleteNewsItem($id: ID!) {
    deleteNewsItem(id: $id) {
      id
      visible
      title
      desc
      createdAt
      url
      type
    }
  }
`;
