import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import _get from 'lodash/get';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core';

import { CREATE_ADVERTISMENT_CLIENT } from '../../graphql/mutation/advertisementClient';

import TextInput from '../form/text-input';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
  error: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

const CreateAdvertisementClientModal = (props) => {
  const { onClose, ...modalProps } = props;
  const classes = useStyles();
  const [client, setClient] = useState('');

  const [createAdvertisementClient, { loading, error }] = useMutation(CREATE_ADVERTISMENT_CLIENT, {
    onCompleted: () => onClose(),
  });

  const onSave = () => {
    createAdvertisementClient({ variables: { client } });
    setClient('');
  };

  const renderError = () => {
    const errorMessage = _get(error, 'errors[0].message');
    if (!errorMessage) return null;
    return <Typography>{errorMessage}</Typography>;
  };

  return (
    <Dialog onClose={onClose} {...modalProps}>
      <DialogTitle>Create advertisement client</DialogTitle>
      <DialogContent>
        <TextInput
          id="client"
          className={classes.input}
          label="Client name"
          placeholder="Client name"
          value={client}
          onChange={(e) => setClient(e?.target.value)}
        />
        {renderError()}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          Close
        </Button>
        <Button disabled={loading || !client} variant="contained" onClick={onSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateAdvertisementClientModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

CreateAdvertisementClientModal.defaultProps = {};

export default CreateAdvertisementClientModal;
