import { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { GET_COLLECTION_TYPE_PROPERTY_LIST_ITEM } from '../../graphql/query/collectionTypePropertyListItems';
import { GET_COLLECTION_TYPE_PROPERTY_BY_SLUG } from '../../graphql/query/collectionTypeProperties';

import EditCollectionTypePropertyItemModal from '../edit-collection-type-property-item-modal';

const EditCollectionTypePropertyItemButton = ({ updateItems, property: editingProperty }) => {
  const { slug } = useParams();
  const { id } = editingProperty || {};
  const [open, setOpen] = useState(false);

  const [getPropertyItem, { data: itemData, loading }] = useLazyQuery(
    GET_COLLECTION_TYPE_PROPERTY_LIST_ITEM,
  );
  const item = itemData?.collectionTypePropertyListItem || null;

  const [getProperty, { data: propertyData }] = useLazyQuery(GET_COLLECTION_TYPE_PROPERTY_BY_SLUG);
  const property = propertyData?.collectionTypePropertyBySlug || {};

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    getPropertyItem({ variables: { id } });
    getProperty({ variables: { slug } });
    setOpen(true);
  };

  return (
    <>
      <IconButton size="small" onClick={onClick}>
        <EditIcon />
      </IconButton>
      <EditCollectionTypePropertyItemModal
        item={item}
        property={property}
        open={open}
        onClose={() => setOpen(false)}
        loading={loading}
        updateItems={updateItems}
      />
    </>
  );
};

EditCollectionTypePropertyItemButton.propTypes = {
  property: PropTypes.shape({ id: PropTypes.string }).isRequired,
  updateItems: PropTypes.func,
};

EditCollectionTypePropertyItemButton.defaultProps = {
  updateItems: () => {},
};

export default EditCollectionTypePropertyItemButton;
