import env from '@beam-australia/react-env';
import axios from 'axios';
import dayjs from 'dayjs';
import _snakeCase from 'lodash/snakeCase';

import keycloak from '../utils/keycloak';

const MEDIA_API_URL = env('MEDIA_API_URL');

const MediaService = {};

MediaService.upload = async ({ file }, { onError }) => {
  const token = keycloak?.token;
  const fileName = _snakeCase(file?.name);
  const timestamp = dayjs().unix();
  const key = `${timestamp}_${fileName}`;

  if (!file) return { error: 'File is not exist', data: null };

  try {
    const headers = { Authorization: `Bearer ${token}` };
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${MEDIA_API_URL}/${key}`, formData, { headers });
    const mediaData = response?.data;
    const { mediaId, userId } = mediaData || {};

    return {
      error: '',
      data: {
        userId,
        key: mediaId,
        original_name: file?.name,
        src: `${MEDIA_API_URL}/${mediaId}`,
      },
    };
  } catch (err) {
    console.error('uploadMedia error: ', err);
    if (onError) onError(err);
    return { error: 'File is not uploaded', data: null };
  }
};

MediaService.delete = (mediaId) => {
  if (!mediaId) return { error: 'File is not exist', data: null };

  const token = keycloak?.token;
  const headers = { Authorization: `Bearer ${token}` };
  axios.delete(`${MEDIA_API_URL}/${mediaId}`, { headers }).catch((error) => {
    console.log(error.message);
  });
};

export default MediaService;
