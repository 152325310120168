import PropTypes from 'prop-types';

const PropertyDashboardLink = ({ children, className, columnId }) => {
  if (columnId === 'media') {
    return <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>;
  }

  return <div className={className}>{children}</div>;
};

PropertyDashboardLink.propTypes = {
  columnId: PropTypes.string.isRequired,
};

export default PropertyDashboardLink;
