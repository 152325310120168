import { gql } from '@apollo/client';

export const REPORTER_OPTIONS = gql`
  query SearchUsers($query: String!, $limit: Int) {
    searchUsers(query: { name: "user", value: $query }, limit: $limit) {
      results {
        id
        email
        name
      }
    }
  }
`;

export const RESPONDER_OPTIONS = gql`
  query SearchUsers($query: String!) {
    searchUsers(query: { name: "user", value: $query }) {
      results {
        id
        email
        name
      }
    }
  }
`;
