/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const EXPLORE_LINES = gql`
  query ExploreLines {
    exploreLines {
      id
      property_id
      value
      name
      property {
        id
        name
        slug
        property_type
        placeholder
        multiple
      }
      propertyValue {
        id
        name
        slug
      }
    }
  }
`;
