import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';

import Header from '../../components/header';
import ReportsDashboard from '../reports-dashboard';
import Report from '../report';
import ImagesDashboard from '../images-dashboard';
import ImageReport from '../image-report';
import NewsDashboard from '../news-dashboard';
import News from '../news';
import AdvertisementDashboard from '../advertisement-dashboard';
import AdvertisementCreate from '../advertisement-create';
import AdvertisementEdit from '../advertisement-edit';
import PropertyDashboard from '../property-dashboard';
import Pages from '../pages';
import Catalogue from '../catalogue';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  body: {
    height: `calc(100% - ${theme.spacing(8)}px)`,
  },
}));

const Content = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.body}>
        <Switch>
          <Route path="/pages">
            <Pages />
          </Route>
          <Route path="/images/:id">
            <ImageReport />
          </Route>
          <Route path="/images">
            <ImagesDashboard />
          </Route>
          <Route path="/news/:id">
            <News />
          </Route>
          <Route path="/news">
            <NewsDashboard />
          </Route>
          <Route path="/advertisement/create">
            <AdvertisementCreate />
          </Route>
          <Route path="/advertisement/:id">
            <AdvertisementEdit />
          </Route>
          <Route path="/advertisement">
            <AdvertisementDashboard />
          </Route>
          <Route path="/report/:id">
            <Report />
          </Route>
          <Route path="/catalogue/property/:slug">
            <PropertyDashboard />
          </Route>
          <Route path="/catalogue">
            <Catalogue />
          </Route>
          <Route>
            <ReportsDashboard />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Content;
