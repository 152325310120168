import { gql } from '@apollo/client';

export const GET_COLLECTION_TYPE_PROPERTY = gql`
  query CollectionTypeProperty($id: ID!) {
    collectionTypeProperty(id: $id) {
      id
      name
      description
      property_type
      multiple
    }
  }
`;

export const GET_COLLECTION_TYPE_PROPERTY_BY_SLUG = gql`
  query CollectionTypePropertyBySlug($slug: String!) {
    collectionTypePropertyBySlug(slug: $slug) {
      id
      name
      description
      property_type
      multiple
    }
  }
`;

export const GET_COLLECTION_TYPE_PROPERTIES = gql`
  query CollectionTypeProperties(
    $next: String
    $previous: String
    $limit: Int
    $collectionType: ID
    $propertyType: String
    $name: String
  ) {
    collectionTypeProperties(
      next: $next
      previous: $previous
      limit: $limit
      collectionType: $collectionType
      propertyType: $propertyType
      name: $name
    ) {
      results {
        id
        name
        description
        slug
        property_type
        collectionTypes {
          id
          name
        }
      }
      previous
      hasPrevious
      next
      hasNext
      resultsNumber
    }
  }
`;

export const GET_AD_COLLECTION_TYPE_PROPERTIES = gql`
  query AdCollectionTypeProperties($limit: Int, $collectionType: ID, $propertyType: String) {
    collectionTypeProperties(
      limit: $limit
      collectionType: $collectionType
      propertyType: $propertyType
    ) {
      results {
        id
        name
        slug
      }
      previous
      hasPrevious
      next
      hasNext
      resultsNumber
    }
  }
`;

export const GET_COLLECTION_TYPE_EDIT_PROPERTIES = gql`
  query CollectionTypeEditProperties($limit: Int) {
    collectionTypeProperties(limit: $limit) {
      results {
        id
        name
        description
      }
      previous
      hasPrevious
      next
      hasNext
      resultsNumber
    }
  }
`;

export const GET_COLLECTION_TYPE_PROPERTIES_TYPES = gql`
  {
    collectionTypePropertyTypes
  }
`;
