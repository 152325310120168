import { useCallback } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import MaterialAutocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Chip, Paper } from '@material-ui/core';

import TextInput from './text-input';

import { getBaseInputStyles } from './base-input';

const useStyles = makeStyles((theme) => ({
  ...getBaseInputStyles(theme),
  popupIndicator: {
    height: '100%',
    width: theme.spacing(3),
    padding: 0,
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  clearIndicator: {
    height: '100%',
    width: theme.spacing(3),
    padding: 0,
    display: 'block',
    position: 'absolute',
    top: 0,
    zIndex: 1,
    visibility: 'visible',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const filter = createFilterOptions();

const Autocomplete = (props) => {
  const {
    label,
    helperText,
    error,
    dataTest,
    customizedOptions,
    required,
    autocompleteInputProps,
    placeholder,
    value,
    classes: outerClasses,
    canCreateNew,
    ...autocompleteProps
  } = props;
  const classes = useStyles();
  const inputParams = {
    label,
    helperText,
    error,
    dataTest,
    required,
    placeholder,
  };
  const hideClearIcon = !value || _isEmpty(value);

  const getOptionSelected = useCallback(
    (option, inputValue) => option.value === inputValue.value,
    [],
  );

  const getOptionLabel = useCallback((option) => _get(option, 'label', ''), []);

  const getPaperComponent = useCallback(
    (paperProps) => <Paper {...paperProps} elevation={8} />,
    [],
  );

  const filterOptions = useCallback((options, params) => {
    const filtered = filter(options, params);

    if (!customizedOptions) return filtered;

    if (params.inputValue !== '' && canCreateNew) {
      filtered.push({
        label: `Create "${params.inputValue}"`, // Options list label
        inputLabel: params.inputValue, // True label
        value: params.inputValue,
      });
    }

    return filtered;
  }, []);

  const renderTag = (option, tagProps) => (
    <Chip variant="outlined" label={option.label} size="small" {...tagProps} />
  );

  const renderTags = (inputValue, getTagProps) => {
    if (!Array.isArray(inputValue)) return [];
    return inputValue.map((option, index) => {
      const tagProps = getTagProps({ index });
      return renderTag(option, tagProps);
    });
  };

  const renderInput = (params) => (
    <TextInput {...inputParams} {...params} {...autocompleteInputProps} />
  );

  return (
    <MaterialAutocomplete
      {...autocompleteProps}
      value={value}
      autoComplete
      blurOnSelect
      freeSolo
      filterSelectedOptions
      autoSelect
      openOnFocus
      forcePopupIcon
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      renderTags={renderTags}
      filterOptions={filterOptions}
      renderInput={renderInput}
      PaperComponent={getPaperComponent}
      disableClearable={hideClearIcon}
      forcePopupIcon={hideClearIcon}
      classes={{
        ...outerClasses,
        inputRoot: clsx(classes.selectRoot, classes.autocompleteRoot, outerClasses.inputRoot),
        input: clsx(classes.select, outerClasses.select),
        endAdornment: clsx(classes.selectEndAdornment, outerClasses.selectEndAdornment),
        clearIndicator: clsx(classes.clearIndicator, outerClasses.clearIndicator),
        popupIndicator: clsx(classes.popupIndicator, outerClasses.popupIndicator),
      }}
    />
  );
};

Autocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      data: PropTypes.any,
    }),
  ),
  dataTest: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  customizedOptions: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
  autocompleteInputProps: PropTypes.object,
  placeholder: PropTypes.string,
  classes: PropTypes.object,
  canCreateNew: PropTypes.bool,
};

Autocomplete.defaultProps = {
  label: '',
  options: [{ value: 'none', label: 'None' }],
  dataTest: '',
  helperText: '',
  error: false,
  customizedOptions: false,
  required: false,
  autocompleteInputProps: {},
  placeholder: '',
  classes: {},
  canCreateNew: true,
};

export default Autocomplete;
