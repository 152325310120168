import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import clsx from 'clsx';

import TextInput from '../form/text-input';
import ReportChangeRequest from '../report-change-request';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'flex-start',
  },
  inputWrapper: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  input: {
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.border.input}`,
    padding: `0 ${theme.spacing(1)}px !important`,
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
  sendButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  list: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  date: {
    marginRight: theme.spacing(2),
    display: 'inline',
  },
  stage: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    alignItems: 'center',
  },
}));

const ReportChangeRequests = (props) => {
  const { value, onChange, onSubmit, changeRequests, className, reportId } = props;
  const classes = useStyles();
  const hasRequests = !_isEmpty(changeRequests);

  const renderRequest = (request) => (
    <ReportChangeRequest key={request?.id} request={request} reportId={reportId} />
  );

  const renderRequests = <div className={classes.list}>{_map(changeRequests, renderRequest)}</div>;

  return (
    <div className={clsx(classes.root, className)}>
      <TextInput
        id="note"
        value={value}
        onChange={onChange}
        rows={2}
        multiline
        className={classes.inputWrapper}
        InputProps={{ classes: { root: classes.input } }}
        placeholder="Type change request text"
      />
      <Button
        fullWidth={false}
        variant="contained"
        size="small"
        onClick={() => onSubmit(value)}
        disabled={!value}
        className={classes.sendButton}
        color="primary"
      >
        Send
      </Button>

      {hasRequests && renderRequests}
    </div>
  );
};

ReportChangeRequests.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  changeRequests: PropTypes.array,
  className: PropTypes.string,
  reportId: PropTypes.string.isRequired,
};

ReportChangeRequests.defaultProps = {
  changeRequests: [],
  className: '',
};

export default ReportChangeRequests;
