import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

import { validateString, validateArray, removeEmptyProperties } from '../form/validate';

const getListPropertyValue = (id, initProperties) => {
  const listProperties = _filter(initProperties, { property_type_id: id });

  const formatValue = (property) => {
    if (!property?.value) return null;
    return { label: property?.value, value: property?.value_id };
  };

  if (_isEmpty(listProperties)) return [''];

  const value = listProperties.map(formatValue).filter((property) => property?.value);
  return value;
};

const getColorValue = (multiple, id, initProperties) => {
  if (multiple > 1) {
    const listProperties = initProperties.filter(
      (listProperty) => listProperty.property_type_id === id,
    );
    if (_isEmpty(listProperties)) return [];
    return listProperties.map((property) => property?.value);
  }
  const property = _find(initProperties, { property_type_id: id });
  return property?.value || [];
};

const getPropertyValue = ({ multiple, type, id, initProperties }) => {
  const isListLike = ['List', 'Options', 'Select'].includes(type);

  if (isListLike) {
    return getListPropertyValue(id, initProperties);
  }

  if (type === 'Color') return getColorValue(multiple, id, initProperties);

  const property = _find(initProperties, { property_type_id: id });
  const media = _get(property, 'media');

  if (type === 'Media') return media ? [media] : '';

  return _get(property, 'value', '');
};

export const getBackground = (properties, values = {}) => {
  const colors = _find(properties, { name: 'Colors' });

  if (!colors) return null;

  const colorsValue = values[colors?.id];

  if (_isEmpty(colorsValue)) return null;

  const colorsString = colorsValue.filter(Boolean).join(', ');

  if (!colorsString) return null;

  return colorsString;
};

export const getInitPropertyValues = (propertyValues, initProperties) => {
  if (_isEmpty(propertyValues)) return {};

  const flattenProperty = (result, property) => {
    const { properties } = property;
    if (_isEmpty(properties)) return [...result, property];
    return [...result, ...properties];
  };

  const flattenProperties = (properties) => {
    if (_isEmpty(properties)) return [];
    return properties.reduce(flattenProperty, []);
  };

  const addValue = (values, { id, multiple, property_type }) => {
    const value = getPropertyValue({ multiple, type: property_type, id, initProperties });
    return { ...values, [id]: { propertyValue: value } };
  };

  const properties = flattenProperties(propertyValues);
  return properties.reduce(addValue, {});
};

export const getInitLineValues = (lines, prefix) => {
  if (_isEmpty(lines)) return {};

  const flattenLine = (result, line) => {
    const { properties } = line;
    if (_isEmpty(properties)) return [...result, line];
    return [...result, ...properties];
  };

  const flattenLines = (linesData) => {
    if (_isEmpty(linesData)) return [];
    return linesData.reduce(flattenLine, []);
  };

  const getValue = (line) => {
    const { property, value, propertyValue } = line;
    const propertyType = property?.property_type;
    const isListLike = ['List'];
    const isSelect = propertyType === 'Select';

    if (isSelect) return _get(value, '[0]');

    if (!isListLike.includes(propertyType)) return value;

    if (!propertyValue) return [''];

    return [{ value: propertyValue?.id, label: propertyValue?.name }];
  };

  const addValue = (values, line) => {
    const value = getValue(line);
    const id = line?.property?.id;
    return { ...values, [id]: { [prefix]: value, nameValue: line?.name } };
  };

  const properties = flattenLines(lines);
  return properties.reduce(addValue, {});
};

const validatePropertyString = (value, { name }) => {
  return validateString(value, {
    required: true,
    requiredMessage: `${name} is required`,
  });
};

const validatePropertyArray = (value, { name }) => {
  return validateArray(value, {
    required: true,
    requiredMessage: `${name} is required`,
  });
};

const validateProperty = (value, property, errors) => {
  const { property_type: type } = property;
  const STRING_TYPES = ['String', 'Number'];
  const ARRAY_TYPES = ['List', 'Media', 'Options', 'Select', 'Color'];
  if (STRING_TYPES.includes(type)) {
    return validatePropertyString(value, property, errors);
  }
  if (ARRAY_TYPES.includes(type)) {
    return validatePropertyArray(value, property, errors);
  }
};

const validateProperties = (properties, values, errors) => {
  const propertyErrors = {};
  if (_isEmpty(properties)) return {};
  properties.map((property) => {
    const value = values[property?.id];
    const error = validateProperty(value, property, errors);
    propertyErrors[property?.id] = error;
    return error;
  });
  return propertyErrors;
};

export const validate = (values, properties = []) => {
  const errors = {};
  const { name, collectionId, description } = values;
  const requiredProperties = properties.filter(({ required }) => required);

  errors.name = validateString(name, {
    required: true,
    requiredMessage: 'Name is required',
  });
  errors.collectionId = validateString(collectionId, {
    required: true,
    requiredMessage: 'Collection is required',
  });
  errors.description = validateString(description);

  const propertyErrors = validateProperties(requiredProperties, values, errors);

  return removeEmptyProperties({ ...errors, ...propertyErrors });
};
