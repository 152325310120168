import ListIcon from '@material-ui/icons/List';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LIST_TYPES = ['List', 'Options', 'Select'];

const CollectionTypePropertyOptionsLink = ({ property }) => {
  const { slug, property_type } = property || {};

  if (!LIST_TYPES.includes(property_type)) return null;

  return (
    <Link to={`/catalogue/property/${slug}`}>
      <IconButton size="small">
        <ListIcon />
      </IconButton>
    </Link>
  );
};

CollectionTypePropertyOptionsLink.propTypes = {
  property: PropTypes.shape({ slug: PropTypes.string, property_type: PropTypes.string }).isRequired,
};

export default CollectionTypePropertyOptionsLink;
