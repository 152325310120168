/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_ADVERTISMENT_CLIENT = gql`
  mutation CreateAdvertisementClient($client: String!) {
    createAdvertisementClient(client: $client) {
      id
      client
    }
  }
`;
