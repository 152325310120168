import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
  url: window?.__ENV?.REACT_APP_KEYCLOAK_URL,
  clientId: window?.__ENV?.REACT_APP_KEYCLOAK_CLIENT_ID,
  realm: window?.__ENV?.REACT_APP_KEYCLOAK_REALM,
  // checkLoginIframe: false,
});

export default keycloak;
