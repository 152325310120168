import { gql } from '@apollo/client';
import { MEDIA } from '../fragments/media';

export const FETCH_EDIT_ADVERTISEMENT = gql`
  query advertisement($id: ID!) {
    advertisement(id: $id) {
      id
      name
      url
      target
      media {
        ...media
        key
        original_name
      }
      expire
      published
      views
      clicks
      properties {
        id
        name
        collectionType {
          id
          name
        }
        collectionTypeProperty {
          id
          name
        }
      }
      client {
        id
        client
      }
    }
  }
  ${MEDIA}
`;

export const ADVERTISEMENT_DASHBOARD = gql`
  query Advertisements($next: String, $previous: String, $limit: Int, $client_id: ID) {
    advertisements(next: $next, previous: $previous, limit: $limit, client_id: $client_id) {
      results {
        id
        name
        url
        target
        media {
          ...media
        }
        expire
        published
        properties {
          id
          name
        }
        client {
          id
          client
        }
      }
      previous
      hasPrevious
      next
      hasNext
      resultsNumber
    }
  }
  ${MEDIA}
`;
