import PropTypes from 'prop-types';

import ConfirmPopup from '../common/confirm-popup';

const SaveConfirmPopup = (props) => {
  const { onSave, open, onClose, saveText } = props;

  const saveButton = {
    text: saveText,
    props: {
      variant: 'contained',
      color: 'secondary',
      onClick: onSave,
    },
  };

  const cancelButton = {
    text: 'Cancel',
    props: {
      color: 'primary',
      onClick: onClose,
    },
  };

  const confirmPopupButtons = [cancelButton, saveButton];

  return (
    <ConfirmPopup open={open} onClose={onClose} buttons={confirmPopupButtons}>
      Are you sure?
    </ConfirmPopup>
  );
};

SaveConfirmPopup.propTypes = {
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  saveText: PropTypes.string,
};

SaveConfirmPopup.defaultProps = {
  saveText: 'Save',
};

export default SaveConfirmPopup;
