import { makeStyles } from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import Loader from '../common/loader';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  container: {
    maxHeight: 'calc(100% - 52px)',
  },
  headCell: {
    backgroundColor: '#E5E5E5',
    fontWeight: 600,
  },
  bodyCell: {
    padding: 0,
  },
  link: {
    display: 'block',
    padding: theme.spacing(2),
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

const DashboardTable = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  columns,
  data,
  loading,
  getRowLink,
  LinkComponent,
  count,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const classes = useStyles();
  const rows = getRowsFromData(data);

  function getRowsFromData(rowsData) {
    const dataLength = rowsData?.length || 0;
    if (dataLength <= rowsPerPage) return rowsData;
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }

  const handleChangePage = (event, newPage) => {
    let direction;
    setPage((oldPage) => {
      direction = newPage > oldPage ? 'next' : 'previous';
      return newPage;
    });
    onPageChange({ newPage, direction });
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsNumber = +event.target.value;
    setRowsPerPage(rowsNumber);
    setPage(0);
    onRowsPerPageChange(rowsNumber);
  };

  const renderHeadCell = (column) => (
    <TableCell
      key={column.id}
      align={column.align}
      style={{ minWidth: column.minWidth, width: column.width }}
      className={classes.headCell}
    >
      {column.label}
    </TableCell>
  );

  const renderHeadCells = (headCells) => {
    if (_isEmpty(headCells)) return null;
    return headCells.map(renderHeadCell);
  };

  const renderBodyCellLink = (content, row, columnId) => {
    if (LinkComponent) {
      return (
        <LinkComponent
          columnId={columnId}
          row={row}
          className={classes.link}
          getRowLink={getRowLink}
        >
          {content}
        </LinkComponent>
      );
    }
    if (!getRowLink) return <span className={classes.link}>{content}</span>;
    return (
      <Link className={classes.link} to={getRowLink(row)}>
        {content}
      </Link>
    );
  };

  const renderBodyCell = (column, row) => {
    const { format, id: columnId, align } = column;
    const { id: rowId } = row;
    const value = _get(row, column.id, '');
    const content = format ? format(row) : value;

    return (
      <TableCell key={`${columnId}_${rowId}`} align={align} className={classes.bodyCell}>
        {renderBodyCellLink(content, row, columnId)}
      </TableCell>
    );
  };

  const renderBodyRow = (row) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
        {columns.map((column) => renderBodyCell(column, row))}
      </TableRow>
    );
  };

  const renderBodyRows = (tableRows) => {
    if (_isEmpty(tableRows)) return null;
    return tableRows.map(renderBodyRow);
  };

  const canRenderTable = data && !loading;

  const renderTable = canRenderTable && (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="list of reports">
          <TableHead>
            <TableRow>{renderHeadCells(columns)}</TableRow>
          </TableHead>
          <TableBody>{renderBodyRows(rows)}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={count || data.length}
      />
    </>
  );

  return (
    <Paper className={classes.root}>
      {renderTable}
      <Loader
        show={!data || loading}
        minWidth="100%"
        minHeight={300}
        absolute
        transparentBackground
      />
    </Paper>
  );
};

DashboardTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  getRowLink: PropTypes.func,
  LinkComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  count: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
};

DashboardTable.defaultProps = {
  data: [],
  getRowLink: null,
  LinkComponent: null,
  count: 0,
  onPageChange: () => {},
  onRowsPerPageChange: () => {},
};

export default DashboardTable;
