import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, Button, Typography } from '@material-ui/core';
import _get from 'lodash/get';
import { string, object } from 'yup';
import { useFormik } from 'formik';

import {
  getFormikInputProps,
  getFormikAutocompleteProps,
  checkSubmitIsDisabled,
} from '../form/helpers';

import TextInput from '../form/text-input';
import Autocomplete from '../form/autocomplete';

const validationSchema = object().shape({
  name: string().required('Name is required'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(60),
    maxWidth: theme.spacing(60),
  },
  field: {
    width: '100%',
    '& + &': {
      marginTop: theme.spacing(3),
    },
  },
  error: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

const CollectionTypeForm = (props) => {
  const { error, propertyOptions, onSubmit, loading, onClose, submitButtonText, initialValues } =
    props;
  const classes = useStyles();

  const formik = useFormik({
    validationSchema,
    onSubmit: onFormSubmit,
    initialValues,
  });
  const { submitForm } = formik;
  const buttonIsDisabled = checkSubmitIsDisabled(formik) || loading;

  const renderError = () => {
    const errorMessage = _get(error, 'errors[0].message');
    if (!errorMessage) return null;
    return <Typography>{errorMessage}</Typography>;
  };

  function onFormSubmit(values = {}) {
    const collectionTypeProperties = values?.properties?.map(({ value }) => value);
    const collectionType = { name: values?.name, properties: collectionTypeProperties };
    onSubmit({ collectionType });
  }

  return (
    <>
      <DialogContent>
        <div className={classes.root}>
          <TextInput
            id="name"
            className={classes.field}
            label="Name"
            placeholder="Type name"
            {...getFormikInputProps({ name: 'name', formik })}
          />
          <Autocomplete
            id="properties"
            label="Properties"
            placeholder="Type properties"
            multiple
            className={classes.field}
            options={propertyOptions}
            {...getFormikAutocompleteProps({ name: 'properties', formik })}
          />
          {renderError()}
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={submitForm}
          disabled={buttonIsDisabled}
          variant="contained"
          color="primary"
        >
          {submitButtonText}
        </Button>
      </DialogActions>
    </>
  );
};

CollectionTypeForm.propTypes = {
  error: PropTypes.object,
  propertyOptions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  initialValues: PropTypes.object,
};

CollectionTypeForm.defaultProps = {
  error: {},
  propertyOptions: {},
  loading: false,
  submitButtonText: 'Save',
  initialValues: {},
};

export default CollectionTypeForm;
