import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, DialogTitle } from '@material-ui/core';

import { CREATE_COLLECTION_TYPE } from '../../graphql/mutation/collectionTypes';
import { GET_COLLECTION_TYPES } from '../../graphql/query/collectionTypes';
import { GET_COLLECTION_TYPE_EDIT_PROPERTIES } from '../../graphql/query/collectionTypeProperties';

import CollectionTypeForm from '../collection-type-form';

const getPropertyOptionLabel = (property = {}) => {
  const { name, description } = property;
  if (name === description || !description) return name;
  return `${name} (${description})`;
};

const CreateCollectionTypeModal = (props) => {
  const { onClose, ...modalProps } = props;

  const [createCollectionType, { loading, error }] = useMutation(CREATE_COLLECTION_TYPE, {
    onCompleted: () => onClose(),
    refetchQueries: [{ query: GET_COLLECTION_TYPES, fetchPolicy: 'network-only' }],
  });

  const { data } = useQuery(GET_COLLECTION_TYPE_EDIT_PROPERTIES);
  const properties = data?.collectionTypeProperties?.results || [];
  const propertyOptions = properties?.map((property) => ({
    value: property?.id,
    label: getPropertyOptionLabel(property),
  }));
  const initialValues = { properties: [] };

  function onSubmit(variables) {
    createCollectionType({ variables });
  }

  return (
    <Dialog onClose={onClose} {...modalProps}>
      <DialogTitle>Create collection type</DialogTitle>
      <CollectionTypeForm
        error={error}
        propertyOptions={propertyOptions}
        onSubmit={onSubmit}
        onClose={onClose}
        loading={loading}
        initialValues={initialValues}
        submitButtonText="Create"
      />
    </Dialog>
  );
};

CreateCollectionTypeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CreateCollectionTypeModal;
