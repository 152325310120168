import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Dialog, Button } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  content: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ConfirmPopup = ({ children, buttons, buttonProps, onClose, ...modalProps }) => {
  const classes = useStyles();

  const renderDefaultButton = <Button {...buttonProps}>Ok</Button>;

  const renderButton = ({ text, props }) => (
    <Button key={text} {...props} className={clsx(classes.button, props.className)}>
      {text}
    </Button>
  );

  const renderButtons = _isEmpty(buttons) ? renderDefaultButton : buttons.map(renderButton);

  return (
    <Dialog {...modalProps} onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.content}>{children}</div>
        <div className={classes.buttons}>{renderButtons}</div>
      </div>
    </Dialog>
  );
};

ConfirmPopup.propTypes = {
  children: PropTypes.node.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.object,
      text: PropTypes.node,
    }),
  ),
  buttonProps: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

ConfirmPopup.defaultProps = {
  buttons: [],
  buttonProps: {},
};

export default ConfirmPopup;
