import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { useQuery } from '@apollo/client';

import { MEDIA_OPTIMIZATION_REQUEST } from '../../graphql/query/mediaOptimizationRequest';

import ImageReportDescription from '../../components/image-report-description';
import ImageReportHistory from '../../components/image-report-history';
import ImageReportMedia from '../../components/image-report-media';
import Loader from '../../components/common/loader';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ImageReport = () => {
  const classes = useStyles();
  const { id } = useParams();

  const { data, loading } = useQuery(MEDIA_OPTIMIZATION_REQUEST, { variables: { id } });
  const request = data?.mediaOptimizationRequest;

  const renderContent = () => {
    if (!request) return null;
    return (
      <>
        <ImageReportDescription request={request} />
        <ImageReportMedia request={request} />
        <ImageReportHistory request={request} />
      </>
    );
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      {renderContent()}
      <Loader show={!data || loading} absolute transparentBackground />
    </Container>
  );
};

export default ImageReport;
