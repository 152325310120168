import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import useHash from '../../hooks/useHash';

import { CATALOGUE_TABS } from '../../constants';

import DashboardTabs from '../../components/dashboard-tabs';
import CollectionTypesCatalogue from '../../components/collection-types-catalogue';
import CollectionTypePropertiesCatalogue from '../../components/collection-type-properties-catalogue';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    display: 'grid',
    gridTemplateRows: `${theme.spacing(6)}px 1fr`,
    gridGap: theme.spacing(2),
    boxSizing: 'border-box',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Catalogue = () => {
  const classes = useStyles();
  const history = useHistory();
  const hash = useHash();
  const defaultTab = hash || CATALOGUE_TABS[0].value;
  const [currentTab, setCurrentTab] = useState(defaultTab);

  const onTabSelect = (e, newValue) => {
    setCurrentTab(newValue);
    history.push(`/catalogue#${newValue}`);
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'TYPES':
        return <CollectionTypesCatalogue />;
      case 'PROPERTIES':
        return <CollectionTypePropertiesCatalogue />;
      default:
        return <CollectionTypesCatalogue />;
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.tabs}>
        <DashboardTabs tabs={CATALOGUE_TABS} onSelect={onTabSelect} tab={currentTab} />
      </div>
      {renderContent()}
    </Container>
  );
};

export default Catalogue;
