import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Dialog, DialogTitle } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { EDIT_COLLECTION_TYPE_PROPERTY_ITEM } from '../../graphql/mutation/collectionTypePropertyListItems';

import CollectionTypePropertyItemForm from '../collection-type-property-item-form';
import Loader from '../common/loader';

const EditCollectionTypePropertyItemModal = (props) => {
  const { onClose, updateItems, item, property, loading, ...modalProps } = props;
  const itemId = item?.id;
  const theme = useTheme();

  const [editCollectionTypeProperty, { loading: editLoading, error }] = useMutation(
    EDIT_COLLECTION_TYPE_PROPERTY_ITEM,
    { onCompleted },
  );

  const propertyName = property?.name;
  const propertyDescription = property?.description;
  const propertyTitle = `${propertyName} (${propertyDescription})`;

  function onCompleted() {
    updateItems();
    onClose();
  }

  function onSubmit(variables) {
    editCollectionTypeProperty({
      variables: { ...variables, id: itemId },
    });
  }

  const spellings = item?.spellings?.map((s) => s.spelling).join(', ') || '';

  const initialValues = {
    name: item?.name,
    description: item?.description,
    published: item?.published,
    media: item?.media ? [item?.media] : [],
    spellings,
  };

  const renderForm = (
    <CollectionTypePropertyItemForm
      error={error}
      onSubmit={onSubmit}
      onClose={onClose}
      loading={editLoading}
      initialValues={initialValues}
    />
  );

  return (
    <Dialog onClose={onClose} {...modalProps}>
      <DialogTitle>Edit {propertyTitle} item</DialogTitle>
      {item && renderForm}
      <Loader show={loading} minHeight={260} minWidth={theme.spacing(66)} />
    </Dialog>
  );
};

EditCollectionTypePropertyItemModal.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  onClose: PropTypes.func.isRequired,
  updateItems: PropTypes.func,
  loading: PropTypes.bool,
  property: PropTypes.shape({ name: PropTypes.string, description: PropTypes.string }),
};

EditCollectionTypePropertyItemModal.defaultProps = {
  updateItems: () => {},
  loading: false,
  item: null,
  property: {},
};

export default EditCollectionTypePropertyItemModal;
