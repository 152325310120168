import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button, FormLabel, Typography } from '@material-ui/core';
import clsx from 'clsx';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import PublishIcon from '@material-ui/icons/Publish';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { useLazyQuery } from '@apollo/client';

import {
  getFormikInputProps,
  getFormikDropzoneProps,
  getFormikCheckboxProps,
  getFormikDateProps,
  getFormikAutocompleteProps,
  checkSubmitIsDisabled,
} from '../form/helpers';
import {
  validateDate,
  validateString,
  validateArray,
  validateAutocompleteValue,
  removeEmptyProperties,
} from '../form/validate';

import { SEARCH_ADVERTISEMENT_CLIENTS } from '../../graphql/query/advertisementClient';

import AdvertisementConditionsForm from '../advertisement-conditions-form';
import CreateAdvertisementClientModal from '../create-advertisement-client-modal';
import TextInput from '../form/text-input';
import Autocomplete from '../form/autocomplete';
import Dropzone from '../form/dropzone';
import Checkbox from '../form/checkbox';
import DatePicker from '../form/date-picker';

const useStyles = makeStyles((theme) => ({
  form: {},
  section: {
    display: 'flex',
    '& + &': {
      marginTop: theme.spacing(4),
    },
  },
  column: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
    height: '100%',
    '& + &': {
      marginLeft: theme.spacing(4),
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& + &': {
      marginTop: theme.spacing(3),
    },
  },
  select: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  label: {
    textTransform: 'uppercase',
  },
  button: {
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  link: {
    textDecoration: 'none',
  },
  counters: {
    marginTop: theme.spacing(2),
  },
  counter: {
    fontSize: 18,
  },
  dropzone: {
    marginTop: theme.spacing(2),
    height: 300,
    width: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzoneContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function validate(values) {
  const errors = {};
  const { name, url, properties, media, client_id, expire } = values;

  errors.name = validateString(name, {
    required: true,
    requiredMessage: 'Name is required',
  });
  errors.url = validateString(url, {
    required: true,
    requiredMessage: 'Url is required',
  });
  errors.properties = validateArray(properties, {
    required: true,
    requiredMessage: 'Conditions is required',
  });
  errors.media = validateArray(media, {
    required: true,
    requiredMessage: 'Media is required',
  });
  errors.client_id = validateAutocompleteValue(client_id, {
    required: true,
    requiredMessage: 'Client is required',
  });
  errors.expire = validateDate(expire, {
    required: true,
    requiredMessage: 'Expired is required',
  });

  return removeEmptyProperties(errors);
}

const AdvertisementForm = (props) => {
  const { initialValues, submitLoading, onSubmit, saveButtonText, views, clicks } = props;
  const classes = useStyles();
  const [openCreateClientModal, setOpenCreateClientModal] = useState(false);

  const [searchAdvertisementClients, { data: clientsData }] = useLazyQuery(
    SEARCH_ADVERTISEMENT_CLIENTS,
  );
  const clients = clientsData?.searchAdvertisementClients || [];
  const clientsOptions = clients?.map(({ id, client }) => ({ value: id, label: client }));

  const formikOptions = {
    validate,
    initialValues,
    onSubmit: onFormSubmit,
  };
  const formik = useFormik(formikOptions);
  const { handleSubmit } = formik;
  const buttonIsDisabled = checkSubmitIsDisabled(formik) || submitLoading;

  function onFormSubmit(values) {
    const { name, url, properties, media, published, client_id, expire } = values;
    const property_ids = properties.map((p) => p?.collection_type_property_list_item?.id);
    const uploadedMedia = _get(media, '[0]', {});
    const { key, original_name, size } = uploadedMedia;
    onSubmit({
      name,
      url,
      property_ids,
      published,
      client_id: client_id?.value,
      media: { key, original_name, size },
      expire,
    });
  }

  const closeCreateClientModal = () => setOpenCreateClientModal(false);

  const onClientTextChange = (e, term) => {
    if (term) {
      searchAdvertisementClients({ variables: { term } });
    }
  };

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.section}>
          <div className={classes.column}>
            <div className={classes.row}>
              <div className={classes.column}>
                <TextInput
                  label="Name"
                  placeholder="Name"
                  id="name"
                  className={classes.input}
                  {...getFormikInputProps({ name: 'name', formik })}
                />
              </div>
              <div className={classes.column}>
                <Autocomplete
                  className={classes.select}
                  options={clientsOptions}
                  id="client_id"
                  label="Client"
                  placeholder="Client"
                  onInputChange={_debounce(onClientTextChange, 200)}
                  {...getFormikAutocompleteProps({ name: 'client_id', formik })}
                />
              </div>
              <div className={classes.column}>
                <DatePicker
                  openTo="date"
                  label="Expire"
                  id="expire"
                  className={classes.input}
                  {...getFormikDateProps({ name: 'expire', formik })}
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.column}>
                <TextInput
                  label="Link"
                  placeholder="Link"
                  id="url"
                  className={classes.input}
                  {...getFormikInputProps({ name: 'url', formik })}
                />
              </div>
              <div className={classes.column}>
                <Button
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenCreateClientModal(true)}
                >
                  Create new client
                </Button>
              </div>
              <div className={classes.column}>
                <Checkbox
                  label="Published"
                  name="published"
                  color="secondary"
                  {...getFormikCheckboxProps({ name: 'published', formik })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.column}>
            <FormLabel className={classes.label}>Show Conditions</FormLabel>
            <AdvertisementConditionsForm formik={formik} />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.column}>
            <FormLabel className={classes.label}>Upload image</FormLabel>
            <Dropzone
              className={classes.dropzone}
              id="media"
              max={1}
              content={<PublishIcon className={classes.uploadIcon} />}
              variant="single"
              imageFit="contain"
              hideEditButton
              classes={{ content: classes.dropzoneContent }}
              {...getFormikDropzoneProps({ name: 'media', formik })}
            />
          </div>
          <div className={classes.column}>
            <FormLabel className={classes.label}>Counters</FormLabel>
            <div className={classes.counters}>
              <Typography className={classes.counter}>
                Views: <b>{views}</b>
              </Typography>
              <Typography className={classes.counter}>
                Clicks: <b>{clicks}</b>
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.row}>
            <Button
              className={classes.button}
              type="submit"
              color="secondary"
              variant="contained"
              disabled={buttonIsDisabled}
            >
              {saveButtonText}
            </Button>
            <Link className={clsx(classes.button, classes.link)} to="/advertisement">
              <Button>Cancel</Button>
            </Link>
          </div>
        </div>
      </form>
      <CreateAdvertisementClientModal
        onClose={closeCreateClientModal}
        open={openCreateClientModal}
      />
    </>
  );
};

AdvertisementForm.propTypes = {
  initialValues: PropTypes.object,
  submitLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string,
  views: PropTypes.number,
  clicks: PropTypes.number,
};

AdvertisementForm.defaultProps = {
  initialValues: {},
  saveButtonText: 'Save',
  views: 0,
  clicks: 0,
};

export default AdvertisementForm;
