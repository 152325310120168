import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import clsx from 'clsx';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import { string } from 'yup';
import { GET_ALL_NEWS } from '../../graphql/query/news';
import {
  UPDATE_NEWS_VISIBLE,
  UPDATE_NEWS_TYPE,
  CREATE_NEWS,
  DELETE_NEWS,
} from '../../graphql/mutation/news';
import { GET_COLLECTION_TYPES } from '../../graphql/query/collectionTypes';

import useAuth from '../../hooks/useAuth';

import DashboardTable from '../../components/dashboard-table';
import TextInput from '../../components/form/text-input';
import Select from '../../components/form/select';
import DashboardFilters from '../../components/dashboard-filters';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(2),
    boxSizing: 'border-box',
  },
  filters: {},
  newItem: {
    position: 'relative',
  },
  createNews: {
    display: 'flex',
  },
  createNewsSelect: {
    marginLeft: theme.spacing(2),
    minWidth: 150,
  },
  createNewsButton: {
    marginLeft: theme.spacing(2),
  },
  urlInputRoot: {
    flex: 1,
  },
  outerLink: {
    color: theme.palette.secondary.main,
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  outerLinkIcon: {
    height: theme.spacing(3),
  },
  table: {
    overflow: 'hidden',
  },
  visibleCell: {
    color: theme.palette.error.main,
  },
  visibleCellTrue: {
    color: theme.palette.success.main,
  },
}));

const urlSchema = string().url().required();

const NewsDashboard = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const [newsType, setNewsType] = useState('');
  const [newsUrl, setNewsUrl] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const { data: newsData, loading } = useQuery(GET_ALL_NEWS);
  const { data: typesData, loading: typesLoading } = useQuery(GET_COLLECTION_TYPES);
  const [createNewsItem] = useMutation(CREATE_NEWS, {
    onCompleted: () => setNewsUrl(''),
  });
  const [updateNewsItemVisible] = useMutation(UPDATE_NEWS_VISIBLE);
  const [updateNewsItemType] = useMutation(UPDATE_NEWS_TYPE);
  const [deleteNewsItem] = useMutation(DELETE_NEWS);
  const news = newsData?.allNewsItems || [];
  const [filteredNews, setFilteredNews] = useState(news);
  const types = typesData?.collectionTypes?.results || [];
  const typesOptions = types.map(({ name, slug }) => ({ value: slug, label: name }));
  typesOptions.unshift({ value: 'football_spotlight', label: 'Football Spotlight' });

  const filterTypesOptions = [{ value: '', label: 'All' }, ...typesOptions];

  const [filterType, setFilterType] = useState({});

  const urlIsValid = urlSchema.isValidSync(newsUrl);

  useEffect(() => {
    const firstCollectionTypeId = newsType;
    setNewsType(firstCollectionTypeId);
  }, [typesLoading]);

  useEffect(() => {
    if (filterType.value) {
      setFilteredNews(news.filter((item) => item.type === filterType.value));
    } else {
      setFilteredNews(news);
    }
  }, [filterType, news]);

  const COLUMNS = [
    {
      id: 'visible',
      label: 'Visible',
      minWidth: 100,
      format: renderVisibleCell,
    },
    { id: 'title', label: 'Title', minWidth: 100, format: renderTitle },
    { id: 'desc', label: 'Description', minWidth: 170 },
    {
      id: 'type',
      label: 'Type',
      minWidth: 170,
      format: renderNewTypeSelect,
    },
    {
      id: 'createdAt',
      label: 'Date',
      minWidth: 50,
      format: ({ createdAt }) => dayjs(createdAt).format('DD.MM.YYYY'),
    },
    {
      id: 'delete',
      minWidth: 40,
      width: 40,
      format: renderDeleteButton,
    },
  ];

  const updateChangedItem = (proxy, updatedNews) => {
    const data = proxy.readQuery({ query: GET_ALL_NEWS });
    const allNewsItemsData = data?.allNewsItems;
    const allNewsItems = [...allNewsItemsData];
    const updatedNewsIndex = _findIndex(allNewsItems, { id: updatedNews?.id });
    allNewsItems[updatedNewsIndex] = updatedNews;
    proxy.writeQuery({ query: GET_ALL_NEWS, data: { ...data, allNewsItems } });
  };

  const createNews = () => {
    const userId = user?.id;
    createNewsItem({
      variables: { userId, url: newsUrl, type: newsType },
      update: (proxy, { data: { createNewsItem: createdNews } }) => {
        const data = proxy.readQuery({ query: GET_ALL_NEWS });
        const allNewsItemsData = data?.allNewsItems;
        const allNewsItems = [createdNews, ...allNewsItemsData];
        proxy.writeQuery({ query: GET_ALL_NEWS, data: { ...data, allNewsItems } });
      },
    });
  };

  const deleteNews = (newsItem) => {
    const newsId = newsItem?.id;
    deleteNewsItem({
      variables: { id: newsId },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteNewsItem: newsItem,
      },
      update: (proxy) => {
        const data = proxy.readQuery({ query: GET_ALL_NEWS });
        const oldNews = data?.allNewsItems;
        const allNewsItems = _filter(oldNews, ({ id }) => id !== newsId);
        proxy.writeQuery({ query: GET_ALL_NEWS, data: { ...data, allNewsItems } });
      },
    });
  };

  function onChangeVisible(e, newsItem) {
    const visible = e.target.checked;
    const id = newsItem?.id;
    updateNewsItemVisible({
      variables: { id, visible },
      optimisticResponse: {
        __typename: 'Mutation',
        updateNewsItemVisible: { ...newsItem, visible },
      },
      update: (proxy, { data: { updateNewsItemVisible: updatedNews } }) => {
        updateChangedItem(proxy, updatedNews);
      },
    });
  }

  function onChangeType(type, newsItem) {
    const id = newsItem?.id;
    updateNewsItemType({
      variables: { id, type },
      optimisticResponse: {
        __typename: 'Mutation',
        updateNewsItemType: { ...newsItem, type },
      },
      update: (proxy, { data: { updateNewsItemType: updatedNews } }) => {
        updateChangedItem(proxy, updatedNews);
      },
    });
  }

  const onTypeChange = (value) => setNewsType(value);

  const onNewsUrlChange = (e) => setNewsUrl(e.target.value);

  function renderTitle({ title, url }) {
    return (
      <Typography>
        {title}{' '}
        <a rel="noopener noreferrer" target="_blank" href={url} className={classes.outerLink}>
          <LinkIcon className={classes.outerLinkIcon} />
        </a>
      </Typography>
    );
  }

  function renderNewTypeSelect(newsItem) {
    return (
      <Select
        id="changeNewsType"
        className={classes.createNewsSelect}
        options={typesOptions}
        value={newsItem?.type}
        onChange={(value) => onChangeType(value, newsItem)}
      />
    );
  }

  function renderCheckbox(newsItem) {
    return (
      <Checkbox
        checked={newsItem?.visible}
        onChange={(e) => onChangeVisible(e, newsItem)}
        name={newsItem?.id}
      />
    );
  }

  function renderVisibleCell(newsItem) {
    const { visible } = newsItem;
    return (
      <FormControlLabel
        classes={{ label: clsx(classes.visibleCell, visible && classes.visibleCellTrue) }}
        control={renderCheckbox(newsItem)}
        label={visible ? 'Published' : 'Unpublished'}
      />
    );
  }

  function renderDeleteButton(newsItem) {
    return (
      <IconButton onClick={() => deleteNews(newsItem)}>
        <DeleteIcon />
      </IconButton>
    );
  }

  const renderCreateSection = (
    <div className={classes.newItem}>
      <h2>Add entry</h2>
      <section className={classes.createNews}>
        <TextInput
          id="createNewsUrl"
          onChange={onNewsUrlChange}
          placeholder="News url"
          value={newsUrl}
          classes={{ root: classes.urlInputRoot }}
        />
        <Select
          id="createNewsType"
          className={classes.createNewsSelect}
          options={typesOptions}
          value={newsType}
          onChange={onTypeChange}
        />
        <Button
          color="secondary"
          variant="contained"
          size="small"
          disabled={!urlIsValid}
          className={classes.createNewsButton}
          onClick={createNews}
        >
          Create
        </Button>
      </section>
    </div>
  );

  const filterFields = [
    {
      placeholder: 'Filter by type',
      id: 'news-type-filter',
      value: filterType,
      // onInputChange: _debounce(onFilterTypeChange, 200),
      options: filterTypesOptions,
      onChange: (e, value) => setFilterType(value || {}),
    },
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      {renderCreateSection}
      <div className={classes.filters}>
        <DashboardFilters fields={filterFields} />
      </div>
      <div className={classes.table}>
        <DashboardTable
          data={filteredNews}
          loading={loading}
          columns={COLUMNS}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </Container>
  );
};

export default NewsDashboard;
