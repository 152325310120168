import _get from 'lodash/get';
import _find from 'lodash/find';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MenuItem } from '@material-ui/core';

import { getBaseInputStyles } from './base-input';

import TextInput from './text-input';

const useStyles = makeStyles((theme) => ({
  ...getBaseInputStyles(theme),
}));

// item of options looks like {value, label, data}

const Select = (props) => {
  const {
    id,
    options,
    renderItem,
    onItemClick,
    onChange,
    onChangeTarget,
    value,
    placeholder,
    classes: outerClasses,
    SelectProps,
    ...selectProps
  } = props;
  const classes = useStyles();
  const showPlaceholder = !value && placeholder;

  const onSelect = (e, element) => {
    const selectedValue = _get(e, 'target.value', value);
    const item = _find(options, { value });
    const data = _get(item, 'data', element);
    onChangeTarget(e);
    onChange(selectedValue, data);
  };

  const renderDefaultItem = (item) => {
    if (renderItem) return renderItem(item);
    const { value: itemValue, label, data } = item;
    return (
      <MenuItem key={itemValue} value={itemValue} onClick={() => onItemClick(data)}>
        {label}
      </MenuItem>
    );
  };

  const renderItems = () => {
    if (!Array.isArray(options) || !options.length) return <div />;
    return options.map(renderDefaultItem);
  };

  const renderValue = () => (
    <span className={clsx(classes.placeholder, outerClasses.placeholder)}>{placeholder}</span>
  );

  const placeholderProps = { displayEmpty: true, renderValue };
  const SelectComponentProps = showPlaceholder
    ? { ...placeholderProps, ...SelectProps }
    : SelectProps;

  return (
    <TextInput
      {...selectProps}
      classes={outerClasses}
      id={id}
      onChange={onSelect}
      value={value}
      select
      SelectProps={SelectComponentProps}
      inputProps={{
        className: clsx(classes.select, classes.selectInput),
        ...selectProps?.inputProps,
      }}
      InputProps={{
        className: classes.selectRoot,
        placeholder,
        ...selectProps?.InputProps,
      }}
    >
      {renderItems()}
    </TextInput>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      data: PropTypes.any,
    }),
  ),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onItemClick: PropTypes.func,
  onChangeTarget: PropTypes.func,
  dataTest: PropTypes.string,
  renderItem: PropTypes.func,
  placeholder: PropTypes.string,
  classes: PropTypes.object,
  SelectProps: PropTypes.object,
};

Select.defaultProps = {
  value: '',
  onChange: () => {},
  onChangeTarget: () => {},
  options: [{ value: 'none', label: 'None' }],
  onItemClick: () => {},
  dataTest: '',
  renderItem: null,
  placeholder: '',
  classes: {},
  SelectProps: {},
};

export default Select;
