import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ADVERTISMENT_FORM_CLIENTS = gql`
  query AdvertisementClients($next: String, $limit: Int) {
    advertisementClients(next: $next, limit: $limit) {
      results {
        id
        client
      }
      next
      hasNext
    }
  }
`;

export const SEARCH_ADVERTISEMENT_CLIENTS = gql`
  query SearchAdvertisementClients($term: String!, $limit: Int) {
    searchAdvertisementClients(term: $term, limit: $limit) {
      id
      client
    }
  }
`;
