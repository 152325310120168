import { useRef } from 'react';
import { useKeycloak } from '@react-keycloak-fork/web';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import theme from './theme/index';
import globalStyles from './theme/globalStyles';

import Login from './containers/login';
import Relogin from './containers/relogin';
import Content from './containers/content';

const SNACKBAR_SETTINGS = {
  maxSnack: 5,
  preventDuplicate: true,
  autoHideDuration: 2000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
};

function App() {
  const { keycloak, initialized } = useKeycloak();
  const notistackRef = useRef(null);
  let isOperator = false;

  const onClickClose = (key) => notistackRef?.current?.closeSnackbar(key);

  const renderSnackbarButton = (key) => (
    <IconButton color="inherit" onClick={() => onClickClose(key)}>
      <CloseIcon />
    </IconButton>
  );

  if (!initialized) {
    return null;
  }

  let component = <Login onLoginClick={() => keycloak.login()} />;

  if (keycloak.authenticated) {
    const user = keycloak.tokenParsed;
    const roles =
      (user.realm_access && Array.isArray(user.realm_access.roles) && user.realm_access.roles) ||
      [];
    isOperator = roles.findIndex((r) => r === 'operator') !== -1;
    component = isOperator ? <Content /> : <Relogin logout={keycloak.logout} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider {...SNACKBAR_SETTINGS} ref={notistackRef} action={renderSnackbarButton}>
        <div className="App">{component}</div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

const withGlobalStyles = withStyles(globalStyles);

export default withGlobalStyles(App);
