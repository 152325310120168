import { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import { GET_COLLECTION_TYPE } from '../../graphql/query/collectionTypes';

import EditCollectionTypeModal from '../edit-collection-type-modal';

const EditCollectionTypeButton = ({ updateTypes, type }) => {
  const { id } = type || {};
  const [open, setOpen] = useState(false);

  const [getCollectionType, { data: collectionTypeData, loading }] =
    useLazyQuery(GET_COLLECTION_TYPE);
  const collectionType = collectionTypeData?.collectionType || null;

  const onClick = () => {
    getCollectionType({ variables: { id } });
    setOpen(true);
  };

  return (
    <>
      <IconButton size="small" onClick={onClick}>
        <EditIcon />
      </IconButton>
      <EditCollectionTypeModal
        collectionType={collectionType}
        open={open}
        onClose={() => setOpen(false)}
        loading={loading}
        updateTypes={updateTypes}
        editTypeId={id}
      />
    </>
  );
};

EditCollectionTypeButton.propTypes = {
  type: PropTypes.shape({ id: PropTypes.string }).isRequired,
  updateTypes: PropTypes.func,
};

EditCollectionTypeButton.defaultProps = {
  updateTypes: () => {},
};

export default EditCollectionTypeButton;
