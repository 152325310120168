/* eslint-disable import/prefer-default-export */
import convert from 'color-convert';

const getRgbArray = (rgb) => {
  const regExp = /\(([^)]+)\)/;
  const matches = rgb.match(regExp);
  const rgbString = matches[1];
  return rgbString.split(',');
};

const getColorFormat = (color = '') => {
  if (color.includes('rgb')) return 'rgb';
  if (color.includes('#')) return 'hex';
  return null;
};

const contrast = (rgbArray) => {
  const o = Math.round(
    (parseInt(rgbArray[0], 10) * 299 +
      parseInt(rgbArray[1], 10) * 587 +
      parseInt(rgbArray[2], 10) * 114) /
      1000,
  );
  return o <= 180 ? 'dark' : 'light';
};

export const colorIsLight = (color) => {
  const format = getColorFormat(color);
  const rgb = format === 'rgb' ? getRgbArray(color) : convert[format].rgb(color);
  return contrast(rgb);
};

export const getColorName = (color) => {
  const format = getColorFormat(color);
  const colorName = convert[format].keyword(color);
  return colorName;
};
